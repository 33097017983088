import { Component, OnInit, AfterViewInit, Optional, Input, ViewChild, OnChanges, SimpleChanges, Output, EventEmitter, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { ControlContainer, FormControl, AbstractControl, FormGroup } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { ISupplierBasePriceScheme } from 'src/app/cards/product/product-pricelist/product-pricelist.model';
import { Observable, of, forkJoin } from 'rxjs';
import { ProductPricelistService, OfferService, AuthenticationService, ProductService } from 'src/app/_services';
import { numberUtil } from 'src/app/util/number-util';
import { calculateMargin, calculateSalePriceByDiscount, calculateDiscount as calculateListPriceDiscount, calculateUnitPrice } from 'src/app/_helpers/calculate';
import { MatTable } from '@angular/material/table';
import { OfferType, OfferProductStatus, OfferKind, CampaignTypeValueType, CampaignTypeVatType } from 'src/app/enum';
import { ICampaignTypeDto, IOfferCard, IOfferCardFormData } from 'src/app/models';
import { LxmDialog, LxmMessage } from 'src/app/_helpers';
import { appSettings } from 'src/app/app.settings';
import moment from 'moment';
import { environment } from 'src/environments/environment';
import { ContentLanguage } from 'src/app/_helpers/content-language';
import { nullIfUndefined } from 'src/app/util/fn';
import { IClvDto } from 'src/app/cards/product/product-info/product-info.model';
import { PriceValuePipe } from 'src/app/pipes';
import { OfferProductProcurementDetailDialogComponent } from '../dialog/offer-product-procurement-detail/offer-product-procurement-detail.dialog';
import { CdkDrag, CdkDragDrop, CdkDropList, moveItemInArray } from '@angular/cdk/drag-drop';
import { OfferProductOrderDetailDialogComponent } from '../dialog/offer-product-order-detail/offer-product-order-detail.dialog';
import { CampaignDiscountMethod } from 'src/app/enum/campaign-discount-method';
import { ProductPriceChangesInfoDialogComponent } from '../dialog/offer-product-price-changes/offer-product-price-changes.dialog';
import { ProductCampaignInfoDialogComponent } from '../dialog/supplier-offer-product-campaign/supplier-offer-product-campaign.dialog';
import { OfferProductsListBase } from '../offer-products-list-base';
import { OfferProductReplacementDialog } from '../dialog/offer-product-replacement/offer-product-replacement.dialog';
import { CalendarGroupCampaignType } from 'src/app/enum/calendar-group-campaign-type';

@Component({
  selector: "supplier-offer-products-list",
  templateUrl: "./supplier-offer-products.component.html",
  styleUrls: [
    "../offer-products.component.scss",
    "./supplier-offer-table.scss"
  ],
})
export class SupplierOfferProductsListComponent
  extends OfferProductsListBase
  implements OnInit, AfterViewInit, OnChanges
{
  private static _cols = [
    "drag",
    "product_image",
    "offer_product_name",
    "offer_product_available_from",
    "offer_product_internal_price",
    "offer_product_list_price",
    "offer_product_price_discount",
    "offer_product_price_retailer",
    "offer_product_requested_price",
    // 'offer_product_offer_margin',
    "offer_product_recommended_retail_price",
    // 'offer_product_order_settings',
    "actions"
  ];

  private static _colsRPim = [
    //'offer_product_expander',
    "replacement_indicator",
    "drag",
    "product_image",
    "offer_product_name",
    "offer_product_available_from",
    "offer_product_internal_price",
    "offer_product_list_price",
    "offer_product_price_discount",
    "offer_product_price_retailer",
    "offer_product_requested_price",
    // 'offer_product_offer_margin',
    "offer_product_recommended_retail_price",
    "product_replacement",
    "product_suspension",
    "actions"
  ];

  private static _procurementCols = [
    //'offer_product_expander',
    "drag",
    "product_image",
    "offer_product_name",
    "offer_product_internal_price",
    "offer_product_list_price",
    "offer_product_price_discount",
    "offer_product_price_retailer",
    "offer_product_requested_price",
    // 'offer_product_offer_margin',
    "offer_product_recommended_retail_price",
    "offer_product_procurement_note",
    // 'offer_product_order_settings',
    "actions"
  ];

  private static _campaignCols = [
    //'offer_product_expander',
    "drag",
    "product_image",
    "offer_product_name",
    "offer_product_display",
    "offer_product_price_valid_from_campaign",
    "offer_product_self_and_list_price_campaign",
    "offer_product_main_assortment_price_campaign",
    "offer_product_price_discount_campaign",
    "offer_product_price_retailer_campaign",
    "offer_product_recommended_retail_price",
    "offer_campaign",
    // 'offer_product_order_settings',
    "actions"
  ];

  private static _campaignWithTypesCols = [
    "drag",
    "product_image",
    "offer_product_name",
    "offer_product_price_valid_from_campaign",
    "offer_product_campaign_type",
    "offer_product_current_price",
    "offer_product_campaign_discount_price_agreement",
    "offer_product_campaign_retail_price_discount",
    "offer_product_recommended_retail_price",
    "offer_product_campaign_retail_price_info",
    "offer_campaign",
    "actions"
  ];

  private static _logisticsCols = [
    //'offer_product_expander',
    "drag",
    "product_image",
    "offer_product_name",
    "offer_product_available_from",
    "offer_product_segment",
    "offer_product_storage_conditions",
    "offer_product_transport_unit_ean",
    "offer_product_pallet_type",
    // 'offer_product_order_settings',
    "actions"
  ];

  private static _priceChangesCols = [
    "drag",
    "product_image",
    "offer_product_name",
    "offer_product_price_change_date",
    "offer_product_current_price",
    "offer_product_editable_list_price",
    "offer_product_price_discount_price_changes",
    "offer_product_price_retailer_with_markup",
    // 'offer_product_price_retailer_with_markup_margin',
    "offer_product_requested_price",
    "offer_product_price_change_reason",
    "offer_product_price_change_note",
    "actions"
  ];

  public anyPriceValueChanged: boolean;
  public priceChangeReasons: IClvDto[];
  public dragDisabled = false;
  public campaignDiscountMethod: CampaignDiscountMethod;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild("table") table: MatTable<any>;

  @Output() onSort = new EventEmitter();

  protected get _defaultItem(): any {
    return SupplierOfferProductDefaultItem;
  }

  public OfferType = OfferType;

  @Input() public productAcExcludeIds: string[] = [];

  @Input() public offerId: string;
  @Input() public date: Date;
  @Input() public retailerId: string;
  @Input() public retailerHasRPim = false;
  @Input() public currency: IClvDto;
  @Input() public offerType: OfferType;
  @Input() public countryCode: string;
  @Input() public readonly = true;
  @Input() public canAddProducts = true;
  @Input() public softDelete = false;
  @Input() public showTotal = true;
  @Input() public showNoContentText = true;
  @Input() public canOrder = false;
  @Input() public campaignType: CalendarGroupCampaignType;
  @Input() public campaignTypes: ICampaignTypeDto[];

  public expandedElements = [];
  public expanders = [];

  public get columns(): string[] {
    switch (this.offerType) {
      case OfferType.MainAssortment:
        if (this.retailerHasRPim) {
          return SupplierOfferProductsListComponent._colsRPim;
        }
        return SupplierOfferProductsListComponent._cols;
      case OfferType.Campaign:
        if (
          this.campaignType ==
          CalendarGroupCampaignType.RetailSalesPriceDiscount
        ) {
          return SupplierOfferProductsListComponent._campaignWithTypesCols;
        }
        return SupplierOfferProductsListComponent._campaignCols;
      case OfferType.Logistics:
        return SupplierOfferProductsListComponent._logisticsCols;
      case OfferType.PriceChanges:
        return SupplierOfferProductsListComponent._priceChangesCols;
      case OfferType.Procurement:
        return SupplierOfferProductsListComponent._procurementCols;
    }
  }

  constructor(
    private _cd: ChangeDetectorRef,
    route: ActivatedRoute,
    private _router: Router,
    private _dialog: LxmDialog,
    private _offerService: OfferService,
    private _pricesService: ProductPricelistService,
    private _message: LxmMessage,
    private _authService: AuthenticationService,
    public contentLanguage: ContentLanguage,
    private _productsService: ProductService,
    @Optional() controlContainer: ControlContainer,
    private _priceValuePipe: PriceValuePipe
  ) {
    super(controlContainer, contentLanguage);

    // this._cd.detach();

    let formData = route.snapshot.data.formData as IOfferCardFormData;
    const offerCard = route.snapshot.data.offerCard as IOfferCard;
    if (offerCard) {
      this.anyPriceValueChanged = offerCard.anyPriceValueChanged;
      this.offerId = offerCard.offerDetail.id;
      formData = offerCard.formData;
    }

    if (formData) {
      this.priceChangeReasons = formData.priceChangeReasons;
      this.campaignDiscountMethod = formData.campaignDiscountMethod;
    }
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this._supplierOfferProductsListComponentInit();
  }

  public ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this._bindCalculations();
    // this._cd.detach();
    // this._cd.detectChanges();
  }

  public ngOnChanges(changes: SimpleChanges) {
    super.ngOnChanges(changes);
    const dateChanged =
      changes?.date &&
      changes.date.previousValue?.toISOString() !==
        changes.date.currentValue?.toISOString();
    const countryCodeChanged =
      changes?.countryCode &&
      changes.countryCode.previousValue !== changes.countryCode.currentValue;
    const retailerIdChanged =
      changes?.retailerId &&
      changes.retailerId.previousValue !== changes.retailerId.currentValue;
    const currencyChanged =
      changes?.currency &&
      changes.currency.previousValue?.id !== changes.currency.currentValue?.id;

    if (currencyChanged) {
      if (this._arr) {
        for (let i = 0; i < this._arr.length; i++) {
          this._arr.controls[i].get("salePrice").setValue(null);
        }

        if (this.offerType == OfferType.PriceChanges) {
          for (let i = 0; i < this._arr.length; i++) {
            this._arr.controls[i].get("editableListPrice").setValue(null);
          }
        }
      }
    }

    if (
      dateChanged ||
      countryCodeChanged ||
      retailerIdChanged ||
      currencyChanged
    ) {
      const productIds = this.dataSource.data.map((x) => x.value.productId);
      this._updatePrices(productIds);

      if (dateChanged) {
        this._updateCampaignPriceValidFromDates();
        this._updatePriceChangeDates();
      }

      if (dateChanged || retailerIdChanged) {
        this._updateOfferProductRelations(productIds);
      }
    }

    if (retailerIdChanged && this.dataSource.data.length) {
      const productIds = this.dataSource.data.map((x) => x.value.productId);

      const productsWithDisplayObservable =
        this.retailerId && this.offerType != OfferType.Logistics
          ? this._offerService.productsWithDisplay(productIds, this.retailerId)
          : of({});

      const productLogisticsDataObservable =
        this.retailerId && this.offerType == OfferType.Logistics
          ? this._offerService.logisticsData(productIds, this.retailerId)
          : of({});

      const productValidityObservable = this.retailerId
        ? this._productsService.getProductsValidState(productIds, [
            this.retailerId
          ])
        : of({});

      forkJoin([
        productsWithDisplayObservable,
        productLogisticsDataObservable,
        productValidityObservable
      ]).subscribe((results) => {
        const productsWithDisplay = results[0];
        const logisticsData = results[1];
        const validStates = results[2];

        const arr = this.dataSource.data;

        for (let i = 0; i < arr.length; i++) {
          const productId = arr[i].value.productId;

          arr[i]
            .get("productHasDisplay")
            .setValue(productsWithDisplay[productId]);
          if (!productsWithDisplay[productId]) {
            arr[i].get("display").setValue(false);
          }

          arr[i].get("segment").setValue(nullIfUndefined(logisticsData[productId]?.segment));
          arr[i]
            .get("storageConditions")
            .setValue(nullIfUndefined(logisticsData[productId]?.storageConditions));
          arr[i]
            .get("storageMinTemp")
            .setValue(nullIfUndefined(logisticsData[productId]?.storageMinTemp));
          arr[i]
            .get("storageMaxTemp")
            .setValue(nullIfUndefined(logisticsData[productId]?.storageMaxTemp));
          arr[i]
            .get("transportUnitDefaultEan")
            .setValue(nullIfUndefined(logisticsData[productId]?.transportUnitDefaultEan));
          arr[i]
            .get("palletType")
            .setValue(nullIfUndefined(logisticsData[productId]?.palletType));
          arr[i]
            .get("isValid")
            .setValue(validStates[productId]?.[this.retailerId || ""]);
        }
      });
    }

    if (this.table) {
      // Fix sticky columns on readonly change
      setTimeout((_) => {
        this.table.updateStickyColumnStyles();
        this.table.renderRows();
      });
    }

    // console.log("changes", changes);
    // this._cd.markForCheck();
  }

  public isRowInvalid(row: any): boolean {
    const listPriceRequired =
      this.offerType == OfferType.MainAssortment &&
      row.value.listPrice === null;
    return listPriceRequired || !row.value.isValid;
  }

  public isRowDeleted(row: any): boolean {
    return (
      row.value.isDeleted ||
      row.value.status === OfferProductStatus.Rejected ||
      row.value.status === OfferProductStatus.Removed
    );
  }

  public get getProductAcExcludeIds() {
    // if (this.productAcExcludeIds?.length) {
    //   return this.productAcExcludeIds;
    // }
    return this.dataSource.data.map((x) => x.value.productId);
  }

  dropTable(event: CdkDragDrop<string[]>) {
    if (this.readonly) {
      return;
    }

    moveItemInArray(
      this.dataSource.data,
      event.previousIndex,
      event.currentIndex
    );
    this.onSort.emit(true);
    this._arr.updateValueAndValidity({ onlySelf: true });
    this.table.renderRows();
  }

  private _supplierOfferProductsListComponentInit() {
    const newProductAc = new FormControl();
    this.fgInternal.addControl("newProduct", newProductAc);

    newProductAc.valueChanges.subscribe((x) => {
      if (x) {
        var productId = x.id;

        const basePricesObservable =
          this.retailerId && this.offerType != OfferType.Logistics
            ? this._getBasePrices([productId])
            : of({});

        const productsWithDisplayObservable =
          this.retailerId && this.offerType != OfferType.Logistics
            ? this._offerService.productsWithDisplay(
                [productId],
                this.retailerId
              )
            : of({});

        const productLogisticsDataObservable =
          this.retailerId && this.offerType == OfferType.Logistics
            ? this._offerService.logisticsData([productId], this.retailerId)
            : of({});

        const productValidityObservable = this.retailerId
          ? this._productsService.getProductsValidState(
              [productId],
              [this.retailerId]
            )
          : of({});

        const offerProductRelationsObservable =
          this.offerType == OfferType.PriceChanges &&
          this.date &&
          (this.offerId || this.retailerId)
            ? this._offerService.getOfferProductRelations(
                [productId],
                this.date,
                this.offerId,
                this.retailerId
              )
            : of({});

        const retailerProductPricesObservable =
          this.campaignType ==
            CalendarGroupCampaignType.RetailSalesPriceDiscount &&
          this.date &&
          this.retailerId
            ? this._pricesService.getRetailerProductPrices(
                this.retailerId,
                this.date,
                [productId]
              )
            : of({});

        forkJoin([
          basePricesObservable,
          productsWithDisplayObservable,
          productLogisticsDataObservable,
          productValidityObservable,
          offerProductRelationsObservable,
          retailerProductPricesObservable
        ]).subscribe((results) => {
          const basePrices = results[0];
          const productsWithDisplay = results[1];
          const logisticsData = results[2];
          const validState = results[3];
          const offerProductRelations = results[4];
          const retailerProductPrices = results[5];

          let item = {
            productId: productId,
            productHasDisplay: productsWithDisplay[productId],
            name: x.name,
            ean: x.ean,
            supplierCode: x.supplierCode,
            assortmentStatus: basePrices[productId]?.status,
            brand: { id: x.brand?.id, value: x.brand?.value },
            selfPrice: basePrices[productId]?.buyInPrice,
            calculatedSelfPriceInForeignCurrency:
              basePrices[productId]?.calculatedBuyInPriceInForeignCurrency,

            listPrice: basePrices[productId]?.publicSalesPrice,
            salePrice:
              basePrices[productId]?.mainAssortmentPrice ||
              basePrices[productId]?.publicSalesPrice,
            vat: basePrices[productId]?.vat,
            mainAssortmentPrice:
              basePrices[productId]?.mainAssortmentPrice ||
              basePrices[productId]?.publicSalesPrice,
            imageUrl: x.hasImage
              ? `${environment.apiUrl}api/products/${productId}/images/thumbnailMedium`
              : null,

            segment: logisticsData[productId]?.segment,
            storageConditions: logisticsData[productId]?.storageConditions,
            transportUnitDefaultEan:
              logisticsData[productId]?.transportUnitDefaultEan,
            storageMinTemp: logisticsData[productId]?.storageMinTemp,
            storageMaxTemp: logisticsData[productId]?.storageMaxTemp,
            palletType: logisticsData[productId]?.palletType,

            mainAssortmentCurrency:
              basePrices[productId]?.mainAssortmentCurrency,
            mainAssortmentListPrice:
              basePrices[productId]?.mainAssortmentListPrice,
            discountPriceChanges: null,
            priceChangesInfo: null,

            isNew: true,
            isValid: validState[productId]?.[this.retailerId || ""],

            campaignType: null,
            discountCampaignWithType: null,
            validRetailerProductBuyInPriceScheme:
              retailerProductPrices[productId]
          };

          if (this.offerType == OfferType.PriceChanges) {
            item.listPrice = basePrices[productId]?.publicSalesPrice;
            item.mainAssortmentPrice =
              basePrices[productId]?.mainAssortmentPrice;
            item.mainAssortmentListPrice =
              basePrices[productId]?.mainAssortmentListPrice;
            item.mainAssortmentCurrency =
              basePrices[productId]?.mainAssortmentCurrency;
            item.discountPriceChanges =
              basePrices[productId]?.defaultDiscountPercent;
            item.priceChangesInfo = {
              priceChangeDate: { editableDate: this.date },
              activeCampaignPriceSchemes:
                nullIfUndefined(offerProductRelations?.activeCampaignPriceSchemes?.[productId]),
              activeOffers: nullIfUndefined(offerProductRelations?.activeOffers?.[productId])
            };
          }

          this.addItem(item);
          this._bindCalculations();
          if (basePrices[productId]?.defaultDiscountPercent) {
            this.dataSource.data[this.dataSource.data.length - 1]
              .get("discount")
              .setValue(basePrices[productId]?.defaultDiscountPercent);
          }
        });
        newProductAc.setValue(null);
      }
    });
  }

  private _twoWayBinding(
    row: AbstractControl,
    sourceField: string,
    targetField: string,
    target: string | ((r: AbstractControl) => string),
    calcTarget: (x, y) => any,
    calcSource: (x, y) => any
  ): void {
    row.get(sourceField).valueChanges.subscribe((val) => {
      let sourceValue = numberUtil.toNumber(val);

      const targetControlName =
        typeof target === "string" ? target : target(row);

      let targetValue = numberUtil.toNumber(row.get(targetControlName).value);

      if (!numberUtil.isNumber(sourceValue)) {
        sourceValue = 0;
      }

      if (numberUtil.isNumber(targetValue)) {
        row.patchValue(
          {
            [targetField]: calcTarget(sourceValue, targetValue)
          },
          {
            emitEvent: false
          }
        );
      }
    });

    row.get(targetField).valueChanges.subscribe((val) => {
      const targetControlName =
        typeof target === "string" ? target : target(row);

      let sourceValue = numberUtil.toNumber(row.get(targetControlName).value);
      let targetValue = numberUtil.toNumber(val);
      if (
        !numberUtil.isNumber(sourceValue) ||
        !numberUtil.isNumber(sourceValue)
      ) {
        return;
      }
      row.patchValue(
        {
          [sourceField]: calcSource(targetValue, sourceValue)
        },
        {
          emitEvent: false
        }
      );
    });

    // this._cd.detectChanges();
  }

  public getListPrice(item) {
    if (this.offerType == OfferType.Campaign) {
      return (
        item.validSchemeMainAssortmentListPrice ||
        item.mainAssortmentListPrice ||
        item.validSchemeListPrice ||
        item.listPrice
      );
    }

    return item.validSchemeListPrice || item.listPrice;
  }

  public getMainAssortmentPrice(item) {
    return (
      item.validSchemeMainAssortmentPrice ||
      item.mainAssortmentPrice ||
      item.listPrice
    );
  }

  private _getValidListPriceControlName(
    c: AbstractControl,
    campaignDiscount = false
  ): string {
    let name = "";
    let value = null;

    if (
      this.offerType == OfferType.Campaign &&
      c.value.validSchemeMainAssortmentListPrice
    ) {
      if (campaignDiscount) {
        name =
          this.campaignDiscountMethod == CampaignDiscountMethod.FromNormalPrice
            ? "validSchemeMainAssortmentPrice"
            : "validSchemeMainAssortmentListPrice";
      }

      value = c.get(name)?.value;
      if (value && value > 0) {
        return name;
      }

      return "validSchemeMainAssortmentListPrice";
    } else if (
      this.offerType == OfferType.Campaign &&
      c.value.mainAssortmentListPrice
    ) {
      if (campaignDiscount) {
        name =
          this.campaignDiscountMethod == CampaignDiscountMethod.FromNormalPrice
            ? "mainAssortmentPrice"
            : "mainAssortmentListPrice";
      }

      value = c.get(name)?.value;
      if (value && value > 0) {
        return name;
      }

      return "mainAssortmentListPrice";
    } else if (c.value.validSchemeListPrice) {
      return "validSchemeListPrice";
    }

    if (this.offerType == OfferType.Campaign && campaignDiscount) {
      name =
        this.campaignDiscountMethod == CampaignDiscountMethod.FromNormalPrice
          ? "mainAssortmentPrice"
          : "listPrice";
    }

    value = c.get(name)?.value;
    if (value && value > 0) {
      return name;
    }

    return "listPrice";
  }

  private _bindCalculations(): void {
    const arr = this.dataSource.data;
    for (let i = 0; i < arr.length; i++) {
      this._twoWayBinding(
        arr[i],
        "discount",
        "salePrice",
        (c) => this._getValidListPriceControlName(c),
        this.calculateSalePriceByDiscount,
        this.calculateDiscount
      );

      this._twoWayBinding(
        arr[i],
        "discountCampaign",
        "salePriceCampaign",
        (c) => this._getValidListPriceControlName(c, true),
        this.calculateSalePriceByDiscount,
        this.calculateDiscount
      );

      this._twoWayBinding(
        arr[i],
        "discountCampaignInput",
        "salePriceCampaignInput",
        (c) => this._getValidListPriceControlName(c, true),
        this.calculateSalePriceByDiscount,
        this.calculateDiscount
      );

      this._twoWayBinding(
        arr[i],
        "discountPriceChanges",
        "salePricePriceChanges",
        "editableListPrice",
        this.calculateSalePriceByDiscount,
        this.calculateDiscount
      );

      arr[i].get("priceChangeDate").valueChanges.subscribe((val) => {
        const date = val || this.date;
        const productId = arr[i].get("productId").value;

        const offerProductRelationsObservable =
          this.offerType == OfferType.PriceChanges &&
          date &&
          (this.offerId || this.retailerId)
            ? this._offerService.getOfferProductRelations(
                [productId],
                date,
                this.offerId,
                this.retailerId
              )
            : of({});

        offerProductRelationsObservable.subscribe((res) => {
          arr[i].get("activeOffers").setValue(nullIfUndefined(res?.activeOffers?.[productId]));
          arr[i]
            .get("activeCampaignPriceSchemes")
            .setValue(nullIfUndefined(res?.activeCampaignPriceSchemes?.[productId]));
        });
      });

      arr[i].get("editableListPrice").valueChanges.subscribe((val) => {
        const discountValue = arr[i].get("discountPriceChanges").value;
        const discount = numberUtil.toNumber(discountValue);
        arr[i]
          .get("salePricePriceChanges")
          .setValue(this.calculateSalePriceByDiscount(discount, val));
      });

      if (
        this.campaignType == CalendarGroupCampaignType.RetailSalesPriceDiscount
      ) {
        arr[i].get("discountCampaignWithType").valueChanges.subscribe((val) => {
          const sourceValue = numberUtil.toNumber(val);

          const validPrice = this.getValidRetailerProductBuyInPriceScheme(
            arr[i]
          );
          const selectedCampaignType = arr[i].get("campaignType").value;
          const { vatType, valueType } = selectedCampaignType || {};

          const price =
            vatType == CampaignTypeVatType.WithVat
              ? validPrice?.price
              : validPrice?.priceWithoutVat;
          if (!price) {
            return;
          }

          const discountedPrice =
            valueType == CampaignTypeValueType.Value
              ? price - sourceValue
              : price - (price * sourceValue) / 100;
          arr[i]
            .get("suggestedRetailPrice")
            .setValue(discountedPrice, { emitEvent: false });
        });

        arr[i].get("suggestedRetailPrice").valueChanges.subscribe((val) => {
          const sourceValue = numberUtil.toNumber(val);
          const validPrice = this.getValidRetailerProductBuyInPriceScheme(
            arr[i]
          );
          const selectedCampaignType = arr[i].get("campaignType").value;
          const discount = this._getDiscountCampaignWithType(
            selectedCampaignType,
            validPrice,
            sourceValue
          );

          arr[i]
            .get("discountCampaignWithType")
            .setValue(discount, { emitEvent: false });
        });
      }
    }
  }

  public calculateSalePriceByDiscount(discount, listPrice) {
    return numberUtil.round(
      calculateSalePriceByDiscount(discount, listPrice),
      3
    );
  }

  public calculateListPriceDiscount(listPrice, assortmentPrice) {
    return calculateListPriceDiscount(assortmentPrice, listPrice);
  }

  public calculateCampaignDiscountMargin(row) {
    let price = this.getCampaignDiscountPrice(
      row.mainAssortmentPrice,
      row.mainAssortmentListPrice
    );
    return calculateMargin(row.salePriceCampaign, price);
  }

  public getCampaignDiscountPrice(normalPrice, priceListPrice) {
    return this.campaignDiscountMethod == CampaignDiscountMethod.FromNormalPrice
      ? normalPrice
      : priceListPrice;
  }

  private _updateCampaignPriceValidFromDates() {
    const arr = this.dataSource.data;
    for (let i = 0; i < arr.length; i++) {
      arr[i].get("campaignPriceValidFrom").setValue(this.date);
    }
    // this._cd.detectChanges();
  }

  private _updatePriceChangeDates() {
    const arr = this.dataSource.data;
    for (let i = 0; i < arr.length; i++) {
      let priceChangeDate = arr[i].get("priceChangeDate");
      if (!priceChangeDate.value) {
        priceChangeDate.setValue(this.date);
      }
    }
    // this._cd.detectChanges();
  }

  public updatePrices() {
    this._offerService.updatePrices(this.offerId).subscribe((res) => {
      const arr = this.dataSource.data;
      for (let i = 0; i < arr.length; i++) {
        const productId = arr[i].value.productId;
        if (!res.hasOwnProperty(productId)) {
          continue;
        }

        arr[i].get("validSchemeListPrice").setValue(null);
        arr[i].get("validSchemeMainAssortmentListPrice").setValue(null);
        arr[i].get("validSchemeMainAssortmentPrice").setValue(null);
        arr[i].get("validSchemeSelfPrice").setValue(null);
        arr[i]
          .get("validSchemeCalculatedSelfPriceInForeignCurrency")
          .setValue(null);

        arr[i].get("listPrice").setValue(res[productId]?.listPrice || null);
        arr[i]
          .get("mainAssortmentListPrice")
          .setValue(res[productId]?.mainAssortmentListPrice || null);
        arr[i]
          .get("mainAssortmentPrice")
          .setValue(res[productId]?.mainAssortmentPrice || null);
        arr[i].get("selfPrice").setValue(res[productId]?.selfPrice || null);
        arr[i]
          .get("calculatedSelfPriceInForeignCurrency")
          .setValue(
            res[productId]?.calculatedBuyInPriceInForeignCurrency || null
          );
      }
      this.anyPriceValueChanged = false;
      this._message.ok({
        message: "cards.offer.products.prices_updated"
      });
    });

    // this._cd.detectChanges();
  }

  private _updatePrices(productIds: string[]) {
    const basePrices = this._getBasePrices(productIds);
    basePrices.subscribe((res) => {
      const arr = this.dataSource.data;
      for (let i = 0; i < arr.length; i++) {
        const productId = arr[i].value.productId;
        if (!res.hasOwnProperty(productId)) {
          continue;
        }

        const priceScheme = res[productId];
        if (!priceScheme) {
          continue;
        }

        arr[i].get("assortmentStatus").setValue(priceScheme.status || null);
        arr[i].get("selfPrice").setValue(priceScheme.buyInPrice || null);
        arr[i]
          .get("calculatedSelfPriceInForeignCurrency")
          .setValue(priceScheme.calculatedBuyInPriceInForeignCurrency || null);

        arr[i].get("listPrice").setValue(priceScheme.publicSalesPrice || null);
        arr[i].get("vat").setValue(priceScheme.vat || null);
        arr[i]
          .get("discount")
          .setValue(priceScheme.defaultDiscountPercent || null);

        if (this.offerType != OfferType.PriceChanges) {
          arr[i]
            .get("mainAssortmentPrice")
            .setValue(
              priceScheme.mainAssortmentPrice ||
                priceScheme.publicSalesPrice ||
                null
            );
        }

        if (this.offerType == OfferType.PriceChanges) {
          arr[i]
            .get("mainAssortmentPrice")
            .setValue(priceScheme.mainAssortmentPrice || null);
          arr[i]
            .get("mainAssortmentListPrice")
            .setValue(priceScheme.mainAssortmentListPrice || null);
          arr[i]
            .get("mainAssortmentCurrency")
            .setValue(priceScheme.mainAssortmentCurrency || null);

          let discountPercent = arr[i].get("discountPriceChanges");
          this.setPriceIfDefault(
            discountPercent,
            priceScheme.defaultDiscountPercent
          );

          let listPrice = arr[i].get("editableListPrice");
          this.setPriceIfDefault(listPrice, priceScheme.publicSalesPrice);
        }
      }
      // this._cd.detectChanges();
    });
    // this._cd.detectChanges();
  }

  private setPriceIfDefault(c: AbstractControl, val: number) {
    if (c.value) {
      let number = numberUtil.toNumber(c.value);
      if (number != 0) {
        return;
      }
    }

    c.setValue(val || null);
    // this._cd.detectChanges();
  }

  private _getBasePrices(
    productIds: string[]
  ): Observable<{ [productId: string]: ISupplierBasePriceScheme }> {
    return !this.retailerId ||
      !this.date ||
      !productIds.length ||
      !this.currency?.id
      ? of(Object.assign({}, ...productIds.map((x) => ({ [x]: null }))))
      : this._pricesService.getProductPrices(
          this.retailerId,
          this.date,
          productIds,
          this.currency?.id
        );
  }

  private _updateOfferProductRelations(productIds: string[]) {
    const offerProductRelationsObservable =
      this.offerType == OfferType.PriceChanges &&
      productIds.length &&
      this.date &&
      (this.offerId || this.retailerId)
        ? this._offerService.getOfferProductRelations(
            productIds,
            this.date,
            this.offerId,
            this.retailerId
          )
        : of({});

    offerProductRelationsObservable.subscribe((res) => {
      const arr = this.dataSource.data;
      for (let i = 0; i < arr.length; i++) {
        const productId = arr[i].get("productId").value;
        const activeOffers = nullIfUndefined(res?.activeOffers?.[productId]);
        const activeCampaignPriceSchemes =
          nullIfUndefined(res?.activeCampaignPriceSchemes?.[productId]);

        arr[i].get("activeOffers").setValue(activeOffers);
        arr[i]
          .get("activeCampaignPriceSchemes")
          .setValue(activeCampaignPriceSchemes);
      }
    });
  }

  protected _getNormalizedItem(item: any) {
    if (item.isNormalized) {
      return item;
    }

    return {
      id: item.id,
      productId: item.productId,
      preview: item.preview || {},
      campaignInfo: item.campaignInfo || {},
      name: this.contentLanguage.get(item.name),
      display: item.display || false,
      productHasDisplay: item.productHasDisplay || false,
      limit: item.limit || null,
      ean: item.ean,
      supplierCode: item.supplierCode,
      brand: item.brand?.value || null,
      availableFrom: item.availableFrom?.editableDate || null,
      campaignPriceValidFrom:
        item.campaignInfo?.campaignPriceValidFrom?.editableDate ||
        this.date ||
        moment(),
      selfPrice: item.selfPrice,
      calculatedSelfPriceInForeignCurrency:
        item.calculatedSelfPriceInForeignCurrency,
      listPrice: item.listPrice,
      salePrice: item.salePrice,
      salePriceCampaign: item.salePrice,
      discount: this.calculateDiscount(item.salePrice, this.getListPrice(item)),
      discountCampaign: this.calculateDiscount(
        item.salePrice,
        this.getCampaignDiscountPrice(
          this.getMainAssortmentPrice(item),
          this.getListPrice(item)
        )
      ),
      vat: item.vat,
      mainAssortmentPrice: item.mainAssortmentPrice,
      mainAssortmentListPrice: item.mainAssortmentListPrice,
      mainAssortmentCurrency: item.mainAssortmentCurrency,
      suggestedRetailPrice: item.suggestedRetailPrice,
      shops: item.shops || [],
      assortmentStatus: item.assortmentStatus,
      isDeleted: item.isDeleted || false,
      isNew: item.isNew || false,
      isNormalized: true,
      thumbnailUrl: item.thumbnailUrl,
      thumbnail: item.thumbnail,
      thumbnailContentType: item.thumbnailContentType,
      imageUrl: item.imageUrl,
      contentUnitId: item.contentUnitId,
      netContent: item.netContent,
      drainedWeightInGrams: item.drainedWeightInGrams,
      validSchemeSelfPrice: item.validSchemeSelfPrice,
      validSchemeCalculatedSelfPriceInForeignCurrency:
        item.validSchemeCalculatedSelfPriceInForeignCurrency,
      validSchemeListPrice: item.validSchemeListPrice,
      validSchemeMainAssortmentPrice: item.validSchemeMainAssortmentPrice,
      validSchemeMainAssortmentListPrice:
        item.validSchemeMainAssortmentListPrice,
      requestedPrice: item.requestedPrice,

      segment: nullIfUndefined(item.logisticsInfo?.segment),
      storageConditions: nullIfUndefined(item.logisticsInfo?.storageConditions),
      storageMinTemp: nullIfUndefined(item.logisticsInfo?.storageMinTemp),
      storageMaxTemp: nullIfUndefined(item.logisticsInfo?.storageMaxTemp),
      transportUnitDefaultEan: nullIfUndefined(
        item.logisticsInfo?.transportUnitDefaultEan
      ),
      palletType: nullIfUndefined(item.logisticsInfo?.palletType),

      priceChangeDate: nullIfUndefined(
        item.priceChangesInfo?.priceChangeDate?.editableDate
      ),
      priceChangeReason: nullIfUndefined(
        item.priceChangesInfo?.priceChangeReason
      ),
      priceChangeNote: nullIfUndefined(item.priceChangesInfo?.priceChangeNote),
      editableListPrice: item.listPrice,
      salePricePriceChanges: item.salePrice,
      discountPriceChanges:
        item.discountPriceChanges ||
        this.calculateDiscount(item.salePrice, item.listPrice),
      activeCampaignPriceSchemes: nullIfUndefined(
        item.priceChangesInfo?.activeCampaignPriceSchemes
      ),
      activeOffers: nullIfUndefined(item.priceChangesInfo?.activeOffers),

      orderDetails: item.orderDetails,
      procurementDetails: item.procurementDetails || {},

      status: item.status,
      isValid: item.isValid,
      isSuspended: item.isSuspended ?? false,
      replacementForProductId: item.replacementForProductId ?? null,

      campaignType: this.campaignTypes?.find(
        (x) => x.id == item.campaignTypeId
      ),
      discountCampaignWithType: this.getDiscountCampaignWithType(item),
      validRetailerProductBuyInPriceScheme:
        item.validRetailerProductBuyInPriceScheme,
      editCampaignSalePrice: false,
      discountCampaignInput: this.calculateDiscount(
        item.salePrice,
        this.getCampaignDiscountPrice(
          this.getMainAssortmentPrice(item),
          this.getListPrice(item)
        )
      ),
      salePriceCampaignInput: item.salePrice
    };
  }

  public setCampaignDiscountPriceAgreement(row: FormGroup) {
    let priceInputValue = row.controls["salePriceCampaignInput"].value;
    if (typeof priceInputValue === "string") {
      priceInputValue = parseFloat(priceInputValue?.replace(",", "."));
    }

    row.controls["salePriceCampaign"].setValue(priceInputValue);
    row.controls["editCampaignSalePrice"].setValue(false);
  }

  public discardCampaignDiscountPriceAgreement(row: FormGroup) {
    row.controls["salePriceCampaignInput"].setValue(
      row.controls["salePriceCampaign"].value
    );
    row.controls["editCampaignSalePrice"].setValue(false);
  }

  public getValidRetailerProductBuyInPriceScheme(row: AbstractControl) {
    const buyInPriceScheme = row.get(
      "validRetailerProductBuyInPriceScheme"
    ).value;
    const retailPrices = buyInPriceScheme?.retailPrices;

    if (retailPrices) {
      return retailPrices[0];
    }

    return null;
  }

  public calculateCampaignWithTypesDiscountMargin(row) {
    const campaignDiscountPrice = this.getCampaignDiscountPrice(
      this.getMainAssortmentPrice(row),
      this.getListPrice(row)
    );
    return this.calculateMargin(row.salePriceCampaign, campaignDiscountPrice);
  }

  public campaignWithTypesDiscountPrice(row) {
    return this.getCampaignDiscountPrice(
      this.getMainAssortmentPrice(row),
      this.getListPrice(row)
    );
  }

  public removeItem(index: number) {
    if (this.softDelete) {
      const item = this._arr.controls[index] as FormGroup;
      const isNew = item.get("isNew").value;
      const status = item.get("status").value;
      const isReplaced = this.isRowReplaced(item);

      if (isReplaced) {
        const replacementProductIndexes = this.getRowReplacementIndexes(item);
        replacementProductIndexes.forEach((i) => {
          this._arr.removeAt(index);
        });
      }

      if (isNew) {
        this._arr.removeAt(index);
      } else {
        item
          .get("status")
          .patchValue(
            status === OfferProductStatus.Removed
              ? OfferProductStatus.Pending
              : OfferProductStatus.Removed
          );
      }

      this._updateTable();
    } else {
      const item = this._arr.controls[index] as FormGroup;
      const isReplaced = this.isRowReplaced(item);
      if (isReplaced) {
        const replacementProductIndexes = this.getRowReplacementIndexes(item);
        replacementProductIndexes.forEach((i) => {
          this._arr.removeAt(index);
        });
      }

      super.removeItem(index);
    }

    // this._cd.detectChanges();
  }

  public toggleExpandRow(row: any) {
    if (this.expandedElements.includes(row)) {
      const index = this.expandedElements.indexOf(row);
      this.expandedElements.splice(index, 1);
    } else {
      this.expandedElements.push(row);
    }
    // this._cd.detectChanges();
  }

  public productClick(productId: string) {
    const url = this._router.createUrlTree(["/products", productId]).toString();
    window.open(window.location.origin + url, "_blank");
  }

  public hasExistingCampaignInfo(row: any) {
    const {
      addressed,
      campaignNr,
      comments,
      displaySize,
      invoiceRecipient,
      limit,
      marketingFee,
      netting,
      returnableItems,
      storeCount,
      withAd
    } = row.campaignInfo;

    return (
      addressed ||
      campaignNr ||
      comments ||
      displaySize ||
      invoiceRecipient ||
      limit ||
      marketingFee ||
      netting ||
      returnableItems ||
      storeCount ||
      withAd
    );
  }

  public openCampaignDialog(row: any) {
    const readonly =
      this.readonly ||
      row.value.isDeleted ||
      row.value.status === OfferProductStatus.Removed;
    this._dialog.open(
      ProductCampaignInfoDialogComponent,
      {
        ...appSettings.DIALOG_SIZES.L,
        data: {
          retailerId: this.retailerId,
          product: row.value,
          readonly: readonly,
          offerKind: OfferKind.SupplierOffer
        }
      },
      (res) => {
        if (!res) {
          return;
        }

        row.get("campaignInfo").setValue(res);
      }
    );
  }

  public getProcurementIcon(row: any) {
    const { procurementDetails } = row.value || {};
    return this.isEmpty(procurementDetails)
      ? "retailer-supplier-prices-info-empty-without-procurement"
      : "retailer-supplier-prices-info-filled-with-procurement";
  }

  public openProcurementDialog(row: any) {
    this._dialog.open(
      OfferProductProcurementDetailDialogComponent,
      {
        ...appSettings.DIALOG_SIZES.M,
        data: {
          product: row.value,
          readonly: this.readonly
        }
      },
      (res) => {
        if (!res) {
          return;
        }

        row.get("procurementDetails").setValue(res);
      }
    );
  }

  public openOrderDialog(row: any) {
    this._dialog.open(
      OfferProductOrderDetailDialogComponent,
      {
        ...appSettings.DIALOG_SIZES.M,
        data: {
          product: row.value,
          readonly: this.readonly
        }
      },
      (res) => {
        if (!res) {
          return;
        }
        row.get("orderDetails").setValue(res);
      }
    );
  }

  public isEmpty(o: any) {
    return (
      o &&
      Object.keys(o)
        .map((x) => o[x])
        .filter((x) => x !== "" && x !== null && x !== undefined).length === 0
    );
  }

  public getPriceChangeIcon(row: any) {
    const { priceChangeNote } = row.value || {};
    return priceChangeNote ? "price-change-filled" : "price-change-empty";
  }

  public openPriceChangeDialog(row: any) {
    this._dialog.open(
      ProductPriceChangesInfoDialogComponent,
      {
        ...appSettings.DIALOG_SIZES.M,
        data: {
          product: row.value,
          readonly: this.readonly
        }
      },
      (res) => {
        if (!res) {
          return;
        }

        row.get("priceChangeNote").setValue(res.note);
      }
    );
  }

  public activeOfferClick(offerId: string) {
    const url = this._router.createUrlTree(["/offers", offerId]).toString();
    window.open(window.location.origin + url, "_blank");
  }

  public getActiveOffer(row: any): any {
    return this._getActiveItem(row, "activeOffers");
  }

  public getActiveCampaignPriceScheme(row: any): any {
    return this._getActiveItem(row, "activeCampaignPriceSchemes");
  }

  private _getActiveItem(row: any, collectionPropertyName: any): any {
    let items = row.get(collectionPropertyName).value;
    return items?.[0];
  }

  public get formatCurrencyPrice() {
    const pipe = this._priceValuePipe;
    const currency = this.currency;
    return function (val) {
      return pipe.transform(val, 3, currency?.value);
    };
  }

  // PRODUCT SUSPENSION

  public toggleSuspendProduct(row: FormGroup) {
    const isRowSuspended = row.value.isSuspended;
    row.get("isSuspended").setValue(!isRowSuspended);
  }

  // PRODUCT REPLACEMENT

  sortPredicate(index: number, item: CdkDrag<FormGroup>) {
    const dropList = this as unknown as CdkDropList; // this = CdkDropList
    const sortData = dropList.data.filteredData;

    const itemData = item.data.value;
    const replacementForProductId = itemData.replacementForProductId;

    if (replacementForProductId) {
      const replacementForSameProduct =
        sortData[index]?.value?.replacementForProductId ===
        replacementForProductId;
      if (replacementForSameProduct) {
        return true;
      }
      return false;
    }

    if (index === 0 || index === sortData.length - 1) {
      return true;
    }

    function isReplacementProduct(index) {
      return sortData[index]?.value?.replacementForProductId ? true : false;
    }

    const canBeDropped =
      !isReplacementProduct(index) && !isReplacementProduct(index + 1);
    return canBeDropped;
  }

  private _replaceProduct(replacementProduct: any, targetIndex: number) {
    const replacementItem = this._createRowObject(replacementProduct);
    const insertIndex = targetIndex + 1;
    const insert = this._arr.at(1);
    const insertControl = this._arr.at(insertIndex);
    if (insertControl?.value?.replacementForProductId) {
      insert.patchValue(replacementItem);
    } else {
      this._arr.insert(insertIndex, replacementItem);
    }
    this._updateTable();
    this._bindCalculations();
  }

  public openReplaceProductDialog(row, targetIndex: number) {
    const { productId } = row.value;
    let products = [row];

    if (this.isRowReplaced(row)) {
      const replacementProducts = this.dataSource.data.filter(
        (x) => x.value.replacementForProductId === productId
      );
      products = products.concat(replacementProducts);
    }

    this._dialog.open(
      OfferProductReplacementDialog,
      {
        width: appSettings.DIALOG_WIDTHS.L,
        height: appSettings.DIALOG_WIDTHS.AUTO,
        data: {
          products: products,
          offerId: this.offerId,
          readonly: true,
          retailerId: this.retailerId,
          offerType: this.offerType,
          date: this.date,
          currency: this.currency
        }
      },
      (products) => {
        if (!products) {
          return;
        }

        for (let i = 0; i < products.length; i++) {
          this._replaceProduct(products[i].value, targetIndex + i);
        }
      }
    );
  }

  public canReplaceProduct(row) {
    const { replacementForProductId, isDeleted } = row.value;
    return !replacementForProductId && !isDeleted;
  }

  // CAMPAIGN

  public isPercentCampaignType(row) {
    const { campaignType } = row.value;

    if (campaignType == null) {
      return true;
    }

    return row.value.campaignType.valueType == CampaignTypeValueType.Percent;
  }

  public isValueCampaignType(row) {
    return (
      row.value.campaignType?.valueType == CampaignTypeValueType.Value || false
    );
  }

  public onCampaignTypeChange(selectedCampaignType, row) {
    if (!selectedCampaignType) {
      return;
    }

    const { numericValue } = selectedCampaignType;
    row.get("discountCampaignWithType").setValue(numericValue);
  }

  public getDiscountCampaignWithType(item: any) {
    const buyInPriceScheme = item.validRetailerProductBuyInPriceScheme;
    const retailPrices = buyInPriceScheme?.retailPrices;

    if (!retailPrices) {
      return null;
    }

    const campaignType = this.campaignTypes?.find(
      (x) => x.id == item.campaignTypeId
    );

    return this._getDiscountCampaignWithType(
      campaignType,
      retailPrices[0],
      item.suggestedRetailPrice
    );
  }

  private _getDiscountCampaignWithType(
    campaignType,
    validPrice,
    suggestedRetailPrice
  ) {
    const { vatType, valueType } = campaignType || {};
    const price =
      vatType == CampaignTypeVatType.WithVat
        ? validPrice?.price
        : validPrice?.priceWithoutVat;

    if (!price) {
      return;
    }

    return valueType == CampaignTypeValueType.Value
      ? price - suggestedRetailPrice
      : ((price - suggestedRetailPrice) / price) * 100;
  }
}


export const SupplierOfferProductDefaultItem = {
  productId: null,
  preview: {},
  campaignInfo: {},
  name: null,
  display: false,
  productHasDisplay: false,
  limit: null,
  ean: null,
  supplierCode: null,
  brand: null,
  availableFrom: null,
  campaignPriceValidFrom: null,
  selfPrice: null,
  calculatedSelfPriceInForeignCurrency: null,
  listPrice: null,
  vat: null,
  mainAssortmentPrice: null,
  mainAssortmentListPrice: null,
  mainAssortmentCurrency: null,
  salePrice: null,
  salePriceCampaign: null,
  discount: null,
  discountCampaign: null,
  suggestedRetailPrice: null,
  shops: [],
  assortmentStatus: null,
  imageUrl: null,
  thumbnailUrl: null,
  thumbnail: null,
  thumbnailContentType: null,
  contentUnitId: null,
  netContent: null,
  drainedWeightInGrams: null,
  validSchemeSelfPrice: null,
  validSchemeCalculatedSelfPriceInForeignCurrency: null,
  validSchemeListPrice: null,
  validSchemeMainAssortmentPrice: null,
  validSchemeMainAssortmentListPrice: null,
  requestedPrice: null,
  segment: null,
  storageConditions: null,
  storageMinTemp: null,
  storageMaxTemp: null,
  transportUnitDefaultEan: null,
  palletType: null,
  status: OfferProductStatus.Pending,
  isDeleted: false,
  isNew: true,
  isValid: true,
  isSuspended: false,
  replacementForProductId: null
};