import { Injectable, ViewChild, Inject, AfterViewInit, OnDestroy, TemplateRef } from '@angular/core';
import { MessageComponent } from '../components/_layout/messages/message/message.component';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';
import { appSettings } from "../app.settings";
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { IConfirmOptions, LxmDialog } from './dialogs';
import { IMessageOptions, LxmMessage } from './messages';
import { IPreviousRouteOptions, RouteNavigator } from './route.listener';
import { ConfirmationDialog } from '../dialogs/confirm/confirm.dialog';
import { MatDialogRef } from '@angular/material/dialog';


@Injectable()
export class ActionHelper {

  constructor(
    private _lxmDialog: LxmDialog,
    private _lxmMessage: LxmMessage,
    private _routeNavigator: RouteNavigator,
    private _translateService: TranslateService
  ) { }

  public translate = (key: string) => this._translateService.instant(key);
  public ok = (options: IMessageOptions) => this._lxmMessage.ok(options);
  public error = (options: IMessageOptions) => this._lxmMessage.error(options);
  public confirm = (options: IConfirmOptions, onConfirm: (cd?: MatDialogRef<ConfirmationDialog, any>) => any ) => this._lxmDialog.confirm(options, onConfirm);
  public openDialog = (ref, options, afterClosed = _ => {}) => this._lxmDialog.open(ref, options, afterClosed);
  public toPreviousRoute = (options: IPreviousRouteOptions) => this._routeNavigator.toPreviousRoute(options);

  public createMessage(successMessage?: string, errorMessage?: string) {
    return {
      LxmMessage: this._lxmMessage,
      successMessage: successMessage,
      errorMessage: errorMessage
    }
  }

  public createConfirm(image: string, title: string, body: string, templateRef?: TemplateRef<any>) {
   
    return {
      LxmDialog: this._lxmDialog,
      image,
      template: `
      <p class="dialog-title mb0">${this.translate(title)}</p>
      <p>
        ${this.translate(body)}
      </p>
      `,
      templateRef: templateRef
    }

  }
  
}

