<card-expanding
  [formGroup]="form"
  [valid]="productValidity.getCardStatusValidType(cardSection)"
  [expanded]="isExpanded" 
  (onExpand)="saveLocalExpandedValue($event)"
>
  <span header-title>{{ title | translate}}</span>

  <div header-actions>
    <ng-container *ngIf="isOwnProduct">
      <ng-container *ngIf="!locked">
        <div class="flex align-center justify-end flex-wrap" *ngIf="!edit">
          <!-- <ng-container *requiredRights="[UserAction.ManageOffer]">
            <lxm-button *ngIf="productId" class="ml15" type="green" icon="plus-dark" label="cards.products.product_base_info.action.create_offer" routerLink="/offers/new"></lxm-button>
          </ng-container> -->
          <!-- <ng-container *ngIf="appState.hasModule(LxmAppModule.SellerPimExtensions)">
            <lxm-button
              class="ml15"
              type="green"
              icon="checkmark"
              label="cards.products.product_base_info.action.validate_product"
              (click)="openValidationDialog()"
            >
            </lxm-button>
          </ng-container> -->
          <!-- <ng-container *requiredRights="[UserAction.ManageProducts]">
            <lxm-button
              class="ml15"
              type="beige"
              icon="plus-dark"
              label="cards.products.product_base_info.action.add_product"
              routerLink="/products/new"
            ></lxm-button>
          </ng-container> -->
          <!-- <ng-container *ngIf="appState.hasModule(LxmAppModule.Pdf)">
            <lxm-button
              *ngIf="productId"
              class="ml15"
              type="beige"
              icon="pdf"
              (click)="openExportProductsDialog()"
            ></lxm-button>
          </ng-container> -->
          <!-- <ng-container *requiredRights="[UserAction.ManageProducts]">
            <lxm-button
              *ngIf="productId"
              class="ml15"
              type="beige"
              icon="copy"
              (click)="clone()"
            ></lxm-button>
          </ng-container> -->
          <ng-container *requiredRights="[UserAction.ManageProducts]">
            <lxm-button
              *ngIf="productId"
              class="ml15"
              type="beige"
              icon="edit"
              title="action.edit"
              (click)="toggleEdit()"
            ></lxm-button>
          </ng-container>
          <!-- <ng-container *requiredRights="[UserAction.ManageProducts]">
            <lxm-button
              *ngIf="productId"
              [disabled]="isProductActive"
              class="ml15"
              type="grey"
              icon="trash"
              title="action.delete"
              (click)="delete()"
            ></lxm-button>
          </ng-container> -->
        </div>

        <div class="flex align-center justify-end flex-wrap" *ngIf="edit && productId">
          <lxm-button
            class="ml15"
            type="grey"
            icon="back"
            label="action.cancel"
            (click)="cancelEdit()"
          ></lxm-button>
          <lxm-button
            class="ml15"
            type="beige"
            icon="save"
            label="action.save"
            [loading]="saveLoading"
            (click)="save()"
            buttonType="submit"
          ></lxm-button>
        </div>
      </ng-container>

      <ng-container *ngIf="locked">
        {{ 'global.locked_by' | translate }}: {{ lockedBy.name }} {{ lockedAt |
        amTimeAgo }}
      </ng-container>

      <div class="flex align-center justify-end flex-wrap" *ngIf="!productId">
        <lxm-button
          class="ml15"
          type="grey"
          icon="back"
          label="action.cancel"
          (click)="backToList()"
        ></lxm-button>
        <lxm-button
          class="card-header-button ml15"
          type="beige"
          icon="save"
          label="action.save"
          [loading]="createLoading"
          (click)="create()"
          buttonType="submit"
        ></lxm-button>
      </div>
    </ng-container>
  </div>

  <div body>
    <card-section>
      <div class="grid grid-cols-1 xl:grid-cols-2 w-full gap-4">
        <div class="flex flex-col">
          <form-field
            [readonly]="!edit"
            labelTop="true"
            label="cards.products.product_base_info.label.full_name"
            description="cards.products.product_base_info.label_description.full_name"
            for="fullName"
            [required]="productValidity.shouldValidate ? productValidity.isRequiredByRetailerLanguageText(productField.FullName) : edit">
            <language-text
              language-text
              for="fullName"
              [required]="productValidity.shouldValidate ? productValidity.getLanguageTextRequiredTypes(productField.FullName) : null"
              [readonly]="!edit"
              newline="true"
              maxlength="200">
            </language-text>
          </form-field>

          <form-field
            [readonly]="!edit"
            label="cards.products.product_base_info.label.brand"
            description="cards.products.product_base_info.label_description.brand"
            for="brand"
            [required]="productValidity.shouldValidate ? productValidity.isRequiredByRetailer(cardSection, productField.Brand, form.get('brand').value) : null">
            <lxm-select
              select
              [readonly]="!edit"
              labelField="value"
              [searchable]="true"
              for="brand"
              [options]="productBrands">
              <lxm-button 
                *requiredRights="[UserAction.ManageClvs]" 
                header 
                label="action.add_new" 
                icon="plus-dark"
                (click)="openClassificatorValueDialog(ClvType.ProductBrand, form.get('brand'), 'productBrands')">
              </lxm-button>
            </lxm-select>
            <validation-errors for="brand"></validation-errors>
            <validation-errors for="brandId"></validation-errors>
          </form-field>

          <form-field
            [readonly]="!edit"
            label="cards.products.product_base_info.label.subbrand"
            description="cards.products.product_base_info.label_description.subbrand"
            for="subBrand"
            [required]="productValidity.shouldValidate ? productValidity.isRequiredByRetailer(cardSection, productField.SubBrand, form.get('subBrand').value) : null"
          >
            <lxm-select
              select
              [readonly]="!edit"
              labelField="value"
              [searchable]="true"
              for="subBrand"
              [options]="productSubBrands">
              <lxm-button 
                *requiredRights="[UserAction.ManageClvs]" 
                header 
                label="action.add_new" 
                icon="plus-dark" 
                (click)="openClassificatorValueDialog(ClvType.ProductBrand, form.get('subBrand'), 'productSubBrands')">
              </lxm-button>
            </lxm-select>
            <validation-errors for="subBrand"></validation-errors>
            <validation-errors for="subBrandId"></validation-errors>
          </form-field>

          <form-field
            [readonly]="!edit"
            label="cards.products.product_base_info.label.model"
            description="cards.products.product_base_info.label_description.model"
            for="model"
            type="input-text"
            [required]="productValidity.shouldValidate ? productValidity.isRequiredByRetailer(cardSection, productField.Model, form.get('model').value) : null"
          >
            <validation-errors for="model"></validation-errors>
          </form-field>

          <form-field
            [readonly]="!edit"
            label="cards.products.product_base_info.label.color"
            description="cards.products.product_base_info.label_description.color"
            for="color"
            type="input-text"
            [required]="productValidity.shouldValidate ? productValidity.isRequiredByRetailer(cardSection, productField.Color, form.get('color').value) : null"
          >
            <validation-errors for="color"></validation-errors>
          </form-field>

          <form-field
            [readonly]="!edit"
            label="cards.products.product_base_info.label.origin"
            description="cards.products.product_base_info.label_description.origin"
            for="countryOfOrigin"
            [required]="productValidity.shouldValidate ? productValidity.isRequiredByRetailer(cardSection, productField.CountryOfOrigin, form.get('countryOfOrigin').value) : null"
          >
            <lxm-select
              select
              [readonly]="!edit"
              [options]="countryOptions"
              [isTranslated]="true"
              labelField="translatedName"
              [sortByLabel]="true"
              [searchable]="true"
              labelIconField="iso3"
              labelIconFallback="global"
              labelIconClass="flag-icon"
              for="countryOfOrigin">
            </lxm-select>
            <validation-errors for="countryOfOrigin"></validation-errors>
            <validation-errors for="countryOfOriginId"></validation-errors>
          </form-field>

          <form-field
            [readonly]="!edit"
            label="cards.products.product_base_info.label.country_of_production"
            description="cards.products.product_base_info.label_description.country_of_production"
            for="countryOfProduction"
            [required]="productValidity.shouldValidate ? productValidity.isRequiredByRetailer(cardSection, productField.CountryOfProduction, form.get('countryOfProduction').value) : null"
          >
            <lxm-select
              select
              [readonly]="!edit"
              [options]="countryOptions"
              [isTranslated]="true"
              labelField="translatedName"
              [sortByLabel]="true"
              [searchable]="true"
              labelIconField="iso3"
              labelIconFallback="global"
              labelIconClass="flag-icon"
              for="countryOfProduction">
            </lxm-select>
            <validation-errors for="countryOfProduction"></validation-errors>
            <validation-errors for="countryOfProductionId"></validation-errors>
          </form-field>

          <form-field
            [readonly]="!edit"
            label="cards.products.product_base_info.label.country_of_packaging"
            description="cards.products.product_base_info.label_description.country_of_packaging"
            for="countryOfPackaging"
            [required]="productValidity.shouldValidate ? productValidity.isRequiredByRetailer(cardSection, productField.CountryOfPackaging, form.get('countryOfPackaging').value) : null"
          >
            <lxm-select
              select
              [readonly]="!edit"
              [options]="countryOptions"
              [isTranslated]="true"
              labelField="translatedName"
              [sortByLabel]="true"
              [searchable]="true"
              labelIconField="iso3"
              labelIconFallback="global"
              labelIconClass="flag-icon"
              for="countryOfPackaging">
            </lxm-select>
            <validation-errors for="countryOfPackaging"></validation-errors>
            <validation-errors for="countryOfPackagingId"></validation-errors>
          </form-field>

          <form-field
            [readonly]="!edit"
            label="cards.products.product_base_info.label.manufacturer"
            description="cards.products.product_base_info.label_description.manufacturer"
            for="manufacturer"
            [required]="productValidity.shouldValidate ? productValidity.isRequiredByRetailer(cardSection, productField.Manufacturer, form.get('manufacturer').value) : null"
          >
            <lxm-select
              select
              [readonly]="!edit"
              labelField="value"
              [searchable]="true"
              for="manufacturer"
              [options]="productManufacturers">
              <lxm-button 
                *requiredRights="[UserAction.ManageClvs]" 
                header 
                label="action.add_new" 
                icon="plus-dark" 
                (click)="openClassificatorValueDialog(ClvType.Manufacturer, form.get('manufacturer'), 'productManufacturers')">
              </lxm-button>
            </lxm-select>
            <div
              below
              class="alt-text input-padding"
              *ngIf="form.get('manufacturer').value"
            >
              {{ form.get('manufacturer').value.address | address }} <br />
              {{ form.get('manufacturer').value.gln }}
            </div>
            <validation-errors for="manufacturer"></validation-errors>
            <validation-errors for="manufacturerId"></validation-errors>
          </form-field>

          <form-field
            [readonly]="!edit"
            label="cards.products.product_base_info.label.importer"
            description="cards.products.product_base_info.label_description.importer"
            for="importer"
            [required]="productValidity.shouldValidate ? productValidity.isRequiredByRetailer(cardSection, productField.Importer, form.get('importer').value) : null"
          >
            <lxm-select
              select
              [readonly]="!edit"
              labelField="value"
              [searchable]="true"
              for="importer"
              [options]="productManufacturers"
            >
              <lxm-button 
                *requiredRights="[UserAction.ManageClvs]" 
                header 
                label="action.add_new" 
                icon="plus-dark"
                (click)="openClassificatorValueDialog(ClvType.Manufacturer, form.get('importer'), 'productManufacturers')">
              </lxm-button>
            </lxm-select>
            <div
              below
              class="alt-text input-padding"
              *ngIf="form.get('importer').value"
            >
              {{ form.get('importer').value.address | address }} <br />
              {{ form.get('importer').value.gln }}
            </div>
            <validation-errors for="importer"></validation-errors>
            <validation-errors for="importerId"></validation-errors>
          </form-field>

          <form-field
            [readonly]="!edit"
            label="cards.products.product_base_info.label.consignment"
            description="cards.products.product_base_info.label_description.consignment"
          >
            <slide-toggle
              toggle
              for="isConsignmentProduct"
              [readonly]="!edit"
            ></slide-toggle>
            <validation-errors for="isConsignmentProduct"></validation-errors>
          </form-field>

          <form-field
            [readonly]="!edit"
            label="cards.products.product_base_info.label.customs_code"
            description="cards.products.product_base_info.label_description.customs_code"
            for="customsCode"
            type="input-text"
            [required]="productValidity.shouldValidate ? productValidity.isRequiredByRetailer(cardSection, productField.CustomsCode, form.get('customsCode').value) : null"
          >
            <validation-errors for="customsCode"></validation-errors>
          </form-field>

          <form-field
            [readonly]="!edit"
            label="cards.products.product_base_info.label.excise_certificate"
            description="cards.products.product_base_info.label_description.excise_certificate"
          >
            <slide-toggle
              toggle
              for="hasExciseCertificate"
              [readonly]="!edit"
            ></slide-toggle>
            <validation-errors for="hasExciseCertificate"></validation-errors>
          </form-field>

          <form-field
            [readonly]="!edit"
            label="cards.products.product_base_info.label.has_lot_number"
            description="cards.products.product_base_info.label_description.has_lot_number"
          >
            <slide-toggle
              toggle
              for="hasLotNumber"
              [readonly]="!edit"
            ></slide-toggle>
            <validation-errors for="hasLotNumber"></validation-errors>
          </form-field>

          <form-field
            [readonly]="!edit"
            label="cards.products.product_base_info.label.hygiene_certificate_required"
            description="cards.products.product_base_info.label_description.hygiene_certificate_required"
          >
            <slide-toggle
              toggle
              for="hygieneCertificateRequired"
              [readonly]="!edit"
            ></slide-toggle>
            <validation-errors
              for="hygieneCertificateRequired"
            ></validation-errors>
          </form-field>
        </div>

        <div class="flex flex-col">
          <form-field
            [readonly]="!edit"
            labelTop="true"
            label="cards.products.product_base_info.label.short_name"
            description="cards.products.product_base_info.label_description.short_name"
            for="shortName"
            [required]="productValidity.shouldValidate ? productValidity.isRequiredByRetailerLanguageText(productField.ShortName) : null"
          >
            <language-text
              language-text
              for="shortName"
              [readonly]="!edit"
              newline="true"
              maxlength="40"
              [required]="productValidity.shouldValidate ? productValidity.getLanguageTextRequiredTypes(productField.ShortName) : null"
            ></language-text>
          </form-field>

          <form-field
            [readonly]="!edit"
            label="cards.products.product_base_info.label.unit_order"
            description="cards.products.product_base_info.label_description.unit_order"
            for="orderUnitId"
            [required]="productValidity.shouldValidate ? productValidity.isRequiredByRetailer(cardSection, productField.OrderUnit, form.get('orderUnitId').value) : edit"
          >
            <lxm-select
              select
              [options]="units"
              for="orderUnitId"
              value="id"
              [readonly]="!edit"
              labelField="translatedValue"
              [isTranslated]="true"
            ></lxm-select>
            <validation-errors for="orderUnitId"></validation-errors>
          </form-field>

          <form-field
            [readonly]="!edit"
            label="cards.products.product_base_info.label.unit_sales"
            description="cards.products.product_base_info.label_description.unit_sales"
            for="salesUnitId"
            [required]="productValidity.shouldValidate ? productValidity.isRequiredByRetailer(cardSection, productField.SalesUnit, form.get('salesUnitId').value) : edit"
          >
            <lxm-select
              select
              [options]="units"
              for="salesUnitId"
              [readonly]="!edit"
              value="id"
              labelField="translatedValue"
              [isTranslated]="true"
            ></lxm-select>
            <validation-errors for="salesUnitId"></validation-errors>
          </form-field>

          <!-- only required for unit type kg -->
          <form-field
            *ngIf="form.get('salesUnitId').value === unitKg"
            [readonly]="!edit"
            label="cards.products.product_base_info.label.content_avg"
            description="cards.products.product_base_info.label_description.content_avg"
            for="averageWeightInGrams"
            type="input-text"
            [required]="productValidity.shouldValidate ? productValidity.isRequiredByRetailer(cardSection, productField.AverageWeightInGrams, form.get('averageWeightInGrams').value) : null"
          >
            <validation-errors for="averageWeightInGrams"></validation-errors>
          </form-field>

          <form-field
            *ngIf="form.get('salesUnitId').value === unitKg"
            [readonly]="!edit"
            label="cards.products.product_base_info.label.marking_price_required"
            description="cards.products.product_base_info.label_description.marking_price_required"
          >
            <slide-toggle
              toggle
              for="markingPriceRequired"
              [readonly]="!edit"
            ></slide-toggle>
            <validation-errors for="markingPriceRequired"></validation-errors>
          </form-field>

          <form-field
            [readonly]="!edit"
            label="cards.products.product_base_info.label.content_net"
            description="cards.products.product_base_info.label_description.content_net"
            for="netContent"
            type="input-text"
            [required]="productValidity.shouldValidate ? productValidity.isRequiredByRetailer(cardSection, productField.NetContent, form.get('netContent').value) : null"
          >
            <validation-errors for="netContent"></validation-errors>
          </form-field>

          <form-field
            [readonly]="!edit"
            label="cards.products.product_base_info.label.content_unit"
            description="cards.products.product_base_info.label_description.content_unit"
            for="contentUnitId"
            [required]="productValidity.shouldValidate ? productValidity.isRequiredByRetailer(cardSection, productField.ContentUnit, form.get('contentUnitId').value) : null"
          >
            <lxm-select
              select
              [options]="units"
              for="contentUnitId"
              [readonly]="!edit"
              value="id"
              labelField="translatedValue"
              [isTranslated]="true"
            ></lxm-select>
            <validation-errors for="contentUnitId"></validation-errors>
          </form-field>

          <form-field
            *ngIf="drainedWeightEnabled"
            [readonly]="!edit"
            label="cards.products.product_base_info.label.content_drained"
            description="cards.products.product_base_info.label_description.content_drained"
            for="drainedWeightInGrams"
            type="input-text"
            [required]="productValidity.shouldValidate ? productValidity.isRequiredByRetailer(cardSection, productField.DrainedWeightInKg, form.get('drainedWeightInGrams').value) : null"
          >
            <validation-errors for="drainedWeightInGrams"></validation-errors>
          </form-field>

          <form-field
            *ngIf="sizeInfoEnabled"
            [readonly]="!edit"
            label="cards.products.product_base_info.label.size_info"
            description="cards.products.product_base_info.label_description.size_info"
            for="additionalSizeInfo"
            type="input-text"
            [required]="productValidity.shouldValidate ? productValidity.isRequiredByRetailer(cardSection, productField.AdditionalSizeInfo, form.get('additionalSizeInfo').value) : null"
          >
            <validation-errors for="additionalSizeInfo"></validation-errors>
          </form-field>

          <form-field
            [readonly]="!edit"
            label="cards.products.product_base_info.label.segment_gs1"
            description="cards.products.product_base_info.label_description.segment_gs1"
            for="gs1Segment"
            [required]="edit"
          >
            <lxm-select
              select
              [readonly]="!edit"
              [searchable]="true"
              labelField="title"
              for="gs1Segment"
              url="api/clvac/product-level-gs1-segments/"
            >
            </lxm-select>
            <validation-errors for="gs1Segment"></validation-errors>
            <validation-errors
              for="gs1SegmentId"
              aliasTo="gs1Segment"
            ></validation-errors>
          </form-field>

        <!-- <form-field [readonly]="!edit" label="cards.products.product_base_info.label.segment" *requiredSupplier
          [required]="productValidity.shouldValidate ? productValidity.isRequiredByRetailer(cardSection, productField.Segment, form.get('segment').value) : null">
          <lxm-select select [readonly]="!edit" [searchable]="true" labelField="title" for="segment"
            url="api/clvac/productlevelsegments/">
          </lxm-select>
          <validation-errors for="segment"></validation-errors>
          <validation-errors for="segmentId"></validation-errors>
        </form-field> -->

          <ng-container *ngIf="isOwnProduct">
            <form-field
              [readonly]="!edit"
              label="cards.products.product_base_info.label.segment"
              description="cards.products.product_base_info.label_description.segment"
              for="segment"
            >
              <lxm-select
                select
                [readonly]="!edit"
                [searchable]="true"
                labelField="title"
                for="segment"
                [options]="segmentOptions"
              >
              </lxm-select>
              <validation-errors for="segment"></validation-errors>
              <validation-errors for="segmentId"></validation-errors>
            </form-field>

            <form-field [readonly]="!edit" *ngIf="showOtherSegments" for="segmentOther">
              <lxm-select
                select
                [readonly]="!edit"
                [searchable]="true"
                for="segmentOther"
                [options]="segmentOtherOptions"
                [disableOption]="disableOption"
                [labelFn]="segmentTreeLabel"
              >
              </lxm-select>
            </form-field>
          </ng-container>

          <ng-container *ngIf="appState.hasModule(LxmAppModule.SendOffers) && isOwnProduct">
            <form-field
              *ngIf="activeAssortmentPeriod || activeOffers?.length > 0"
              label="cards.products.product_base_info.label.product_status"
              description="cards.products.product_base_info.label_description.product_status"
              [readonly]="!edit"
            >
              <div any class="product-status input-padding">
                <span
                  *ngIf="activeAssortmentPeriod?.validFrom"
                  class="pb4"
                  class="assortment-period"
                >
                  {{ activeAssortmentPeriod.validTo 
                    ? (('cards.products.product_base_info.in_assortment_until' | translate) + (activeAssortmentPeriod.validTo | formDate) ) 
                    : ('cards.products.product_base_info.in_assortment_termless' | translate) }}
                </span>
                <ng-container *requiredRights="[UserAction.ViewSupplierOffers, UserAction.ViewOthersSupplierOffers]">
                  <a
                    class="active-offer link"
                    *ngFor="let offer of activeOffers | slice:0:activeOffersRenderCount"
                    href="offers/{{offer.offerId}}"
                    target="_blank"
                  >
                    {{ offer.activeTo 
                      ? (('cards.products.product_base_info.in_active_offer_until' | translate) + (offer.activeTo | formDate) ) 
                      : ('cards.products.product_base_info.in_active_offer_termless' | translate) }}
                  </a>
                  <span
                    class="list-overflow-count"
                    *ngIf="activeOffers?.length > activeOffersRenderBaseCount"
                    (click)="toggleAllActiveOffers()"
                    [class.show-less]="activeOffersRenderCount > activeOffersRenderBaseCount"
                  >
                    {{ activeOffersRenderCount > activeOffersRenderBaseCount ? '-' : '+' }} 
                    ({{activeOffers.length - activeOffersRenderBaseCount}})
                  </span>
                </ng-container>
              </div>
            </form-field>
          </ng-container>
        </div>
      </div>
    </card-section>

    <div class="separator"></div>

    <card-section>
      <span header-title
        >{{'cards.products.product_base_info.codes.title' | translate}}</span
      >

        <div body class="flex flex-col">
          <product-code-manager
            for="codes"
            [readonly]="!edit"
            [codeTypes]="codeTypes"
            startIndex="{{productId ? 1 : 0}}">
          </product-code-manager>

          <card-message 
            class="mt10" 
            type="error" 
            message="cards.products.product_base_info.codes.no_codes_error"
            *ngIf="productValidity.shouldValidate ? productValidity.isRequiredByRetailer(cardSection, productField.BaseUnitCodes, form.get('codes').value?.length > 1) : false">
          </card-message>
        </div>

    </card-section>
  </div>
</card-expanding>
