import { Component, OnInit, ChangeDetectorRef, Input, ChangeDetectionStrategy, Injector, EventEmitter } from '@angular/core';
import { Location } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';
import { ProductsListBase } from '../products-list-base';
import { DataTableService, CreateOfferDataService, AuthenticationService, ProductService, SalesOrderDataService } from 'src/app/_services';
import { IProductsSearchFormData } from '../../product/product-info/product-info.model';
import { Observable } from 'rxjs';
import { UserAction, HttpMethod, LxmAppModule } from 'src/app/enum';
import { ProductsRetailerExportDialogComponent } from '../retailer/export-products-retailer.dialog';
import { LxmDialog } from 'src/app/_helpers';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { ProductsListState } from '../products-list.state';
import { FormDatePipe } from 'src/app/pipes';
import { TranslateService } from '@ngx-translate/core';
import { MatSort } from '@angular/material/sort';
import { AppState } from 'src/app/state/app.state';
import { appSettings } from 'src/app/app.settings';
import { ConformityDeclarationDialog } from 'src/app/dialogs/conformity-declaration/conformity-declaration.dialog';

@Component({
  selector: 'products-supplier-card',
  templateUrl: './products-supplier.card.html',
  host: { 'class': 'data-table-card' },
  styleUrls: ['./products-supplier.card.scss', '../products-list-base.scss']
})
export class ProductsSupplierCard extends ProductsListBase<ISupplierProductData, any> implements OnInit {

  dataUrl = 'products/forSupplier';
  method = HttpMethod.Post;
  initialData;

  public formData: IProductsSearchFormData;

  public table = {
    columns: []
  };

  public UserAction = UserAction;
  public LxmAppModule = LxmAppModule;

  constructor(
    private _productService: ProductService,
    private _router: Router,
    private _createOfferDataService: CreateOfferDataService,
    private _formDate: FormDatePipe,
    private _translateService: TranslateService,
    public soDataService: SalesOrderDataService,
    public appState: AppState,
    public state: ProductsListState,
    authService: AuthenticationService,
    location: Location,
    public dialog: LxmDialog,
    route: ActivatedRoute,
    dataTableService: DataTableService<ISupplierProductData, any>,
    cd: ChangeDetectorRef
  ) {
    super(state, location, dialog, dataTableService, cd);
    this.initialData = route?.snapshot?.data?.initialData;
    this.formData = route?.snapshot?.data?.formData as IProductsSearchFormData;

    let cols = ['product_select', 'product_image', 'product_name', 'product_ean', 'product_brand', 'product_tree'];

    if (authService.hasRight([UserAction.ViewPrices])) {
      cols = cols
        .concat(this.formData.retailChains.sort((a, b) => a.shortName > b.shortName ? 1 : -1).map(x => x.id));
    }

    this.table.columns = cols;
  }

  protected getSortParam(sort: MatSort) {
    if (!sort || !sort.active || sort.direction === "") {
      return '';
    }

    let colId = sort.active;
    if (colId === 'product_name') {
      colId = 'fullName';
    }
    if (colId === 'product_ean') {
      colId = 'ean';
    }
    if (colId === 'product_brand') {
      colId = 'brand';
    }
    if (colId === 'product_tree') {
      colId = 'segment';
    }

    return colId + ' ' + sort.direction;
  }

  protected override getColId(sortable: string) {

    if (sortable === 'fullName') {
      return 'product_name'
    }
    if (sortable === 'ean') {
      return 'product_ean'
    }
    if (sortable === 'brand') {
      return 'product_brand'
    }
    if (sortable === 'segment') {
      return 'product_tree';
    }

    return sortable;
  }

  public getRetailPriceScheme(row: ISupplierProductData, chainId: string): any {
    const name = 'retailPriceScheme_' + chainId.replace(/-/g, '');
    return row.retailPriceSchemes[name];
  }

  public getRetailerHasInvalidValues(row: ISupplierProductData, retailerId: string): boolean {
    return row.retailersWithInvalidValues.includes(retailerId);
  }

  public newProduct() {
    this._router.navigate(['/products/new']);
  }

  public createOffer() {
    this._createOfferDataService.productIds = this.selection.map(x => x.id);
    this._router.navigate(['/offers/new']);
  }

  public createSalesOrderFromSelection() {
    const productIds = this.selection.map(x => x.id);
    this.soDataService.productIds = productIds;
    this._router.navigate(['orders/sales/new']);
  }

  public export() {
    var ids = this.selection.map(x => x.id)
    if (ids?.length == 0) {
      ids = this.dataSource?.data?.map((x: any) => x.id);
    }
    if (ids?.length > 0) {
      this.closeDotMenu.emit();
      this.dialog.open(ProductsRetailerExportDialogComponent, {
        resolve: () =>
          this._productService.exportSupplierProducts({ productIds: ids })
            .pipe(
              map(res => {
                this._productService.getSupplierProductsExport(res.id)
              })
            )
      });
    }
  }

  public getImageDescription(product: ISupplierProductData) {
    const { activeViaOffer, offerActiveTo } = product || {};
    if (!activeViaOffer) return null;
    if (!offerActiveTo) return this._translateService.instant('cards.products.product_base_info.in_active_offer_termless');
    return `${this._translateService.instant('cards.products.product_base_info.in_active_offer_until')} ${this._formDate.transform(offerActiveTo)}`;
  }

  public openConformityDeclarationDialog(selection = this.selection) {
    this.closeDotMenu.emit();
    this.dialog.open(ConformityDeclarationDialog, {
      width: '700px',
      height: 'auto',
      data: {
        productIds: selection.map(x => x.id),
        contactOptions: this.formData.conformityDeclarationContacts
      }
    }, res => {

    }
    );
  }

}

export interface ISupplierProductData {
  id: string;
  fullName: string;
  weight: string;
  ean: number;
  brand: string;
  segment: string;
  status: number;
  imageUrl: string;
  imageThumbUrl: string;
  imageThumbSmallUrl: string;
  retailersWithInvalidValues: string[];
  retailPriceSchemes: IRetailPriceSchemes;
  activeViaOffer: boolean;
  offerActiveTo: Date;
}

interface IRetailPriceSchemes {
  retailPriceScheme?: IRetailPriceScheme;
}

interface IRetailPriceScheme {
  campaign: boolean;
  campaignFrom: string;
  campaignTo: string;
  campaignInfo: any;
  mainPrice: number;
  publicPrice: number;
  retailPrice: number;
}

export interface ISupplierCompanyData {
  shops: number;
  listed_price: string;
  hasCampaign: boolean;
  campaignInfo: any;
}

export interface ISupplierInfo {
  company: string;
  as: string;
}

export interface IProductInfo {
  name: string;
  weight: string;
}
