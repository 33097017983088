import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";

@Injectable({ providedIn: "root" })
export class AiService {
  constructor(
    private _http: HttpClient
  ) { }

  public translate(req: IAiTranslateRequest) {
    return this._http.post<string>(`api/ai/translate`, req);
  }
}

export interface IAiTranslateRequest {
  languageFrom: string;
  languageTo: string;
  text: string;
}