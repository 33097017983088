<card-expanding [formGroup]="form" [valid]="productValidity.getCardStatusValidType(cardSection)" [expanded]="isExpanded" (onExpand)="saveLocalExpandedValue($event)">
  <span header-title>{{ title | translate}}</span>

  <div header-actions *requiredRights="[UserAction.ManageProducts]">
    <ng-container *ngIf="isOwnProduct">
      <ng-container *ngIf="!locked">
        <lxm-button *ngIf="!edit" type="beige" icon="edit" title="action.edit" (click)="toggleEdit()"></lxm-button>
        <lxm-button *ngIf="edit" type="grey" icon="back" label="action.cancel" (click)="toggleEdit()">
        </lxm-button>
        <lxm-button *ngIf="edit" type="beige" icon="save" label="action.save" [loading]="saveLoading"
          (click)="save()" buttonType="submit"></lxm-button>
      </ng-container>
      <ng-container *ngIf="locked">
        {{ 'global.locked_by' | translate }}: {{ lockedBy.name }} ({{ lockedAt }})
      </ng-container>
    </ng-container>
  </div>

  <div body>

    <div class="grid grid-cols-1 xl:grid-cols-2 w-full gap-4">
      <div class="flex flex-col">

        <form-field label="cards.products.product_electronics.label.is_electronics_device" description="cards.products.product_electronics.label_description.is_electronics_device" [readonly]="!edit">
          <slide-toggle toggle for="isElectronicsDevice" [readonly]="!edit"></slide-toggle>
          <validation-errors for="isElectronicsDevice"></validation-errors>
        </form-field>

        <form-field label="cards.products.product_electronics.label.has_integrated_battery" description="cards.products.product_electronics.label_description.has_integrated_battery" [readonly]="!edit"
          [required]="productValidity.shouldValidate ? productValidity.isRequiredByRetailer(cardSection, productField.IntegratedBatteryWeight, form.get('hasIntegratedBattery').value) : null">
          <slide-toggle toggle for="hasIntegratedBattery" [readonly]="!edit"></slide-toggle>
          <validation-errors for="hasIntegratedBattery"></validation-errors>
        </form-field>
      </div>

      <div class="flex flex-col">

        <form-field label="cards.products.product_electronics.label.battery_type" 
          description="cards.products.product_electronics.label_description.battery_type" 
          [readonly]="!edit"
          for="batteryTypeId"
          [required]="productValidity.shouldValidate ? productValidity.isRequiredByRetailer(cardSection, productField.BatteryType, form.get('batteryTypeId').value) : null">
          <lxm-select select class="w100p" [options]="batteryTypeOptions" for="batteryTypeId" value="id"
            labelField="translatedValue" [isTranslated]="true" [sortByLabel]="true"></lxm-select>
          <validation-errors for="batteryType"></validation-errors>
        </form-field>

        <ng-container *ngIf="form.get('hasIntegratedBattery').value">
          <form-field for="integratedBatteryWeight"
            label="cards.products.product_electronics.label.integrated_battery_weight"
            description="cards.products.product_electronics.label_description.integrated_battery_weight"
            [readonly]="!edit"
            type="input-text"
            [required]="productValidity.shouldValidate ? productValidity.isRequiredByRetailer(cardSection, productField.IntegratedBatteryWeight, form.get('integratedBatteryWeight').value) : null">
            <validation-errors for="integratedBatteryWeight"></validation-errors>
          </form-field>
        </ng-container>
      </div>

    </div>

  </div>

</card-expanding>