import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TitleService, UserService } from 'src/app/_services/';
import { FormGroup, FormControl } from '@angular/forms';

@Component({
  selector: "reset-password",
  templateUrl: "./reset-password.component.html",
  styleUrls: ["../login.scss"]
})
export class ResetPasswordComponent implements OnInit {
  public title = "login.new_password.title";
  public userId: string;
  public token: string;

  public errors = [];

  public passwordView = ResetPasswordView.ResetPassword;
  public successView = ResetPasswordView.ResetSucess;

  public view = this.passwordView;

  public resetForm: FormGroup = new FormGroup({
    password: new FormControl(),
    passwordConfirm: new FormControl()
  });

  constructor(
    private _route: ActivatedRoute,
    private _userService: UserService,
    private _titleService: TitleService
  ) {}

  public newPasswordLoading = false;
  public requestNewPassword() {
    this.newPasswordLoading = true;
    const form = this.resetForm.value;
    const data = {
      userId: this.userId,
      token: this.token,
      password: form.password,
      passwordConfirm: form.passwordConfirm
    };

    this._userService.resetPassword(data).result(
      this.resetForm,
      () => {
        this.newPasswordLoading = false;
        this.view = this.successView;
        this.resetErrors();
      },
      (err) => {
        this.newPasswordLoading = false;

        if (err.validationSummary) {
          this.errors = [err.validationSummary];
          if (err.validationResults?.token) {
            this.errors.push(err.validationResults?.token[0].value);
          }
        } else {
          this.resetErrors();
        }
      }
    );
  }

  public resetErrors() {
    this.errors = [];
  }

  ngOnInit() {
    this._route.params.subscribe((params) => {
      this.userId = params["userId"];
      this.token = params["token"];
    });

    this._titleService.setTitle(this.title);
  }
}

enum ResetPasswordView {
  ResetPassword = 1,
  ResetSucess = 2
}
