<div class="flex w-full items-center justify-end py-5">
  <guest-language-selector></guest-language-selector>
</div>

<div class="activate-account-container" [class.has-password]="hasPassword">
  <card class="card-left" [formGroup]="profileData">
    <span header-title class="acc-header">
      <img src="/assets/img/brand/LXM.svg" draggable="false" class="app-logo"/>

      {{'cards.activate_account.title' | translate }}</span
    >
    <div body class="mt15 mb15 justify-between">
      <div class="app-logo"></div>

      <div class="row">
        <div class="col-12 pr0">
          <form-field
            label="cards.user_settings.profile.label.user_e_mail"
            for="email"
            type="input-email"
            [readonly]="true"
            [required]="true"
          >
            <validation-errors for="email"></validation-errors>
          </form-field>
          <form-field
            label="cards.user_settings.profile.label.user_first_name"
            for="firstName"
            type="input-text"
            [required]="true"
          >
            <validation-errors for="firstName"></validation-errors>
          </form-field>
          <form-field
            label="cards.user_settings.profile.label.user_surname"
            for="lastName"
            type="input-text"
            [required]="true"
          >
            <validation-errors for="lastName"></validation-errors>
          </form-field>
          <form-field
            label="cards.user_settings.profile.label.user_id"
            for="idCode"
            type="input-number"
            description="cards.user_settings.profile.label_description.user_id"
          >
            <validation-errors for="idCode"></validation-errors>
          </form-field>
          <form-field
            label="cards.user_settings.profile.label.user_phone_short"
          >
            <div any class="flex w-full flex-wrap gap-2">
              <lxm-select
                class="!w-[125px] flex flex-1"
                [searchable]="true"
                [clearable]="false"
                [options]="phoneCodes"
                labelField="value"
                value="id"
                for="phoneCode"
              >
              </lxm-select>
              <lxm-input
                class="flex flex-1 min-w-[125px]"
                for="phoneNumberShort"
              ></lxm-input>
            </div>
            <validation-errors for="phoneCode"></validation-errors>
            <validation-errors for="phoneNumberShort"></validation-errors>
            <validation-errors for="phoneNumber"></validation-errors>
          </form-field>

          <form-field
            label="cards.user_settings.profile.label.user_job_title"
            for="jobTitle"
            type="input-text"
          >
            <validation-errors for="jobTitle"></validation-errors>
          </form-field>

          <!-- <form-field label="cards.user_settings.profile.label.user_portfolio_description"
                for="portfolioDescription" type="input-text">
                <validation-errors for="portfolioDescription"></validation-errors>
              </form-field>

              <form-field label="cards.user_settings.profile.label.assistants">
                <lxm-select select for="assistants" class="w-full" labelField="label" [searchable]="true"
                  [multiple]="true" [options]="assistantsOptions" compareField="id">
                </lxm-select>
                <validation-errors for="assistants"></validation-errors>
              </form-field> -->

          <ng-container *ngIf="!hasPassword">
            <!-- CHROME AUTOFILL FIX -->
            <input type="text" class="hidden-input" readonly />

            <form-field
              label="cards.user_settings.profile_password.label.user_password"
              for="newPassword"
              type="input-password"
            >
              <validation-errors for="newPassword"></validation-errors>
            </form-field>
            <form-field
              label="cards.user_settings.profile_password.label.user_password_confirm"
              for="newPasswordConfirm"
              type="input-password"
            >
              <validation-errors for="newPasswordConfirm"></validation-errors>
            </form-field>
          </ng-container>
        </div>
        <div class="col-12 pr0">
          <info-array
            class="mt20 mb20"
            *ngIf="hasPassword"
            prelude="cards.activate_account.access_will_be_added_to_existing_account"
          >
          </info-array>

          <div class="flex flex-row justify-center">
            <lxm-button
              type="primary"
              buttonType="submit"
              class="w-full max-w-[250px]"
              icon="checkmark-white"
              label="cards.activate_account.action.activate"
              [loading]="saveLoading"
              (click)="activate()"
            >
            </lxm-button>
          </div>
        </div>
      </div>
    </div>
  </card>

  <div
    class="card-right logo-no-company"
    [class.logo-has-company]="imageSource"
    [class.logo-no-company]="!imageSource"
  >
    <div class="custom-logo-wrapper">
      <img draggable="false" *ngIf="imageSource" [src]="imageSource" />
    </div>
  </div>
</div>
