<card-expanding [formGroup]="form" [valid]="productValidity.getCardStatusValidType(cardSection)" [expanded]="isExpanded" (onExpand)="saveLocalExpandedValue($event)">
  <span header-title>{{ title | translate}}</span>

  <div header-actions *ngIf="isOwnProduct">
    <ng-container *requiredRights="[UserAction.ManageProducts]">
      <ng-container *ngIf="!locked">
        <lxm-button *ngIf="!edit" type="beige" icon="edit" title="action.edit" (click)="toggleEdit()"></lxm-button>
        <lxm-button *ngIf="edit" type="grey" icon="back" label="action.cancel" (click)="toggleEdit()"></lxm-button>
        <lxm-button *ngIf="edit" type="beige" icon="save" label="action.save" [loading]="saveLoading" (click)="save()" buttonType="submit"></lxm-button>
      </ng-container>
      <ng-container *ngIf="locked">
        {{ 'global.locked_by' | translate }}: {{ lockedBy.name }} ({{ lockedAt }})
      </ng-container>
    </ng-container>
  </div>

  <div body>

    <div class="row">
      <div class="col-6 no-padding">

        <form-field for="latinName" label="cards.products.product_fish.label.scientific_name" description="cards.products.product_fish.label_description.scientific_name" [readonly]="!edit" type="input-text"
          [required]="productValidity.shouldValidate ? productValidity.isRequiredByRetailer(cardSection, productField.ScientificNameOfFish, form.get('latinName').value) : null">
          <validation-errors for="latinName"></validation-errors>
        </form-field>

        <form-field for="fishingArea" label="cards.products.product_fish.label.fishing_area" description="cards.products.product_fish.label_description.fishing_area" [readonly]="!edit" type="input-text"
          [required]="productValidity.shouldValidate ? productValidity.isRequiredByRetailer(cardSection, productField.FishingArea, form.get('fishingArea').value) : null">
          <validation-errors for="fishingArea"></validation-errors>
        </form-field>

      </div>

      <div class="col-6 no-padding">

        <form-field label="cards.products.product_fish.label.state_fish" for="stateId" description="cards.products.product_fish.label_description.state_fish" [readonly]="!edit"
          [required]="productValidity.shouldValidate ? productValidity.isRequiredByRetailer(cardSection, productField.StateOfFish, form.get('stateId').value) : null">
          <lxm-select select class="w100p" 
            [options]="stateOptions" for="stateId" 
            [searchable]="true" 
            [readonly]="!edit"
            value="id" 
            labelField="translatedValue" 
            [isTranslated]="true">
          </lxm-select>
            <validation-errors for="stateId"></validation-errors>
          </form-field>

        <form-field label="cards.products.product_fish.label.fish_trim" for="stateId" description="cards.products.product_fish.label_description.fish_trim" [readonly]="!edit"
          [required]="productValidity.shouldValidate ? productValidity.isRequiredByRetailer(cardSection, productField.FishTrim, form.get('fishTrimId').value) : null">
          <lxm-select select class="w100p" 
            [options]="fishTrimOptions" for="fishTrimId" 
            [readonly]="!edit"
            [searchable]="true" 
            value="id" 
            labelField="translatedValue" 
            [sortByLabel]="true"
            [isTranslated]="true">
          </lxm-select>
            <validation-errors for="fishTrimId"></validation-errors>
          </form-field>

      </div>

    </div>

  </div>

</card-expanding>
