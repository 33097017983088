<ng-container [formGroup]="formGroup">
  <div class="separator"></div>

  <!-- {{fgInternal.value | json}} -->

  <form-field
    [formGroup]="fgInternal"
    label="cards.settings.product_management_settings.calendar_group_edit.label.type"
  >
    <lxm-radio
      any
      for="selectedOfferType"
      [options]="typeOptions"
      class="offer-type"
      discriminator="id"
    >
    </lxm-radio>
    <validation-errors for="offerType"></validation-errors>
  </form-field>

  <ng-container [ngSwitch]="fgInternal.value.selectedOfferType?.id">
    <!-- PROCUREMENT -->

    <ng-container *ngSwitchCase="CalendarOfferType.Procurement">
      <form-field
        dialog
        label="cards.settings.product_management_settings.calendar_group_edit.label.is_for_procurement"
      >
        <div any class="f-1 align-center flex flex-row">
          <lxm-select
            select
            for="procurementCalendarCategoryRules"
            value="id"
            [options]="procurementCalendarCategoryRulesOptions"
            [multiple]="true"
          ></lxm-select>
        </div>
      </form-field>

      <ng-container>
        <form-field
          dialog
          label="cards.settings.product_management_settings.calendar_group_edit.label.procurement_items"
        >
          <product-procurement-list
            any
            for="procurementItems"
          ></product-procurement-list>
        </form-field>
      </ng-container>
    </ng-container>

    <ng-container *ngSwitchCase="CalendarOfferType.Campaign">
      <form-field
        dialog
        label="cards.settings.product_management_settings.calendar_group_edit.label.is_for_campaign"
         [required]="true"
      >
        <div any class="f-1 align-center flex flex-row">
          <lxm-select
            select
            for="campaignType"
            value="id"
            [clearable]="false"
            [options]="calendarGroupCampaignTypesOptions"
          ></lxm-select>
        </div>
      </form-field>

      <!-- <ng-container
        *ngIf="isCampaignTypesSelectVisible"
        [ngSwitch]="isCampaignTypeAdded"
      >
        <lxm-button
          *ngSwitchCase="false"
          label="cards.settings.product_management_settings.calendar_group_edit.label.add_price_model"
          type="transparent"
          padding="8px 0"
          icon="plus-dark"
          (click)="addCampaignType()"
        ></lxm-button> -->

        <form-field dialog *ngIf="isCampaignTypesSelectVisible" label="cards.settings.product_management_settings.calendar_group_edit.label.price_models" [required]="true">
          <div any class="f-1 align-center flex flex-row">
            <lxm-select
              select
              for="campaignTypes"
              labelField="value"
              [options]="campaignTypes"
              [multiple]="true"
            ></lxm-select>
          </div>
        </form-field>
      <!-- </ng-container> -->
    </ng-container>
  </ng-container>

  <!-- <div class="separator"></div> -->

  <form-field
    dialog
    label="cards.settings.product_management_settings.calendar_group_edit.label.gs1_segments"
    [readonly]="!canEdit"
  >
    <lxm-select
      select
      for="gs1Segments"
      [searchable]="true"
      labelField="title"
      compareField="id"
      [multiple]="true"
      url="api/clvac/product-level-gs1-segments/"
    >
      <validation-errors for="gs1Segments"></validation-errors>
      <validation-errors
        for="gs1SegmentIds"
        aliasTo="gs1Segments"
      ></validation-errors>
    </lxm-select>
  </form-field>
</ng-container>
