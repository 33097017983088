import {
  AfterContentInit,
  AfterViewInit,
  Directive,
  Inject,
  ViewChild,
} from "@angular/core";
import { FormArray, FormControl, FormGroup } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Observable } from "rxjs";
import { DialogComponent } from "src/app/components";
import { DialogHelper, LxmDialog } from "src/app/_helpers";
import {
  AuthenticationService,
  ProductPricelistService,
} from "src/app/_services";
import {
  ICountryTranslated,
  IRetailChain,
} from "../product-info/product-info.model";
import { IPriceSchemeBase } from "./product-pricelist.model";
import { AppState } from "src/app/state/app.state";
import { TranslateService } from "@ngx-translate/core";
import { ClvType, UserAction } from "src/app/enum";
import { ProductPricelistBaseSchemeDialog } from "./template/base-scheme/base-scheme.dialog";
import { nextSchemeDay } from "src/app/_helpers/utilities";

@Directive()
export abstract class ProductPricelistDialogBase<
  TDialogData extends IDialogDataBase<TFormData, TScheme>,
  TFormData,
  TScheme extends IPriceSchemeBase,
> implements AfterViewInit
{
  public UserAction = UserAction;
  public ClvType = ClvType;

  @ViewChild("dialog", { static: true, read: DialogComponent })
  private _dialog: DialogComponent;

  public form: FormGroup;

  protected _timeZone: string;

  public countryOptions: ICountryTranslated[];
  public retailChains: IRetailChain[];
  public campaignTypeOptions: any[] = [];

  public get nextDay() {
    return nextSchemeDay();
  }

  protected _initialPatch = false;

  public digits = {
    discount: 1,
    margin: 1,
    buyInPrice: 3,
    listPrice: 3,
    defaultPrice: 3,
  };

  constructor(
    protected _appState: AppState,
    protected _productPricelistService: ProductPricelistService,
    @Inject(MAT_DIALOG_DATA) public data: TDialogData,
    public dialogRef: MatDialogRef<ProductPricelistBaseSchemeDialog>,
    public dialog: LxmDialog,
    private _authenticationService: AuthenticationService,
    private _dialogHelper: DialogHelper,
    private _translateService: TranslateService,
  ) {
    const priceListCard = this.data.pricelistCard;
    this.countryOptions = priceListCard?.formData.countries;
    this.retailChains = priceListCard?.formData.retailChains;

    this._authenticationService.tenantTimeZone.subscribe((x) => {
      this._timeZone = x;
    });
    this.form = this.createForm();
  }

  public createPriceGroupFormGroup(data: any) {
    const { priceGroup, margin, retailPrices } = data;

    return new FormGroup({
      priceGroup: new FormControl(priceGroup),
      margin: new FormControl(margin),
      retailPrices: new FormControl(retailPrices),
    });
  }

  public ngAfterViewInit(): void {
    this._dialog.dataLoaded$.subscribe((state) => {
      if (!state) {
        return;
      }

      this.onLoad();
      // const schemeData = this.data.scheme as any
      // this.data.scheme = schemeData?.priceScheme;
      const formData = this.normalizeData(
        this.data.scheme ? { ...this.data.scheme } : null,
      );
      this._initialPatch = true;
      this.form.patchValue(formData, { emitEvent: true });

      // TODO - REFACTOR
      const { priceGroups } = formData as any;
      if (priceGroups) {
        this.form.setControl("priceGroups", new FormArray(priceGroups));
      }

      this._initialPatch = false;
      this.bindCalculations();
    });
  }

  public onNoClick(): void {
    this.dialogRef.close();
  }

  public closeDialog(res = null) {
    this.dialogRef.close(res);
  }

  protected onLoad(): void {}

  public get schemeId() {
    return this.data?.scheme?.id;
  }

  public get scheme() {
    return this.data?.scheme;
  }

  public deleteLoading = false;
  protected deleteSchemeInternal(
    deleteRequest: (id: string) => Observable<string>,
    onSuccess?: () => void,
    dialogClose?: () => void,
  ) {
    this.dialog.confirm(
      {
        image: "delete-modal-image",
        template: `
      <p class="dialog-title">${this._translateService.instant("cards.products.product_pricelist.delete.title")}</p>
      <p>
        ${this._translateService.instant("cards.products.product_pricelist.delete.message")}
      </p>
      `,
      },
      () => {
        this.deleteLoading = true;

        let id = this.schemeId;

        deleteRequest(id).result(this.form, () => {
          if (onSuccess) {
            onSuccess();
          }

          if (dialogClose) {
            dialogClose();
            return;
          }

          this.closeDialog();
          this.deleteLoading = false;
        });
      },
    );
  }

  public openClassificatorValueDialog(
    clvType: ClvType,
    formRef: FormControl,
    optionsRefName: string,
    valueAsArray = false,
    formData: any = { countries: this.countryOptions },
  ): void {
    this._dialogHelper.openFormNewClassificatorValueDialog(
      clvType,
      formRef,
      optionsRefName,
      valueAsArray,
      formData,
      this,
    );
  }

  public abstract deleteScheme(): void;
  protected abstract normalizeData(scheme: TScheme): TScheme;
  protected abstract save(): void;
  protected abstract bindCalculations(): void;
  protected abstract createForm(): FormGroup;
}

export interface IDialogDataBase<TData, TScheme extends IPriceSchemeBase> {
  formData: TData;
  scheme: TScheme;
  pricelistCard: any;
}
