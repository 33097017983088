<!-- SUPPLIER SEARCH  -->
<search [formGroup]="searchData" [isOpen]="isOpen" [isLoading]="isLoading" (onToggle)="toggleOpen($event)" placeholder="cards.products.products_supplier_search.placeholder" (onSubmit)="submit()">
  <ng-container panel-left>
    <scope translationsPath="cards.products.products_supplier_search">
      <form-field panel label="brand" for="brands">
        <lxm-select select
                    labelField="value"
                    [searchable]="true"
                    for="brands"
                    [multiple]="true"
                    url="api/clvac/brands/">
        </lxm-select>
      </form-field>
      <form-field panel label="sub_brand" for="subBrands">
        <lxm-select select
                    labelField="value"
                    [searchable]="true"
                    for="subBrands"
                    [multiple]="true"
                    url="api/clvac/brands/">
        </lxm-select>
      </form-field>
      <form-field panel label="origin" for="countries">
        <lxm-select select for="countries"
          value="id"
          [options]="countryOptions"
          [isTranslated]="true"
          labelField="translatedName"
          [sortByLabel]="true"
          [searchable]="true"
          [multiple]="true"
          labelIconField="iso3"
          labelIconFallback="global"
          labelIconClass="flag-icon">
        </lxm-select>
      </form-field>
      <form-field panel label="manufacturer" for="manufacturers">
        <lxm-select select
                    labelField="value"
                    [searchable]="true"
                    for="manufacturers"
                    [multiple]="true"
                    url="api/clvac/manufacturers/">
        </lxm-select>
      </form-field>
      <form-field panel label="importer" for="importers">
        <lxm-select select
                    labelField="value"
                    [searchable]="true"
                    for="importers"
                    [multiple]="true"
                    url="api/clvac/manufacturers/">
        </lxm-select>
      </form-field>
      <form-field panel label="nutritional_claims" for="nutritionalClaims">
        <lxm-select select for="nutritionalClaims"
                    value="id"
                    labelField="name"
                    [options]="nutritionalClaimsOptions"
                    [sortByLabel]="true"
                    [multiple]="true"
                    [searchable]="true">
        </lxm-select>
      </form-field>
    </scope>
  </ng-container>
  <ng-container panel-right>
    <scope translationsPath="cards.products.products_supplier_search">
      <form-field panel label="gs1_tree" for="gs1Segments">
        <lxm-select select
                    labelField="name"
                    [searchable]="true"
                    [multiple]="true"
                    for="gs1Segments"
                    url="api/clvac/gs1-segments/">
        </lxm-select>
      </form-field>
      <form-field panel label="product_tree" for="segments">
        <lxm-select select for="segments"
          [options]="segmentOptions"
          [searchable]="true"
          [multiple]="true"
          [labelFn]="segmentTreeLabel">
        </lxm-select>
      </form-field>
      <form-field panel label="product_status" for="assortmentStatuses">
        <lxm-select select
                    labelField="name"
                    [multiple]="true"
                    value="id"
                    [options]="statuses"
                    for="assortmentStatuses">
        </lxm-select>
      </form-field>
      <form-field panel label="product_tags" for="productTags">
        <div any class="flex f-1 flex-row align-start">
          <lxm-select
            labelField="value"
            [multiple]="true"
            value="id"
            [options]="tagsOptions"
            for="productTags">
          </lxm-select>
          <lxm-select class="ml15" *ngIf="searchData.get('productTags').value?.length > 1"
                    width="100px"
                    labelField="name"
                    value="id"
                    [disabled]="searchData.get('productTags').value?.length < 2"
                    [options]="searchOperatorOptions"
                    [clearable]="false"
                    for="productTagsSearchOperator">
          </lxm-select>
        </div>
      </form-field>
      <form-field panel label="productValidation" for="productValidation">
        <lxm-select select
                    labelField="name"
                    value="id"
                    [options]="searchProductValidationOptions"
                    [searchable]="true"
                    for="productValidation">
        </lxm-select>
      </form-field>
      <form-field panel label="retailers" for="retailers" *ngIf="showRetailersForProductValidation">
        <lxm-select select
                    labelField="name"
                    value="id"
                    [multiple]="true"
                    [options]="reailersOptions"
                    [searchable]="true"
                    for="retailers">
        </lxm-select>
      </form-field>
      <form-field panel label="priceSchemeType" for="productPriceSchemeType">
        <lxm-select select
                    value="id"
                    [options]="schemeTypeOptions"
                    for="productPriceSchemeType">
        </lxm-select>
      </form-field>
      <form-field panel label="client" for="client" *ngIf="productPriceSchemeFilterSelected">
        <lxm-select select
                    labelField="name"
                    value="id"
                    [options]="clientsOptions"
                    [searchable]="true"
                    for="client">
        </lxm-select>
      </form-field>
    </scope>
  </ng-container>
</search>