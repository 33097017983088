import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute, UrlSegment } from '@angular/router';
import { AuthenticationService, UserService, LocaleService } from 'src/app/_services';
import { TitleService } from '../../_services/title.service';
import { NgForm, FormGroup, FormControl } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { AppState } from 'src/app/state/app.state';

@Component({
  selector: 'login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.scss'],
})
export class LoginComponent implements OnInit {

  title = 'login.log_in';

  @ViewChild('username') usernameElement: ElementRef;

  private _returnUrl: string;

  public loginLoading = false;
  public requestResetPasswordLinkLoading = false;

  public errors: string[] = [];

  public LoginType = LoginType;
  public view = LoginView.Login;
  public loginView = LoginView.Login;
  public resetView = LoginView.ResetPassword;
  public resetSentView = LoginView.ResetSent;

  public selectedLogin = LoginType.MobileId;

  public passwordForm = new FormGroup({
    username: new FormControl(),
    password: new FormControl()
  });

  public mobileIdForm = new FormGroup({
    phoneNumber: new FormControl()
  });

  public resetPasswordForm = new FormGroup({
    email: new FormControl()
  });

  public toResetPassword() {
    this.view = LoginView.ResetPassword;
    this.resetErrors();
  }

  public toLogin() {
    this.view = LoginView.Login;
    this.resetErrors();
  }

  public toResetSentView() {
    this.view = this.resetSentView;
    this.resetErrors();
  }

  public customFor: string = null;

  constructor(
    public state: AppState,
    private _localeService: LocaleService,
    private _router: Router,
    private _route: ActivatedRoute,
    private _authenticationService: AuthenticationService,
    private _userService: UserService,
    private _titleService: TitleService) {

      const { customFor } = this._route.snapshot.data || {};

      if (customFor) {
        this.customFor = customFor;
        this.selectedLogin = LoginType.AzureAd;
      }

      this.mobileIdForm.get("phoneNumber").valueChanges.subscribe((x: string) => {

        if (x && x.indexOf('@') > 0) {

          this.passwordForm.patchValue({
            username: x
          });

          this.mobileIdForm.patchValue({
            phoneNumber: ''
          });

          this.selectedLogin = LoginType.Password;

          setTimeout(() => {
            document.getElementById("username").focus();  
          }, 0);
        }

      });

  }

  public get registerUrl() {
    switch(this.customFor) {
      case 'selver':
        return 'https://lexi.market/et/selver/';
      default: 
        return null;
    }
  }

  public get imageUrl() {
    switch(this.customFor) {
      case 'selver':
        return "../../assets/img/login/Selver.png";
      default:
        return "../../assets/img/login/group-13.png";
    }
  }

  ngOnInit() {

    if (this._route.snapshot.routeConfig.path === 'logout') {
      this._router.navigate(['/login']);
      return;
    }

    this._titleService.setTitle(this.title);

    if (this._route.snapshot.routeConfig.path === 'idcard/:sessionId') {

      this.toIdLogin();
      this.loginLoading = true;

      const sessionId = this._route.snapshot.params['sessionId'];
      this._authenticationService.loginWithIdCard(sessionId)
        .result(null, _ => {
          this._router.navigate(['/'])
            .then(() => {
              this.loginLoading = false;
            });
        }, err => {
          this.loginLoading = false;
          if (err.validationSummary) {
            this.errors = [err.validationSummary];
          } else {
            this.resetErrors();
          }
        });

      return;
    }

    if (this._route.snapshot.routeConfig.path === 'azureAd/:sessionId') {

      this.toAzureAdLogin();
      this.loginLoading = true;

      const sessionId = this._route.snapshot.params['sessionId'];
      this._authenticationService.loginWithAzureAd(sessionId)
        .result(null, _ => {
          this._router.navigate(['/'])
            .then(() => {
              this.loginLoading = false;
            });
        }, err => {
          this.loginLoading = false;
          if (err.validationSummary) {
            this.errors = [err.validationSummary];
          } else {
            this.resetErrors();
          }
        });

      return;
    }

    const lastAuthError = this._authenticationService.lastError;
    if (lastAuthError?.validationSummary) {
      this.errors = [lastAuthError.validationSummary];
    }

    // reset login status
    this._authenticationService.logout();

    // get return url from route parameters or default to '/'
    this._returnUrl = this._route.snapshot.queryParams['returnUrl'] || '/';

  }

  public resetErrors() {
    this.errors = [];
  }

  public toIdLogin() {
    this.selectedLogin = LoginType.IdCard;
  }

  public toMobileLogin(): void {
    this.selectedLogin = LoginType.MobileId;
  }

  public toPasswordLogin(): void {
    this.selectedLogin = LoginType.Password;
  }

  public toAzureAdLogin(): void {
    this.selectedLogin = LoginType.AzureAd;
  }

  public requestResetPasswordLink() {
    const f = this.resetPasswordForm.value;
    this.requestResetPasswordLinkLoading = true;
    this._userService.requestResetPasswordLink(f.email, this._localeService.currentLanguage.value)
      .result(this.resetPasswordForm, res => {
        this.requestResetPasswordLinkLoading = false;
        this.toResetSentView();
      }, err => {
        this.requestResetPasswordLinkLoading = false;
        if (err.validationSummary) {
          this.errors = [err.validationSummary];
        }
      });
  }

  public loginPassword() {
    const f = this.passwordForm.value;
    this.loginLoading = true;
    this._authenticationService.loginWithPassword(f.username, f.password)
      .result(this.passwordForm, () => {
        this._router.navigate([this._returnUrl || '/'])
          .then(() => {
            this.loginLoading = false;
          });
      }, err => {
        this.loginLoading = false;
        if (err.validationSummary) {
          this.errors = [err.validationSummary];
        } else {
          this.resetErrors();
        }
      });
  }

  public loginMobileId() {
    const f = this.mobileIdForm.value;
    this.loginLoading = true;

    this._authenticationService.initMobileIdAuth(f.phoneNumber)
      .result(this.mobileIdForm, res => {

        this.mobileIdForm['verificationCode'] = res.verificationCode;

        this._authenticationService.loginWithMobileId(res.sessionId)
          .result(null, _ => {
            this._router.navigate(['/'])
              .then(() => {
                this.loginLoading = false;
              });
          }, err => {
            this.loginLoading = false;
            this.mobileIdForm['verificationCode'] = null;
            if (err.validationSummary) {
              this.errors = [err.validationSummary];
            } else {
              this.resetErrors();
            }
          });

      }, (err) => {
        this.loginLoading = false;
        if (err.validationSummary) {
          this.errors = [err.validationSummary];
        } else {
          this.errors = [];
        }
      });
  }

  public loginIdCard() {
    this.loginLoading = true;

    this._authenticationService.initIdCardAuth()
      .result(null, sessionId => {
        const callbackUrl = `${document.location.origin}/login/idcard/${sessionId}`;
        document.location.href = `${environment.apiUrl}api/idcard/auth/${sessionId}?callbackUrl=${encodeURIComponent(callbackUrl)}`;
      }, (err) => {
        this.loginLoading = false;
        if (err.validationSummary) {
          this.errors = [err.validationSummary];
        } else {
          this.resetErrors();
        }
      });
  }

  public loginAzureAd() {
    this.loginLoading = true;

    this._authenticationService.initAzureAdAuth()
      .result(null, sessionId => {
        const callbackUrl = `${document.location.origin}/login/azureAd/${sessionId}`;
        document.location.href = `${environment.apiUrl}api/azureAd/auth/${sessionId}?callbackUrl=${encodeURIComponent(callbackUrl)}`;
      }, (err) => {
        this.loginLoading = false;
        if (err.validationSummary) {
          this.errors = [err.validationSummary];
        } else {
          this.resetErrors();
        }
      });
  }
}

@Component({
  selector: 'app-visitor',
  template: '',
})
export class VisitorComponent {

  constructor(
    private _route: ActivatedRoute,
    private _authenticationService: AuthenticationService) {
  }

  ngOnInit() {
    const token = this._route.snapshot.params.token;
    const returnUrl = this._route.snapshot.queryParams['returnUrl'] || '/';
    this._authenticationService.authToken(token, returnUrl);
  }

}

@Component({
  selector: 'activate-account',
  template: '',
})
export class ActivateAccountComponent {

  constructor(
    private _route: ActivatedRoute,
    private _authenticationService: AuthenticationService) {
  }

  ngOnInit() {
    const token = this._route.snapshot.params.token;
    const returnUrl = this._route.snapshot.queryParams['returnUrl'] || '/';
    this._authenticationService.authInvitationToken(token, returnUrl);
  }

}


@Component({
  selector: 'accept-tenant-invite',
  template: '',
})
export class AcceptTenantInviteComponent {

  constructor(
    private _route: ActivatedRoute,
    private _authenticationService: AuthenticationService) {
  }

  ngOnInit() {
    const token = this._route.snapshot.params.token;
    const returnUrl = this._route.snapshot.queryParams['returnUrl'] || '/';
    this._authenticationService.verifyTenantInvitationToken(token, returnUrl);
  }
}

enum LoginType {
  IdCard = 0,
  MobileId = 1,
  Password = 2,
  AzureAd = 3
}

enum LoginView {
  Login = 0,
  ResetPassword = 1,
  ResetSent = 2
}
