<ng-container [ngSwitch]="readonly && readonlyAsText">
  <div class="checkbox-wrapper" [class.readonly]="readonly" *ngSwitchDefault>
    <label class="checkbox-container gap-[10px]">
      <input
        type="checkbox"
        [checked]="isChecked"
        (change)="select($event)"
        name="checkbox-{{ for }}"
      />
      <span class="checkmark"></span>
      <span class="text-dirtyPurple text-nowrap" *ngIf="label">
        {{ label | translate }}
      </span>
      <ng-content></ng-content>
    </label>
  </div>

  <ng-container *ngSwitchCase="true">
    {{ isChecked | translateEnum: "Bool" }}
  </ng-container>
</ng-container>
