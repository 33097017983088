import {
  Component,
  Input,
  Optional,
  OnInit,
  Output,
  EventEmitter,
  HostListener,
  ViewEncapsulation,
  HostBinding,
  SimpleChanges
} from "@angular/core";
import { FormGroup, ControlContainer } from "@angular/forms";
import { Subscription } from "rxjs";
import { FileService } from "src/app/_services";

@Component({
  selector: "lxm-textarea",
  templateUrl: "./lxm-textarea.component.html",
  encapsulation: ViewEncapsulation.None,
  styleUrls: ["./lxm-textarea.component.scss"]
})
export class LxmTextareaComponent {
  @HostBinding("class.readonly") @Input() public readonly = false;

  @Input() public formGroup: FormGroup;
  @Input() public for: string;
  @Input() public newline = false;
  @Input() public minRows = 1;
  @Input() public maxRows: number;
  @Input() public maxlength: string;
  @Input() public placeholder: string = " ";
  @Input() public isAutosize = true;
  @Input() public quill = false;

  @Input() public inputId: string;

  @Input() public isValid: boolean = true;

  @Output() blur: EventEmitter<any> = new EventEmitter();
  @Output() submit: EventEmitter<any> = new EventEmitter();

  public quillModules: any;

  private _subscription: Subscription;

  constructor(
    @Optional() @Optional() private _controlContainer: ControlContainer,
    private _fileService: FileService
  ) {}

  public ngOnInit(): void {
    if (
      this._controlContainer &&
      this._controlContainer.control instanceof FormGroup
    ) {
      this.formGroup = <FormGroup>this._controlContainer.control;

      if (this.for) {
        this._subscription = this.formGroup
          .get(this.for)
          .statusChanges.subscribe((x) => (this.isValid = x === "VALID"));
      }
    }

    if (this.quill) {
      this.quillModules = {
        imageHandler: {
          upload: (file) => {
            return this._upload(file);
          },
          accepts: ["png", "jpg", "jpeg", "jfif"] // Extensions to allow for images (Optional) | Default - ['jpg', 'jpeg', 'png']
        },
        videoHandler: {
          upload: (file) => {
            return this._upload(file);
          },
          accepts: ["mpeg", "avi"] // Extensions to allow for videos (Optional) | Default - ['mp4', 'webm']
        }
      };
    }
  }

  public get id() {
    return this.inputId || this.for;
  }

  private _upload(file) {
    return new Promise((resolve, reject) => {
      const uploadData = new FormData();
      uploadData.append("file", file, file.name);

      this._fileService
        .upload(uploadData)
        .toPromise()
        .then((result) => {
          resolve(result);
        })
        .catch((error) => {
          reject("Upload failed");
          console.error("Error:", error);
        });
    });
  }

  public get formValue() {
    return this.formGroup.get(this.for)?.value || "-";
  }

  public ngOnChanges(changes: SimpleChanges) {
    if (changes?.readonly?.currentValue) {
      this.isValid = true;
    }
  }

  ngOnDestroy(): void {
    if (this._subscription) {
      this._subscription.unsubscribe();
    }
  }

  @HostListener("keydown", ["$event"])
  keyEvent(event: KeyboardEvent) {
    if (event.ctrlKey && event.keyCode === 13) {
      this.submit.emit();
    }
  }
}
