<card-expanding>
  <span header-title>{{ 'cards.clients.contract_conditions.title' | translate}}</span>
  <div header-actions>
    <lxm-button *ngIf="canEdit"
      type="beige" 
      icon="plus-dark"
      label="cards.clients.contract_conditions.action.add_condition" 
      (click)="openDialog()">
    </lxm-button>
  </div>

  <div body [ngSwitch]="hasContractConditions">
    <div *ngSwitchCase="true">
      <div class="contracts-section">
        <span class="contracts-section_header">
          {{ 'cards.clients.contract_conditions.active_rules' | translate}} ({{clientDiscountRules?.length || 0}})
        </span>
        <div class="contracts-section_body">
          <client-discount-item 
          *ngFor="let discount of clientDiscountRules | slice:0:4"
          (click)="openDialog(discount)"
          [data]="discount">
        </client-discount-item>
          <ng-container *ngIf="showAllRules">
            <client-discount-item 
            *ngFor="let discount of clientDiscountRules | slice:4:1000"
            (click)="openDialog(discount)"
            [data]="discount">
          </client-discount-item>
          </ng-container>
          <div *ngIf="clientDiscountRules?.length > 4" class="toggle-show-all-rules" (click)="toggleShowAllRules()">
            {{(showAllRules ? 
              'cards.clients.contract_conditions.action.show_less' : 
              'cards.clients.contract_conditions.action.show_all') | translate
            }}
          </div>
        </div>
      </div>
    </div>

    <no-content 
      *ngSwitchCase="false" 
      heading="cards.clients.contract_conditions.no_conditions_heading"
      text="cards.clients.contract_conditions.no_conditions_text"
      image="no-contract-conditions">
    </no-content>

  </div>

</card-expanding>