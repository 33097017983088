import { Injectable } from "@angular/core";
import {
  Routes,
  RouterModule,
  Resolve,
  ActivatedRouteSnapshot
} from "@angular/router";
import { AuthGuard } from "./_guards/auth.guard";
import { CanDeactivateGuard } from "./_guards/can-deactivate.guard";
import { Observable, of } from "rxjs";
import { map } from "rxjs/operators";
import { HttpClient } from "@angular/common/http";
import { IUserSettings, IUserState } from "./models";

// APP LAYOUTS
import { GuestLayout } from "./_layout/guest/guest.layout";
import { HomeLayout } from "./_layout/home/home.layout";

// CONTENT LAYOUTS
import { DashboardLayout } from "./views/dashboard/dashboard.layout";
import { ProductsLayout } from "./views/products/products.layout";
import { ProductLayout } from "./views/product/product.layout";
import { OffersLayout, OffersRouter } from "./views/offers/offers.layout";
import { OfferLayoutComponent } from "./views/offers/offer/offer.layout";
import { ReportsLayout } from "./views/reports/reports.layout";
import { SettingsLayout } from "./modules/settings/settings.layout";
import { UserSettingsLayout } from "./views/user-settings/user-settings.layout";
import { LxmSettingsLayout } from "./views/lxm-settings/lxm-settings.layout";
import { CompanyProfileLayout } from "./views/lxm-settings/company-profile/company-profile.layout";
import { PackageInfoLayout } from "./views/lxm-settings/package-info/package-info.layout";
import { UsersLayout } from "./views/lxm-settings/users/users.layout";
import { RolesLayout } from "./views/lxm-settings/roles/roles.layout";

// COMPONENTS
import {
  AcceptTenantInviteComponent,
  ActivateAccountComponent,
  LoginComponent,
  VisitorComponent
} from "./views/login/login.component";
import { ResetPasswordComponent } from "./views/login/reset-password/reset-password.component";
import { AppState } from "./state/app.state";

// RESOLVERS
import {
  UsersResolve,
  UsersFormDataResolve,
  UserRolesResolve,
  RolesFormDataResolve
} from "./resolve/lxm-settings/users.resolve";
import {
  AssortmentManagementDataResolve,
  SettingsFieldDataResolve,
  SettingsFormDataResolve,
  SettingsProductManagementDataResolve
} from "./modules/settings/resolve/settings-formdata.resolve";
import {
  OfferCardResolve,
  OfferCardFormDataResolve,
  CloneOfferCardResolve,
  OfferCardFromCalendarEntryResolve,
  OffersListFormDataResolve,
  RequestedOffersListDataResolve,
  RequestedOffersListFormDataResolve,
  SendingOffersListDataResolve,
  ReceivingOffersListDataResolve
} from "./resolve/offers.resolve";
import {
  ProductCardResolve,
  ProductCardFormDataResolve,
  ProductListFormDataResolve,
  ProductListDataResolve,
  ProductListCachedFilterDataResolve,
  CloneProductCardResolve,
  MarketProductListDataResolve
} from "./resolve/products.resolve";
import { CompanyProfileCardResolve } from "./resolve/company-profile-card.resolve";
import { PackageDataResolve } from "./resolve/package-data.resolve";
import {
  ActivateAccountCardResolve,
  UserProfileCardResolve
} from "./resolve/user-profile-card.resolve";
import { DashboardResolve } from "./resolve/dashboard.resolve";

//
import {
  AuthenticationService,
  ILanguage,
  LocaleService,
  SignalRService
} from "./_services";
import { HubConnectionState } from "@microsoft/signalr";
import { NotFoundComponent } from "./views/error/not-found/not-found.component";
import { authRootMatcher } from "./auth-user-matcher";
import { MailSettingsLayout } from "./views/lxm-settings/mail-settings/mail-settings.layout";
import { MailSettingsCardResolve } from "./resolve/mail-settings-card.resolve";
import { ActivateAccountCardComponent } from "./cards/activate-account/activate-account.card";
import { MarketLayout } from "./views/market/market.layout";
import { OfferKind } from "./enum";
import { ExtraParametersSettingsLayout, ProductFieldsSettingsLayout } from "./views/lxm-settings/product-fields-settings/product-fields-settings.layout";
import { ExtraParametersSettingsCardResolve } from "./resolve/lxm-settings/product-fields-settings-card.resolve";
import { SubscriptionLayout } from "./views/lxm-settings/subscription/subscription.layout";

@Injectable()
export class UserSettingsResolve implements Resolve<any> {
  constructor(
    private _appState: AppState,
    private _http: HttpClient,
    private _signalRService: SignalRService
  ) { }

  resolve(route: ActivatedRouteSnapshot): Observable<IUserSettings> {
    this._signalRService.commonHub.subscribe(connection => {
      if (connection) {
        if (connection.state === HubConnectionState.Disconnected) {
          connection.start();
        }
      }
    });

    if (this._appState.userSettings) {
      return new Observable<IUserSettings>(subscriber => {
        subscriber.next(this._appState.userSettings);
        subscriber.complete();
      });
    }

    return this._http.get<IUserState>(`api/user/state`).pipe(
      map(res => {
        this._appState.inbox = res.inbox;
        this._appState.userSettings = res.settings;

        let appLanguage: ILanguage;
        const uiLanguages = res.settings.uiLanguages;
        const preferredUiLanguage = res.settings.preferredUiLanguage;
        const backupUiLanguage = res.settings.uiLanguages[0];

        if (
          preferredUiLanguage?.id &&
          uiLanguages.find(x => x.id === preferredUiLanguage.id)
        ) {
          appLanguage = preferredUiLanguage;
        } else {
          appLanguage = backupUiLanguage;
        }

        this._appState.initializeApp(appLanguage);

        return res.settings;
      })
    );
  }
}

@Injectable()
export class GuestSettingsResolve implements Resolve<any> {
  constructor(
    private _appState: AppState,
    private _localeService: LocaleService
  ) { }

  resolve(route: ActivatedRouteSnapshot) {
    this._appState.initializeApp(this._localeService.getGuestLanguage());
  }
}

const AppRoutes: Routes = [
  {
    path: "visitor/:token",
    component: VisitorComponent
  },
  {
    path: "invitation/:token",
    component: ActivateAccountComponent
  },
  {
    path: "tenantInvitation/:token",
    component: AcceptTenantInviteComponent
  },
  // Routes which use the home layout:
  {
    matcher: authRootMatcher,
    component: HomeLayout,
    canActivate: [AuthGuard],
    resolve: {
      userSettings: UserSettingsResolve
    },
    children: [
      { path: "logout", component: LoginComponent },
      { path: "", redirectTo: "/dashboard", pathMatch: "full" },
      {
        path: "dashboard",
        component: DashboardLayout,
        resolve: {
          dashboardData: DashboardResolve
        }
      },
      {
        path: "notifications",
        loadChildren: () =>
          import("./modules/notifications/notifications.module").then(
            (m) => m.NotificationsModule
          )
      },
      {
        path: "market",
        data: { breadcrumb: "navigator.links.market" },
        children: [
          {
            path: "",
            component: MarketLayout,
            resolve: {
              formData: ProductListFormDataResolve,
              initialData: MarketProductListDataResolve
            }
          },
          {
            path: "search/:searchKey",
            resolve: {
              formData: ProductListFormDataResolve,
              filterData: ProductListCachedFilterDataResolve
            },
            children: [
              {
                path: "",
                component: MarketLayout,
                resolve: {
                  formData: ProductListFormDataResolve,
                  initialData: MarketProductListDataResolve
                }
              }
            ]
          },
          {
            path: ":productId",
            component: ProductLayout,
            canDeactivate: [CanDeactivateGuard],
            resolve: {
              productCard: ProductCardResolve
            },
            data: {
              isMarketProduct: true,
              breadcrumb: { alias: "ProductName" }
            }
          }
        ]
      },
      {
        path: "products",
        data: { breadcrumb: "cards.products.products_supplier.title" },
        children: [
          {
            path: "",
            component: ProductsLayout,
            resolve: {
              formData: ProductListFormDataResolve,
              initialData: ProductListDataResolve
            }
          },
          {
            path: "new",
            component: ProductLayout,
            resolve: {
              formData: ProductCardFormDataResolve
            }
          },
          {
            path: "clone/:productId",
            component: ProductLayout,
            resolve: {
              productCard: CloneProductCardResolve
            }
          },
          {
            path: ":productId",
            component: ProductLayout,
            canDeactivate: [CanDeactivateGuard],
            data: { breadcrumb: { alias: "ProductName" } },
            resolve: {
              productCard: ProductCardResolve
            },
            runGuardsAndResolvers: "always"
          }
        ]
      },
      {
        path: "user-settings",
        children: [
          {
            path: "",
            component: UserSettingsLayout,
            resolve: {
              userProfileCard: UserProfileCardResolve
            }
          }
        ]
      },
      {
        path: "lxm-settings",
        component: LxmSettingsLayout,
        children: [
          {
            path: "profile",
            component: CompanyProfileLayout,
            resolve: {
              companyProfileCard: CompanyProfileCardResolve
            }
          },
          {
            path: "packages",
            component: PackageInfoLayout,
            resolve: {
              packageData: PackageDataResolve
            }
          },
          {
            path: "subscription",
            component: SubscriptionLayout,
            resolve: {
              packageData: PackageDataResolve
            }
          },
          {
            path: "users",
            component: UsersLayout,
            resolve: {
              initialData: UsersResolve,
              formData: UsersFormDataResolve
            }
          },
          {
            path: "roles",
            component: RolesLayout,
            resolve: {
              initialData: UserRolesResolve,
              formData: RolesFormDataResolve
            }
          },
          {
            path: "mail",
            component: MailSettingsLayout,
            resolve: {
              mailSettingsCard: MailSettingsCardResolve
            }
          },
          {
            path: "product-fields",
            children: [
              {
                path: "",
                component: ProductFieldsSettingsLayout
              },
              {
                path: "extra-parameters",
                component: ExtraParametersSettingsLayout,
                resolve: {
                  settings: ExtraParametersSettingsCardResolve
                }
              }
            ]
          }
        ]
      },
      {
        path: "alterations",
        loadChildren: () =>
          import("./modules/alterations/alterations.module").then(
            (m) => m.AlterationsModule
          )
      },
      {
        path: "locations",
        loadChildren: () =>
          import("./modules/location/location.module").then(
            (m) => m.LocationModule
          )
      },
      {
        path: "offers/sending/search/:searchKey",
        component: OffersLayout,
        resolve: {
          formData: OffersListFormDataResolve,
          initialData: SendingOffersListDataResolve
        },
        data: {
          offerKind: OfferKind.SupplierOffer
        }
      },
      {
        path: "offers/receiving/search/:searchKey",
        component: OffersLayout,
        resolve: {
          formData: OffersListFormDataResolve,
          initialData: ReceivingOffersListDataResolve
        },
        data: {
          offerKind: OfferKind.ReceivingOffer
        }
      },
      {
        path: "offers/requests/search/:searchKey",
        component: OffersLayout,
        resolve: {
          formData: RequestedOffersListFormDataResolve,
          initialData: RequestedOffersListDataResolve
        },
        data: {
          offerKind: OfferKind.OfferRequest
        }
      },
      {
        path: "offers",
        children: [
          {
            path: "",
            component: OffersRouter
          },
          {
            path: "sending",
            component: OffersLayout,
            resolve: {
              formData: OffersListFormDataResolve,
              initialData: SendingOffersListDataResolve
            },
            data: {
              offerKind: OfferKind.SupplierOffer
            }
          },
          {
            path: "receiving",
            component: OffersLayout,
            resolve: {
              formData: OffersListFormDataResolve,
              initialData: ReceivingOffersListDataResolve
            },
            data: {
              offerKind: OfferKind.ReceivingOffer
            }
          },
          {
            path: "requests",
            component: OffersLayout,
            resolve: {
              formData: RequestedOffersListFormDataResolve,
              initialData: RequestedOffersListDataResolve
            },
            data: {
              offerKind: OfferKind.OfferRequest
            }
          },
          {
            path: "new",
            canDeactivate: [CanDeactivateGuard],
            resolve: {
              formData: OfferCardFormDataResolve
            },
            data: {
              offerKind: OfferKind.SupplierOffer
            },
            children: [
              {
                path: "",
                component: OfferLayoutComponent
              },
              {
                path: ":clientId",
                component: OfferLayoutComponent
              }
            ]
          },
          {
            path: "request",
            canDeactivate: [CanDeactivateGuard],
            resolve: {
              formData: OfferCardFormDataResolve
            },
            data: {
              offerKind: OfferKind.OfferRequest
            },
            children: [
              {
                path: "",
                component: OfferLayoutComponent
              },
              {
                path: ":clientId",
                component: OfferLayoutComponent
              }
            ]
          },
          {
            path: "clone/:offerId",
            component: OfferLayoutComponent,
            canDeactivate: [CanDeactivateGuard],
            resolve: {
              offerCard: CloneOfferCardResolve
            }
          },
          {
            path: "createFromCalendarEntry/:calendarEntryId",
            component: OfferLayoutComponent,
            canDeactivate: [CanDeactivateGuard],
            resolve: {
              offerCard: OfferCardFromCalendarEntryResolve
            },
            data: {
              fromCalendarEntry: true
            }
          },
          {
            path: ":offerId",
            component: OfferLayoutComponent,
            canDeactivate: [CanDeactivateGuard],
            resolve: {
              offerCard: OfferCardResolve
            },
            runGuardsAndResolvers: "always"
          },
          {
            path: ":offerId/:token",
            component: OfferLayoutComponent,
            canDeactivate: [CanDeactivateGuard],
            resolve: {
              offerCard: OfferCardResolve
            },
            runGuardsAndResolvers: "always"
          }
        ]
      },
      {
        path: "calendar",
        loadChildren: () =>
          import("./modules/calendar/calendar.module").then(
            (m) => m.CalendarModule
          )
      },
      { path: "reports", component: ReportsLayout },
      {
        path: "import",
        loadChildren: () =>
          import("./modules/imports/imports.module").then(
            (m) => m.ImportsModule
          )
      },
      {
        path: "clients",
        loadChildren: () =>
          import("./modules/clients/clients.module").then(
            (m) => m.ClientsModule
          )
      },
      {
        path: "workspace",
        loadChildren: () =>
          import(
            "./modules/purchase-manager-desktop/purchase-manger-desktop.module"
          ).then((m) => m.PurchaseManagerDesktopModule)
      },
      {
        path: "suppliers",
        loadChildren: () =>
          import("./modules/suppliers/suppliers.module").then(
            (m) => m.SuppliersModule
          )
      },
      {
        path: "orders",
        loadChildren: () =>
          import("./modules/orders/orders.module").then((m) => m.OrdersModule)
      },
      {
        path: "store",
        loadChildren: () =>
          import("./modules/store/store.module").then((m) => m.StoreModule)
      },
      {
        path: "settings",
        loadChildren: () => import("./modules/settings/settings.module").then(m => m.SettingsModule)
      },
      {
        path: "**",
        component: NotFoundComponent
      }
    ]
  },

  // Routes which use the guest layout:
  {
    path: "",
    component: GuestLayout,
    resolve: {
      _: GuestSettingsResolve
    },
    children: [
      {
        path: "",
        redirectTo: "/login",
        pathMatch: "full"
      },
      {
        path: "login",
        children: [
          {
            path: "",
            component: LoginComponent
          },
                    {
            path: "selver",
            component: LoginComponent,
            data: {
              customFor: "selver"
            }
          },
          {
            path: "idcard/:sessionId",
            component: LoginComponent
          },
          {
            path: "azureAd/:sessionId",
            component: LoginComponent
          }
        ]
      },
      {
        path: "selver",
        redirectTo: "/login/selver"
      },
      {
        path: "activate-account",
        children: [
          {
            path: "",
            component: ActivateAccountCardComponent,
            resolve: {
              userProfileCard: ActivateAccountCardResolve
            }
          }
        ]
      },
      { path: "logout", component: LoginComponent },
      {
        path: "register",
        loadChildren: () =>
          import("./modules/register-account/register-account.module").then(
            (m) => m.RegisterModule
          )
      },
      {
        path: "resetPassword/:userId/:token",
        component: ResetPasswordComponent
      },
      {
        path: "**",
        component: NotFoundComponent
      }
    ]
  }
];

export const appRouting = RouterModule.forRoot(AppRoutes, {
  onSameUrlNavigation: "reload",
  // paramsInheritanceStrategy: "always"
  relativeLinkResolution: "legacy"
});
