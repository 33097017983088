<card-expanding [formGroup]="form" [valid]="cardValidType" [expanded]="isExpanded" (onExpand)="saveLocalExpandedValue($event)">

  <span header-title>{{ title | translate}}</span>

  <div header-actions *requiredRights="[UserAction.ManageProducts]">
    <ng-container *ngIf="isOwnProduct">
      <ng-container *ngIf="!locked">
        <lxm-button *ngIf="!edit" type="beige" icon="edit" title="action.edit" (click)="toggleEdit()"></lxm-button>
        <lxm-button *ngIf="edit" type="grey" icon="back" label="action.cancel" (click)="toggleEdit()">
        </lxm-button>
        <lxm-button *ngIf="edit" type="beige" icon="save" label="action.save" [loading]="saveLoading"
          (click)="save()" buttonType="submit"></lxm-button>
      </ng-container>
      <ng-container *ngIf="locked">
        {{ 'global.locked_by' | translate }}: {{ lockedBy.name }} {{ lockedAt | amTimeAgo }}
      </ng-container>
    </ng-container>
  </div>

  <div body>

    <div class="grid grid-cols-1 xl:grid-cols-2 w-full gap-4">
      <div class="flex flex-col">

        <form-field [readonly]="!edit"
          label="cards.products.product_game_info.label.game_format" description="cards.products.product_game_info.label_description.game_format"
          for="gameFormatId"
          [required]="productValidity.shouldValidate ? productValidity.isRequiredByRetailer(cardSection, productField.GameFormat, form.get('gameFormatId').value) : null">
          <lxm-select select
            [readonly]="!edit"
            [options]="gameFormatOptions"
            for="gameFormatId"
            value="id"
            labelField="translatedValue"
            [isTranslated]="true"
            [sortByLabel]="true">
            </lxm-select>
          <validation-errors for="gameFormatId"></validation-errors>
        </form-field>


        <form-field [readonly]="!edit" for="age"
            label="cards.products.product_game_info.label.age"
            description="cards.products.product_game_info.label_description.age"
            type="input-text"
            [required]="productValidity.shouldValidate ? productValidity.isRequiredByRetailer(cardSection, productField.GameAge, form.get('age').value) : null">
            <validation-errors for="age"></validation-errors>
        </form-field>

        <form-field [readonly]="!edit" for="players"
            label="cards.products.product_game_info.label.players"
            description="cards.products.product_game_info.label_description.players"
            type="input-text"
            [required]="productValidity.shouldValidate ? productValidity.isRequiredByRetailer(cardSection, productField.GamePlayers, form.get('players').value) : null">
            <validation-errors for="players"></validation-errors>
        </form-field>

        <form-field [readonly]="!edit" for="duration"
            label="cards.products.product_game_info.label.duration"
            description="cards.products.product_game_info.label_description.duration"
            type="input-text"
            [required]="productValidity.shouldValidate ? productValidity.isRequiredByRetailer(cardSection, productField.GameDuration, form.get('duration').value) : null">
            <validation-errors for="duration"></validation-errors>
        </form-field>

        <form-field [readonly]="!edit"
          label="cards.products.product_game_info.label.genres"
          description="cards.products.product_game_info.label_description.genres"
          for="genres"
          [required]="productValidity.shouldValidate ? productValidity.isRequiredByRetailer(cardSection, productField.GameGenres, form.get('genres').value) : null">
          <lxm-select select
            [readonly]="!edit"
            [options]="gameGenreOptions"
            for="genres"
            value="id"
            labelField="translatedValue"
            maxSelectedItems="4"
            [multiple]="true"
            [searchable]="true"
            [isTranslated]="true"
            [sortByLabel]="true">
          </lxm-select>
          <validation-errors for="genres"></validation-errors>
        </form-field>

      </div>

      <div class="flex flex-col">

        <form-field [readonly]="!edit" for="releaseYear"
          label="cards.products.product_game_info.label.release_year"
          description="cards.products.product_game_info.label_description.release_year"
          type="input-text"
          [required]="productValidity.shouldValidate ? productValidity.isRequiredByRetailer(cardSection, productField.GameReleaseYear, form.get('releaseYear').value) : null">
          <validation-errors for="releaseYear"></validation-errors>
        </form-field>

        <form-field [readonly]="!edit" for="publisher"
            label="cards.products.product_game_info.label.publisher"
            description="cards.products.product_game_info.label_description.publisher"
            type="input-text"
            [required]="productValidity.shouldValidate ? productValidity.isRequiredByRetailer(cardSection, productField.GamePublisher, form.get('publisher').value) : null">
            <validation-errors for="publisher"></validation-errors>
        </form-field>

        <form-field [readonly]="!edit"
          label="cards.products.product_game_info.label.content_ratings"
          description="cards.products.product_game_info.label_description.content_ratings"
          for="contentRatings"
          [required]="productValidity.shouldValidate ? productValidity.isRequiredByRetailer(cardSection, productField.GameContentRatings, form.get('contentRatings').value) : null">
          <lxm-select select
            [readonly]="!edit"
            [options]="gameContentRatingOptions"
            [isTranslated]="true"
            value="id"
            labelField="translatedValue"
            [sortByLabel]="true"
            [searchable]="true"
            [multiple]="true"
            for="contentRatings">
          </lxm-select>
          <validation-errors for="contentRatings"></validation-errors>
        </form-field>

        <form-field [readonly]="!edit"
          label="cards.products.product_game_info.label.pegi_rating"
          description="cards.products.product_game_info.label_description.pegi_rating"
          for="pegiRatingId"
          [required]="productValidity.shouldValidate ? productValidity.isRequiredByRetailer(cardSection, productField.GamePegiRating, form.get('pegiRatingId').value) : null">
          <lxm-select select
            [readonly]="!edit"
            [options]="gamePegiRatingOptions"
            for="pegiRatingId"
            value="id"
            labelField="translatedValue"
            [isTranslated]="true">
            </lxm-select>
          <validation-errors for="pegiRatingId"></validation-errors>
        </form-field>

        <form-field [readonly]="!edit" label="cards.products.product_game_info.label.isCe">
          <slide-toggle toggle for="isCe" [readonly]="!edit"></slide-toggle>
        </form-field>

        <form-field [readonly]="!edit" label="cards.products.product_game_info.label.isInfant">
          <slide-toggle toggle for="isInfant" [readonly]="!edit"></slide-toggle>
        </form-field>

      </div>
    </div>

  </div>

  <div footer class="flex flex-row" *ngIf="edit">
    <lxm-button class="ml15" type="grey" icon="back" label="action.cancel" (click)="toggleEdit()"></lxm-button>
    <lxm-button class="ml15" type="beige" icon="save" label="action.save" [loading]="saveLoading" (click)="save()">
    </lxm-button>
  </div>

</card-expanding>
