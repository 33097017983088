<lxm-dialog [dialogRef]="dialogRef" [formGroup]="form" #dialog>
  <span header-title
    >{{(editable ? (schemeId ?
    'cards.products.product_pricelist_base_edit.title_edit' :
    'cards.products.product_pricelist_base_edit.title_new' ) :
    'cards.products.product_pricelist_base_edit.title') | translate}}
  </span>
  <div body style="padding-bottom: 40px">
    <scope translationsPath="cards.products.product_pricelist_base_edit">
      <form-field
        dialog
        label="country"
        for="country"
        description="country"
        [required]="true"
      >
        <lxm-select
          select
          class="dialog-input-fixed-width-3"
          [options]="countryOptions"
          [isTranslated]="true"
          [clearable]="false"
          labelField="translatedName"
          [sortByLabel]="true"
          [searchable]="true"
          compareField="id"
          for="country"
          labelIconField="iso3"
          labelIconFallback="global"
          labelIconClass="flag-icon"
        >
        </lxm-select>
        <validation-errors
          for="countryId"
          aliasTo="country"
        ></validation-errors>
      </form-field>

      <form-field
        dialog
        label="valid"
        description="valid"
        for="validFrom"
        [required]="true"
      >
        <lxm-datepicker
          combined
          class="dialog-input-fixed-width-1"
          for="validFrom"
          [min]="nextDay"
        ></lxm-datepicker>
        <validation-errors for="validFrom"></validation-errors>
      </form-field>

      <div class="separator"></div>

      <section-expanding [expanded]="buyInSectionExpanded" speed="0">
        <ng-container header-title>
          {{'cards.products.product_pricelist_base_edit.buy_in_price_section_title'
          | translate}}
        </ng-container>

        <div class="flex flex-col" body>
          <form-field
            dialog
            label="manufacturer"
            for="manufacturer"
            description="manufacturer"
          >
            <lxm-select
              select
              class="dialog-input-fixed-width-3"
              labelField="value"
              [searchable]="true"
              for="manufacturer"
              [options]="manufacturersOptions"
            >
            </lxm-select>
            <validation-errors for="manufacturer"></validation-errors>
            <validation-errors for="manufacturerId"></validation-errors>
          </form-field>

          <form-field
            dialog
            for="manufacturerSalesPrice"
            label="manufacturer_sales_price"
            description="manufacturer_sales_price"
          >
            <lxm-input
              input
              type="price"
              for="manufacturerSalesPrice"
              class="dialog-input-fixed-width-1"
            >
              <validation-errors
                for="manufacturerSalesPrice"
              ></validation-errors>
            </lxm-input>
          </form-field>

          <form-field
            dialog
            for="discount"
            label="discount"
            description="discount"
          >
            <lxm-input
              input
              for="discount"
              class="dialog-input-fixed-width-1"
              type="percent"
              [digits]="2"
            >
              <validation-errors for="discount"></validation-errors>
            </lxm-input>
          </form-field>

          <form-field
            dialog
            for="buyInPrice"
            label="buy_in_price"
            description="buy_in_price"
          >
            <lxm-input
              input
              for="buyInPrice"
              type="price"
              class="dialog-input-fixed-width-1"
            >
              <validation-errors for="buyInPrice"></validation-errors>
            </lxm-input>
          </form-field>
        </div>
      </section-expanding>

      <div class="separator"></div>

      <form-field
        dialog
        label="logistics_partner"
        for="logisticsPartner"
        description="logistics_partner"
      >
        <lxm-select
          select
          class="dialog-input-fixed-width-3"
          labelField="value"
          [searchable]="true"
          for="logisticsPartner"
          [options]="logisticsPartnersOptions"
        >
          <lxm-button
            *requiredRights="[UserAction.ManageClvs]"
            header
            label="action.add_new"
            icon="plus-dark"
            (click)="openClassificatorValueDialog(ClvType.LogisticsPartner, form.get('logisticsPartner'), 'logisticsPartnersOptions')"
          >
          </lxm-button>
        </lxm-select>
        <validation-errors for="logisticsPartner"></validation-errors>
        <validation-errors for="logisticsPartnerId"></validation-errors>
      </form-field>

      <form-field
        dialog
        label="public_sales_price"
        for="publicSalesPrices"
        description="public_sales_price"
      >
        <currency-price
          class="dialog-input-fixed-width-1"
          any
          for="publicSalesPrices"
          [currencyOptions]="currencies"
          digits="3"
        ></currency-price>
        <validation-errors for="publicSalesPrices"></validation-errors>
      </form-field>

      <form-field
        dialog
        label="vat"
        description="vat"
        for="vat"
        [required]="true"
      >
        <lxm-select
          select
          class="dialog-input-fixed-width-3"
          labelField="value"
          [options]="vats"
          [searchable]="true"
          for="vat"
        >
        </lxm-select>
        <validation-errors for="vatId" aliasTo="vat"></validation-errors>
      </form-field>
    </scope>
  </div>

  <div footer class="justify-between">
    <span class="flex flex-row">
      <lxm-button
        type="orange"
        label="action.save"
        [loading]="saveLoading"
        (click)="save()"
        buttonType="submit"
      ></lxm-button>
      <lxm-button
        type="grey"
        label="action.cancel"
        class="ml10"
        (click)="closeDialog()"
      ></lxm-button>
    </span>

    <span class="flex flex-row">
      <lxm-button
        *ngIf="data.scheme"
        type="transparent"
        label="action.delete"
        icon="trash"
        class="ml30"
        (click)="deleteScheme()"
      ></lxm-button>
    </span>
  </div>
</lxm-dialog>
