<card-expanding [formGroup]="form">
  <span header-title>{{ 'cards.clients.offer_settings.title' | translate}}</span>
  <div header-actions>
    <ng-container [ngSwitch]="edit">
      <ng-container *ngSwitchCase="false">
        <lxm-button type="beige" icon="edit" title="action.edit" (click)="toggleEdit()"
          *ngIf="canEdit"></lxm-button>
      </ng-container>
      <ng-container *ngSwitchCase="true">
        <lxm-button type="grey" icon="back" label="action.cancel" (click)="toggleEdit()"></lxm-button>
        <lxm-button type="beige" icon="save" label="action.save" [loading]="saveLoading" (click)="save()"
          buttonType="submit"></lxm-button>
      </ng-container>
    </ng-container>

  </div>

  <div body>

    <div class="row">
      <div class="col-12 no-padding">

        <form-field label="cards.clients.offer_settings.label.client_tag"
          description="cards.clients.offer_settings.label_description.client_tag">
          <lxm-select select [readonly]="!edit" [options]="clientTagOptions" [classFn]="getItemClass" for="clientTags"
            value="id" labelField="value" [sortByLabel]="true" [multiple]="true" [searchable]="true"></lxm-select>
          <validation-errors for="clientTags"></validation-errors>
        </form-field>

        <form-field label="cards.clients.offer_settings.label.offer_language" *ngIf="isClient" [required]="edit"
          description="cards.clients.offer_settings.label_description.offer_language">
          <lxm-select select [readonly]="!edit" [options]="languages" for="offerLanguage" value="value"
            [clearable]="false"></lxm-select>
          <validation-errors for="defaultLanguage" aliasTo="offerLanguage"></validation-errors>
        </form-field>

        <form-field label="cards.clients.offer_settings.label.offer_type" *ngIf="isClient" [required]="edit"
          description="cards.clients.offer_settings.label_description.offer_type">
          <lxm-select select [readonly]="!edit" [options]="offerExportTypes" for="offerExportType" value="id"
            [clearable]="false"></lxm-select>
          <validation-errors for="offerExportType"></validation-errors>
        </form-field>

        <form-field label="cards.clients.offer_settings.label.currency" [required]="edit">
          <lxm-select select [readonly]="currencyOptions.length <= 1 || !edit" [options]="currencyOptions"
            for="currencyId" value="id" labelField="value" [sortByLabel]="true" [clearable]="false"></lxm-select>
          <validation-errors for="currencyId"></validation-errors>
        </form-field>

        <!-- <form-field label="cards.clients.offer_settings.label.delivery_method" >
          <lxm-select select [readonly]="!edit" [options]="deliveryMethodOptions" for="deliveryMethod" 
            value="id" labelField="translatedValue" [isTranslated]="true"></lxm-select>
          <validation-errors for="deliveryMethod"></validation-errors>
        </form-field> -->

        <form-field [readonly]="!edit" label="cards.clients.offer_settings.label.payment_deadline_days"
          for="paymentDeadlineInDays" type="input-text">
          <validation-errors for="paymentDeadlineInDays"></validation-errors>
        </form-field>

        <form-field label="cards.clients.offer_settings.label.fine_for_delay">
          <combined-input any for="fineForDelay" [readonly]="!edit" inputType="text">
            <span suffix>%</span>
            <validation-errors for="fineForDelay"></validation-errors>
          </combined-input>
        </form-field>

      </div>
    </div>
  </div>

</card-expanding>