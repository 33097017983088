import { Component, Injector, Input } from '@angular/core';
import { LxmDialog, LxmMessage } from 'src/app/_helpers';
import { ProductImageDialogComponent } from './product-image.dialog';
import { ActivatedRoute } from '@angular/router';
import { IProductCard, IProductImage } from '../product-info/product-info.model';
import { ProductService, ProductValidity } from 'src/app/_services';
import { UserAction, ProductCardSection } from 'src/app/enum';
import { appSettings } from 'src/app/app.settings';
import { TranslateService } from '@ngx-translate/core';
import { ProductCardBase } from '../product-card-base';
import { FormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { LOCAL_STORAGE_KEY } from 'src/app/config';
import { ProductHeaderState } from '../product-header/product-header.state';

@Component({
  selector: "product-images-card",
  templateUrl: './product-images.card.html',
  styleUrls: ['./product-images.card.scss']
})
export class ProductImagesCard extends ProductCardBase<IProductImage[]> {

  protected get successMessage(): string {
    return null;
  }
  
  public get title(): any {
    return "cards.products.product_images.title";
  }
  
  public UserAction = UserAction;

  public cardSection = ProductCardSection.ProductImages;

  @Input()
  public productId: string;

  public expanded = true;
  public cardValid = true;
  public images: IProductImage[];
  public isOwnProduct: boolean;

  public isExpandedKey = LOCAL_STORAGE_KEY.PRODUCT_IMAGES_CARD_OPEN;

  constructor(
    private _productHeaderState: ProductHeaderState,
    injector: Injector,
    private _dialog: LxmDialog,
    private _msg: LxmMessage,
    private route: ActivatedRoute,
    public productValidity: ProductValidity,
    private _translateService: TranslateService,
  ) {
    super(injector, 'productImages', 'productImagesChanged');
    const productCard = route.snapshot.data.productCard as IProductCard;
    this.isOwnProduct = productCard.isOwnProduct;
    this.images = productCard.images?.sort((a, b) => (a.isMaster ? -1 : 1)) ?? [];
  }

  public imageComparer(index, item) {
    return item?.id;
  }

  public openDialog(image: IProductImage) {

    this._dialog.open(ProductImageDialogComponent, {
      ...appSettings.DIALOG_SIZES.M,
      data: {
        imagesCard: this,
        productId: this.productId,
        image: image
      }
    }, (images) => {
      if (images) {
        this.images = images.sort((a, b) => (a.isMaster ? -1 : 1));
        this._productHeaderState.setData({
          images: this.images
        })
      }
    });
  }

  public deleteImage(id: string, i: number) {

    this._dialog.confirm({
      image: 'delete-modal-image',
      template: `
      <p class="dialog-title">${this._translateService.instant('cards.products.product_images.remove_image_title')}</p>
      <p>
        ${this._translateService.instant('cards.products.product_images.remove_image_confirm')}
      </p>
      `
    }, () => {
      this._productService.deleteImage(this.productId, id)
        .result(null, (res) => {
          this.images.splice(i, 1);
          this._msg.ok({
            message: 'cards.products.product_images.image_removed'
          });

          if (res.newMasterId) {
            const newMaster = this.images.find(x => x.id === res.newMasterId);
            if (newMaster) {
              newMaster.isMaster = true;
              newMaster.isForRetailChain = true;
            }
          }
        });
    });
  }

}

