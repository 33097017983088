<card [breadcrumbs]="true">
  <div header-title>
    {{data.fullName | translatedValue:true }}
  </div>
  <div header-actions>
    <ng-container *ngIf="isMarketProduct">
      <div class="market-product-indicator">
        <svg-icon key="productsGlobalActive" class="mr8" fontSize="20px"></svg-icon>
        <span class="indicator-name">
          {{'global.market_product' | translate}}
        </span>
      </div>
    </ng-container>
    <ng-container *requiredModules="['SellerPimExtensions']">
      <lxm-button
        type="green"
        icon="checkmark"
        label="cards.products.product_base_info.action.validate_product"
        (click)="openValidationDialog()">
      </lxm-button>
    </ng-container>
    <dot-menu *ngIf="show.dotMenu">
      <ul>
        <li *ngIf="show.addButton" routerLink="/products/new">
          <lxm-button
            icon="plus-dark"
            label="action.add_product">
          </lxm-button>
        </li>
        <li *ngIf="show.pdfButton" (click)="openExportProductsDialog()">
            <lxm-button
              icon="pdf"
              label="action.download_pdf">
          </lxm-button>
        </li>
        <li *ngIf="show.cloneButton" (click)="clone()">
          <lxm-button
              icon="copy"
              label="action.clone">
          </lxm-button>
        </li>
        <li *ngIf="show.sendToDirectoButton" (click)="sendToDirecto()">
          <lxm-button
              [disabled]="sendToDirectoButtonDisabled"
              icon="send"
              label="action.sendToDirecto">
          </lxm-button>
        </li>
        <li *ngIf="show.getImageFromApollo" (click)="getImageFromApollo()">
          <lxm-button
              icon="copy"
              label="Get image from Apollo">
          </lxm-button>
        </li>
        <li *ngIf="show.sendToDirectoButton" (click)="getProductInfoFromApollo()">
          <lxm-button
              icon="info"
              label="Get product info from Apollo">
          </lxm-button>
        </li>
        <li *ngIf="show.deleteButton && !isProductActive" (click)="delete()">
            <lxm-button
              [disabled]="isProductActive"
              icon="trash"
              label="action.delete"
              title="action.delete">
            </lxm-button>
        </li>
      </ul>
    </dot-menu>
  </div>
  <div body>
    <div class="row mb10">
      <div class="flex flex-col f-1">
          <div class="row nowrap">

              <div class="flex">
                  <image-box 
                      class="main-image" 
                      [images]="imagesList"
                      [index]="0"
                      imageFit="contain" 
                      size="3">
                  </image-box>
              </div>

              <div class="flex flex-col f-1 ml20">
                <div class="row nowrap mb10 gap-4" [ngSwitch]="showRetailerAssortmentStatus">
                  <div class="col-12">
                    <div class="flex flex-row align-center" *ngSwitchCase="true">
                      <status-circle [status]="retailerAssortmentStatus ? ('color-' + retailerAssortmentStatus?.color) : null" class="mr5"></status-circle>
                      <span class="dirty-purple">{{retailerAssortmentStatus?.value ?? ('global.without_assortment_schema' | translate)}}</span>
                    </div>
                    <div class="flex flex-row align-center" *ngSwitchCase="false">
                      <status-circle class="mr5" [status]="supplierAssortmentStatus" enum="SupplierProductAssortmentStatus"></status-circle>
                      <span class="dirty-purple">
                        {{supplierAssortmentStatus | translateEnum: 'SupplierProductAssortmentStatus'}}
                      </span>
                    </div>
                  </div>
                </div>

                <div class="row nowrap mb10 gap-4">
                  <div class="col-6" *ngIf="data.brand?.value">
                    <span class="dirty-purple" style="  font-family: RedHatDisplay;
                    font-size: 18px;
                    font-weight: bold;
                    letter-spacing: -0.3px;
                    color: var(--dirty-purple);">
                      {{data.brand?.value}}
                    </span>
                  </div>
                  <div class="col-6">
                    <ng-container *ngIf="data.countryOfOrigin?.translatedName">
                      <span class="flex align-center product-header-text">
                      <svg-icon class="flag-icon mr10" [key]="getCountryIcon(data.countryOfOrigin)" fontSize="16px"></svg-icon>
                        {{data.countryOfOrigin?.translatedName | translatedValue}}
                      </span>
                    </ng-container>
                  </div>

                </div>
                <div class="row nowrap gap-4">
                  <div class="col-6">
                    <span class="product-header-label">{{'table.barcode' | translate}}</span>
                    <span class="product-header-text">{{data.ean13?.value ?? data.weighingGoodsCode?.value ?? '-'}}</span>
                  </div>
                  <div class="col-6">
                    <ng-container [ngSwitch]="hasBuyerPimExtensions">
                      <div class="flex flex-col" *ngSwitchDefault>
                        <span class="product-header-label">{{'enums.ProductUnitCodeType.SupplierCode' | translate}}</span>
                        <span class="product-header-text">{{data.supplierCode?.value || '-'}}</span>
                      </div>
                      <div class="flex flex-col" *ngSwitchCase="true">
                        <span class="product-header-label">{{'enums.ProductUnitCodeType.InternalCode' | translate}}</span>
                        <span class="product-header-text">{{data.internalCode?.value || '-'}}</span>
                      </div>
                    </ng-container>
                  </div>
                </div>
                <div class="row align-center pictograms-row">
                  <div class="col-12">
                    <div class="flex flex-row flex-wrap w-full">
                      <svg-icon 
                        *ngFor="let pictogram of pictograms" 
                        [key]="pictogram.pictogram" 
                        [title]="pictogram.description | translate" 
                        class="mr5 mt5" 
                        size="28px">
                      </svg-icon>
                    </div>
                  </div>
                </div>
              </div>

              <div class="flex flex-col f-1 ml20 justify-between">
                <!-- <div class="row mb10">
                  <div class="flex flex-row align-center" [ngSwitch]="showRetailerAssortmentStatus">
                    <ng-container *ngSwitchCase="true">
                      <status-circle [status]="retailerAssortmentStatus ? ('color-' + retailerAssortmentStatus?.color) : null" class="mr5"></status-circle>
                      <span class="dirty-purple">{{retailerAssortmentStatus?.value ?? ('global.without_assortment_schema' | translate)}}</span>
                    </ng-container>
                    <ng-container *ngSwitchCase="false">
                      <status-circle class="mr5" [status]="supplierAssortmentStatus" enum="SupplierProductAssortmentStatus"></status-circle>
                      <span class="dirty-purple">
                        {{supplierAssortmentStatus | translateEnum: 'SupplierProductAssortmentStatus'}}
                      </span>
                    </ng-container>
                  </div>
                </div> -->
                <!-- <div class="row align-center mb10" *ngIf="data.brand?.value">
                  <div class="col-3">
                    <span class="dirty-purple" style="font-weight: 500;">
                      {{data.brand?.value}}
                    </span>
                  </div>
                  <div class="col-9">
                    <span class="dirty-purple" *ngIf="data.countryOfOrigin?.translatedName">
                      {{data.countryOfOrigin?.translatedName | translatedValue}}
                    </span>
                  </div>
                </div> -->
                <!-- <div class="row align-center mb10">
                  <div class="col-3">
                      <span class="product-header-label">EAN-13</span>
                      <span class="dirty-purple">{{data.ean13?.value || '-'}}</span>
                  </div>
                  <div class="col-9">
                    <ng-container [ngSwitch]="hasBuyerPimExtensions">
                      <div class="flex flex-col" *ngSwitchDefault>
                        <span class="product-header-label">{{'enums.ProductUnitCodeType.SupplierCode' | translate}}</span>
                        <span class="dirty-purple">{{data.supplierCode?.value || '-'}}</span>
                      </div>
                      <div class="flex flex-col" *ngSwitchCase="true">
                        <span class="product-header-label">{{'enums.ProductUnitCodeType.InternalCode' | translate}}</span>
                        <span class="dirty-purple">{{data.internalCode?.value || '-'}}</span>
                      </div>
                    </ng-container>
                  </div>
                </div> -->
                <!-- <div class="row align-center">
                  <div class="flex flex-row">
                      <svg-icon *ngFor="let pictogram of pictograms" [key]="pictogram.pictogram" [title]="pictogram.description | translate" class="mr5" size="28px"></svg-icon>
                  </div>
                </div> -->
              </div>

          </div>
      </div>

    </div>
  </div>

</card>