<form class="search-container flex flex-col" [formGroup]="formGroup" (ngSubmit)="onSearch()" [class.focused]="isInputFocused">
  <div class="flex flex-row z-[206]">
    <div class="search-input-container">
      <input #searchInput formControlName="searchTerm" class="search-input flex f-1" (click)="$event.stopImmediatePropagation()"
        [placeholder]="placeholder | translate"/>
      <div class="flex f-0 items-center">
        <lxm-button *ngIf="showPanel"
          type="transparent" 
          [icon]="isOpen ? 'filter-button-active' : 'filter-button'" 
          style="height: 100%; min-height: 100%;"
          padding="2px 6px" 
          [label]="isOpen ? 'action.hide_filter' : 'action.open_filter'"
          iconPosition="right"
          [active]="isOpen"
          (click)="togglePanel()">
        </lxm-button>



        <span class="active-filters-count generic-hover" *ngIf="filtersCount" (click)="togglePanel()">
          {{filtersCount}}
        </span>

        <!-- <lxm-button *ngIf="showBookmarks"
          type="transparent" 
          class="coming-soon" 
          icon="saved-button"
          style="height: 100%; min-height: 100%;"
          padding="2px 6px">
        </lxm-button> -->
      </div>
    </div>
    <lxm-button class="flex" type="primary" buttonType="submit" label="cards.products.products_retailer_search.search" [loading]="isLoading"
      minWidth="156px" icon="search-button" iconPosition="right">
    </lxm-button>
  </div>


  <div class="search-panel" *ngIf="isOpen">
    <div class="mt10 grid grid-cols-1 xl:grid-cols-2 gap-4">
      <div class="panel-left">
        <ng-content select="[panel-left]"></ng-content>
      </div>
      <div class="panel-right">
        <ng-content select="[panel-right]"></ng-content>
      </div>
    </div>
    <div class="search-panel-footer row mt18">
      <ng-content select="[panel-footer]"></ng-content>
      <lxm-button *ngIf="showSaveButton" type="beige" class="ml15" icon="save"
        label="cards.products.products_retailer_search.save_search">
      </lxm-button>
      <lxm-button type="grey" class="ml15" icon="cancel" label="cards.products.products_retailer_search.cancel_search"
        (click)="resetFields()">
      </lxm-button>
      <lxm-button type="grey" class="ml15" icon="close" label="cards.products.products_retailer_search.close"
        (click)="togglePanel()">
      </lxm-button>
    </div>
  </div>
</form>