<card-expanding [formGroup]="form" [valid]="productValidity.getCardStatusValidType(cardSection)" [expanded]="isExpanded"
  (onExpand)="saveLocalExpandedValue($event)">

  <span header-title>{{ title | translate}}</span>

  <div header-actions *requiredRights="[UserAction.ManageProducts]">
    <ng-container *ngIf="isOwnProduct">
      <ng-container *ngIf="!locked">
        <lxm-button *ngIf="!edit" type="beige" icon="edit" title="action.edit"
          (click)="toggleEdit()"></lxm-button>
        <lxm-button *ngIf="edit" type="grey" icon="back" label="action.cancel" (click)="toggleEdit()">
        </lxm-button>
        <lxm-button *ngIf="edit" type="beige" icon="save" label="action.save" [loading]="saveLoading"
          (click)="save()" buttonType="submit"></lxm-button>
      </ng-container>
      <ng-container *ngIf="locked">
        {{ 'global.locked_by' | translate }}: {{ lockedBy.name }} {{ lockedAt | amTimeAgo }}
      </ng-container>
    </ng-container>
  </div>

  <div body>

    <div class="grid grid-cols-1 xl:grid-cols-2 w-full gap-4">
      <div class="flex flex-col">

        <form-field [readonly]="!edit" label="cards.products.product_alcohol.label.alcohol_type"
          description="cards.products.product_alcohol.label_description.alcohol_type" for="alcoholTypeId"
          [required]="productValidity.shouldValidate ? productValidity.isRequiredByRetailer(cardSection, productField.TypeOfAlcohol, form.get('alcoholTypeId').value) : null">
          <lxm-select select for="alcoholTypeId" class="w100p" [options]="alcoholTypesOptions" [searchable]="true"
            value="id" labelField="translatedValue" [isTranslated]="true" [sortByLabel]="true" [readonly]="!edit">
          </lxm-select>
          <validation-errors for="alcoholTypeId"></validation-errors>
        </form-field>

        <form-field for="alcoholContent" [readonly]="!edit" label="cards.products.product_alcohol.label.alcohol_content"
          description="cards.products.product_alcohol.label_description.alcohol_content" type="input-text"
          [required]="productValidity.shouldValidate ? productValidity.isRequiredByRetailer(cardSection, productField.AlcoholContent, form.get('alcoholContent').value) : null">
          <validation-errors for="alcoholContent"></validation-errors>
        </form-field>

        <form-field for="licenseNumber" [readonly]="!edit"
          label="cards.products.product_alcohol.label.alcohol_licence_number"
          description="cards.products.product_alcohol.label_description.alcohol_licence_number" type="input-text"
          [required]="productValidity.shouldValidate ? productValidity.isRequiredByRetailer(cardSection, productField.AlcoholLicenseNumber, form.get('licenseNumber').value) : null">
          <validation-errors for="licenseNumber"></validation-errors>
        </form-field>

        <form-field [readonly]="!edit" label="cards.products.product_alcohol.label.alcohol_licence_date"
          description="cards.products.product_alcohol.label_description.alcohol_licence_date" for="licenseDate"
          [required]="productValidity.shouldValidate ? productValidity.isRequiredByRetailer(cardSection, productField.AlcoholLicenseDate, form.get('licenseDate').value) : null">
          <lxm-datepicker any for="licenseDate" class="w-full" [readonly]="!edit"></lxm-datepicker>
          <validation-errors for="licenseDate"></validation-errors>
        </form-field>

        <form-field [readonly]="!edit" label="cards.products.product_alcohol.label.alcohol_origin"
          description="cards.products.product_alcohol.label_description.alcohol_origin" for="countryOfOrigin"
          [required]="productValidity.shouldValidate ? productValidity.isRequiredByRetailer(cardSection, productField.AlcoholCountryOfOrigin, form.get('countryOfOrigin').value) : null">
          <lxm-select select [readonly]="!edit" [options]="countryOptions" [isTranslated]="true"
            labelField="translatedName" [sortByLabel]="true" [searchable]="true" labelIconField="iso3"
            labelIconFallback="global" labelIconClass="flag-icon" for="countryOfOrigin">
          </lxm-select>
          <validation-errors for="countryOfOrigin"></validation-errors>
        </form-field>

        <form-field [readonly]="!edit" label="cards.products.product_alcohol.label.wine_winery"
          description="cards.products.product_alcohol.label_description.wine_winery" for="winery"
          [required]="productValidity.shouldValidate ? productValidity.isRequiredByRetailer(cardSection, productField.Winery, form.get('winery').value) : null">
          <lxm-select select [readonly]="!edit" labelField="value" for="winery" [options]="wineryOptions">
            <lxm-button *requiredRights="[UserAction.ManageClvs]" header label="action.add_new" icon="plus-dark"
              (click)="openClassificatorValueDialog(ClvType.Winery, form.get('winery'), 'wineryOptions')">
            </lxm-button>
          </lxm-select>
          <validation-errors for="winery"></validation-errors>
          <validation-errors for="wineryId"></validation-errors>
        </form-field>

        <form-field [readonly]="!edit" label="cards.products.product_alcohol.label.wine_region"
          description="cards.products.product_alcohol.label_description.wine_region" for="wineRegion"
          [required]="productValidity.shouldValidate ? productValidity.isRequiredByRetailer(cardSection, productField.WineRegionOfOrigin, form.get('wineRegion').value) : null">
          <lxm-select select [readonly]="!edit" labelField="value" for="wineRegion"
            [options]="wineRegionOfOriginOptions">
            <lxm-button *requiredRights="[UserAction.ManageClvs]" header label="action.add_new" icon="plus-dark"
              (click)="openClassificatorValueDialog(ClvType.WineRegionOfOrigin, form.get('wineRegion'), 'wineRegionOfOriginOptions')">
            </lxm-button>
          </lxm-select>
          <validation-errors for="wineRegionId"></validation-errors>
        </form-field>

      </div>

      <div class="flex flex-col">

        <form-field [readonly]="!edit" label="cards.products.product_alcohol.label.wine_grape_varieties"
          description="cards.products.product_alcohol.label_description.wine_grape_varieties" for="wineGrapes"
          [required]="productValidity.shouldValidate ? productValidity.isRequiredByRetailer(cardSection, productField.WineGrapes, form.get('wineGrapes').value?.length > 0) : null">
          <lxm-select select for="wineGrapes" [readonly]="!edit" [options]="selectedWineGrapes" labelField="value"
            [searchable]="true" [multiple]="true" [maxSelectedItems]="3" url="api/clvac/wineGrapes/" compareField="id">
          </lxm-select>
          <validation-errors for="wineGrapes"></validation-errors>
        </form-field>

        <form-field [readonly]="!edit" label="cards.products.product_alcohol.label.wine_color"
          description="cards.products.product_alcohol.label_description.wine_color" for="wineColorId"
          [required]="productValidity.shouldValidate ? productValidity.isRequiredByRetailer(cardSection, productField.WineColor, form.get('wineColorId').value) : null">
          <lxm-select select for="wineColorId" [readonly]="!edit" class="w100p" [options]="wineColorsOptions"
            [searchable]="true" value="id" labelField="translatedValue" [isTranslated]="true" [sortByLabel]="true">
          </lxm-select>
          <validation-errors for="wineColorId"></validation-errors>
        </form-field>

        <form-field [readonly]="!edit" label="cards.products.product_alcohol.label.wine_character"
          description="cards.products.product_alcohol.label_description.wine_character" for="wineCharacteristicsId"
          [required]="productValidity.shouldValidate ? productValidity.isRequiredByRetailer(cardSection, productField.WineCharacteristics, form.get('wineCharacteristicsId').value) : null">
          <lxm-select select for="wineCharacteristicsId" [readonly]="!edit" class="w100p"
            [options]="wineCharacteristicsOptions" [searchable]="true" value="id" labelField="translatedValue"
            [isTranslated]="true" [sortByLabel]="true">
          </lxm-select>
          <validation-errors for="wineCharacteristicsId"></validation-errors>
        </form-field>

        <form-field [readonly]="!edit" label="cards.products.product_alcohol.label.wine_intensity"
          description="cards.products.product_alcohol.label_description.wine_intensity" for="wineIntensityId"
          [required]="productValidity.shouldValidate ? productValidity.isRequiredByRetailer(cardSection, productField.WineIntensity, form.get('wineIntensityId').value) : null">
          <lxm-select select for="wineIntensityId" class="w100p" [readonly]="!edit" [options]="wineIntensitiesOptions"
            [searchable]="true" value="id" labelField="translatedValue" [isTranslated]="true">
          </lxm-select>
          <validation-errors for="wineIntensityId"></validation-errors>
        </form-field>

        <form-field [readonly]="!edit" label="cards.products.product_alcohol.label.wine_taste"
          description="cards.products.product_alcohol.label_description.wine_taste" for="wineTasteId"
          [required]="productValidity.shouldValidate ? productValidity.isRequiredByRetailer(cardSection, productField.WineTaste, form.get('wineTasteId').value) : null">
          <lxm-select select for="wineTasteId" [readonly]="!edit" class="w100p" [options]="wineTastesOptions"
            [searchable]="true" value="id" labelField="translatedValue" [isTranslated]="true" [sortByLabel]="true">
          </lxm-select>
          <validation-errors for="wineTasteId"></validation-errors>
        </form-field>

        <form-field for="servingTemperature" type="input-text" [readonly]="!edit"
          label="cards.products.product_alcohol.label.alcohol_serving_temperature"
          description="cards.products.product_alcohol.label_description.alcohol_serving_temperature"
          [required]="productValidity.shouldValidate ? productValidity.isRequiredByRetailer(cardSection, productField.AlcoholServingTemperature, form.get('servingTemperature').value) : null">
          <validation-errors for="servingTemperature"></validation-errors>
        </form-field>

        <form-field [readonly]="!edit" label="cards.products.product_alcohol.label.wine_food_suggestion"
          description="cards.products.product_alcohol.label_description.wine_food_suggestion" for="wineFoodSuggestions"
          [required]="productValidity.shouldValidate ? productValidity.isRequiredByRetailer(cardSection, productField.WineFoodSuggestions, form.get('wineFoodSuggestions').value?.length > 0) : null">
          <lxm-select select for="wineFoodSuggestions" class="w-full" [readonly]="!edit"
            [options]="wineFoodSuggestionsOptions" value="id" labelField="translatedValue" [isTranslated]="true"
            [searchable]="true" [multiple]="true" maxSelectedItems="4" [sortByLabel]="true">
          </lxm-select>
          <validation-errors for="wineFoodSuggestions"></validation-errors>
        </form-field>

      </div>
    </div>

    <div class="grid grid-cols-1 xl:grid-cols-2 w-full gap-4">
      <div class="flex flex-col">

        <form-field [readonly]="!edit" label="cards.products.product_alcohol.label.alcohol_description"
          description="cards.products.product_alcohol.label_description.alcohol_description" for="description"
          [required]="productValidity.shouldValidate ? productValidity.isRequiredByRetailer(cardSection, productField.AlcoholDescription, form.get('description').value) : null">
          <language-text language-text for="description" [readonly]="!edit" [newline]="true"></language-text>
          <validation-errors for="description"></validation-errors>
        </form-field>

      </div>
    </div>

  </div>

</card-expanding>