import { Component, OnInit, Input, Inject, ViewChild, AfterViewInit, Injector } from "@angular/core";
import { FormGroup, FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SupplierProductAssortmentService, AuthenticationService, ProductValidity } from 'src/app/_services';
import { DialogComponent } from 'src/app/components/_layout/dialog/dialog.component';
import { LxmDialog } from 'src/app/_helpers/dialogs';
import { ActivatedRoute } from '@angular/router';
import { ISupplierProductAssortment, ISupplierProductAssortmentFormData } from './supplier-product-assortment.model';
import { Subscription, forkJoin, Observable } from 'rxjs';
import { asArray, SupplierProductAssortmentStatus, Bool, UserAction, ProductCardSection, asObject } from 'src/app/enum';
import { IProductCardFormData, IRetailChain, IProductCard, IProductBaseInfo, IProductCardRetailChain } from '../product-info/product-info.model';
import { nextSchemeDay } from 'src/app/_helpers/calculate';
import { AppState } from 'src/app/state/app.state';
import { appSettings } from 'src/app/app.settings';
import { LxmMessage } from 'src/app/_helpers';
import { TranslateService } from "@ngx-translate/core";
import { ProductCardBase } from "../product-card-base";
import { LOCAL_STORAGE_KEY } from "src/app/config";

@Component({
  selector: "supplier-product-assortment-card",
  templateUrl: "./supplier-product-assortment.card.html",
  styleUrls: ['./supplier-product-assortment.card.scss']
})
export class SupplierProductAssortmentCard extends ProductCardBase<ISupplierProductAssortment[]> implements OnInit {

  public title = 'cards.products.product_catalog.title';

  public UserAction = UserAction;
  public Bool = Bool;
  public ProductAssortmentStatus = SupplierProductAssortmentStatus;

  public cardSection = ProductCardSection.AssortmentSchemes;

  public baseColumns: string[];
  public listColumns: string[] = [
    'assortment_list_expander',
    'assortment_list_valid',
    'assortment_list_type',
    'assortment_list_shopNumber'
  ];

  public tableSupplier = {
    base: {
      columns: []
    }
  };

  public tableRetailer = {
    base: {
      columns: [
        'assortment_base_valid',
        'assortment_base_status',
      ]
    }
  };

  public productCard: IProductCard;
  public assortments: ISupplierProductAssortment[];
  public expandedElements = [];

  public formData: IProductCardFormData;
  public retailChains: IProductCardRetailChain[];
  public retailChainsById: { [key: string]: IProductCardRetailChain };

  @Input()
  public productId: string;

  public cardValid = true;
  public canEdit: boolean;
  public isOwnProduct: boolean;

  private _loadBaseAssortmentsSubscription: Subscription;

  public isExpandedKey = LOCAL_STORAGE_KEY.PRODUCT_SUPPLIER_PRODUCT_ASSORTMENT_CARD_OPEN;

  constructor(
    injector: Injector,
    private _appState: AppState,
    private _dialog: LxmDialog,
    private _supplierProductAssortmentService: SupplierProductAssortmentService,
    route: ActivatedRoute,
    private _authService: AuthenticationService,
    public productValidity: ProductValidity,
    private _msg: LxmMessage) {
    super(injector, 'productSupplierAssortment', 'productSupplierAssortmentChanged');

    this.productCard = route.snapshot.data.productCard as IProductCard;

    this.isOwnProduct = this.productCard.isOwnProduct;
    this.assortments = this.productCard.assortments;
    this.formData = this.productCard.formData;
    this.retailChains = this.formData.retailChains.filter(x => !x.isClient);
    this.retailChainsById = this.retailChains.reduce((dict, x) => {
      dict[x.id] = x;
      return dict;
    }, {});
  }

  ngOnInit() {
    super.ngOnInit();
    this.canEdit = this._authService.hasRight([UserAction.ManageProducts]);
  }

  public get table() {

    if (this.isOwnProduct) {
      if (this.tableSupplier.base.columns.length === 0) {
        this.tableSupplier.base.columns = [
          "assortment_base_valid",
          "assortment_base_status",
          "assortment_base_visibility",
          // "is_orderable",
          "orderable_for_clients",
          "assortment_base_user"
          // ...this.retailChains.sort((a, b) => a.shortName > b.shortName ? 1 : a.shortName === b.shortName ? 0 : -1).map(x => x.id)
        ];
      }
      return this.tableSupplier;
    }
    else {
      return this.tableRetailer;
    }
  }

  public isAvailable(row: ISupplierProductAssortment, chainId: string): boolean {
    if (!row.retailerIds || row.retailerIds.length === 0) {
      return row.retailersListIsExclusive;
    }

    const ix = row.retailerIds?.indexOf(chainId);
    return row.retailersListIsExclusive
      ? ix < 0
      : ix >= 0;
  }

  public openBaseSchemaDialog(row?: ISupplierProductAssortment) {
    if ((row && !row.isEditable) || !this.canEdit) {
      return;
    }

    this._dialog.open(SupplierProductAssortmentBaseSchemaDialog, {
      ...appSettings.DIALOG_SIZES.M,
      data: {
        assortmentCard: this,
        retailChains: this.retailChains,
        schema: row
      },
      resolve: () => {
        const resolvers: any = {
          formData: this._supplierProductAssortmentService.getFormData(this.productId)
        };
        return forkJoin(resolvers);
      }
    });
  }

  public loadBaseAssortments(): void {
    if (this._loadBaseAssortmentsSubscription) {
      this._loadBaseAssortmentsSubscription.unsubscribe();
    }

    this._loadBaseAssortmentsSubscription = this._supplierProductAssortmentService
      .getGridData(this.productId)
      .subscribe(result => {
        this.assortments = result;
      }, err => {
        console.error(err);
        this._msg.error({
          message: 'cards.products.product_assortment_management.table.load_failed'
        })
      });
  }

  //

  public toggleExpandRow(row) {
    event.stopImmediatePropagation();
    if (this.expandedElements.includes(row)) {
      let index = this.expandedElements.indexOf(row);
      this.expandedElements.splice(index, 1);
    } else {
      this.expandedElements.push(row);
    }
  }

}

@Component({
  selector: "supplier-product-assortment-base-schema-dialog",
  host: { 'class': 'dialog' },
  templateUrl: "./template/supplier-product-assortment-schema.dialog.html"
})
export class SupplierProductAssortmentBaseSchemaDialog implements AfterViewInit {

  @ViewChild('dialog', { static: true, read: DialogComponent })
  private _dialog: DialogComponent;

  private _enabledStatuses = [
    SupplierProductAssortmentStatus.Active,
    SupplierProductAssortmentStatus.EndOfLife,
    SupplierProductAssortmentStatus.Inactive,
  ]

  public saveLoading = false;
  public deleteLoading = false;
  public statuses = this._enabledStatuses.map(key => asObject(SupplierProductAssortmentStatus, key));
  public retailChains: IRetailChain[];

  public form: FormGroup;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: IProductAssortmentDialogData,
    public dialogRef: MatDialogRef<SupplierProductAssortmentBaseSchemaDialog>,
    private _supplierProductAssortmentService: SupplierProductAssortmentService,
    private _message: LxmMessage,
    public dialog: LxmDialog,
    private _translateService: TranslateService) {

    this.retailChains = data.retailChains
      .filter(x => !x.isClient)
      .map(x => Object.assign({}, x));

    this.form = new FormGroup({
      id: new FormControl(),
      validFrom: new FormControl(),
      status: new FormControl(SupplierProductAssortmentStatus.Active),
      retailersListIsExclusive: new FormControl(),
      retailers: new FormControl(),
      isOrderableProduct: new FormControl(),
      orderableWithPriceSchemeOnly: new FormControl(),
      orderableRetailers: new FormControl(),
      priceLists: new FormControl()
    });

    this.form.patchValue(this.normalizeData(this.data.schema));
  }

  public get priceListOptions() {
    return this.data.assortmentCard.formData.priceLists;
  }

  private normalizeData(data: ISupplierProductAssortment): any {
    const existingAssortment = this.data.assortmentCard.assortments?.[0];

    let result: any;

    if (data) {
      result = { ...data };
    } else if (existingAssortment) {
      result = { ...existingAssortment };
      result.id = null;
      result.validFrom = null;
    } else {
      result = <any>{};
    }

    result.retailers = result.retailerIds || result.retailers;

    result.validFrom = result.validFrom
      ? result.validFrom.editableDate
      : nextSchemeDay(existingAssortment?.validFrom.editableDate);

    result.isOrderableProduct = result.isOrderable;
    result.orderableWithPriceSchemeOnly = result.withPriceSchemeOnly;
    result.orderableRetailers = result.orderableRetailerIds;

    return result;
  }

  ngAfterViewInit(): void {

    this._dialog.dataLoaded$
      .subscribe(state => {
        if (!state) {
          return;
        }

        if (!this.data.assortmentCard.assortments.length) {
          this.form.patchValue({
            retailersListIsExclusive: this.data.formData.retailersListIsExclusive
          });
        }
      });

  }

  public save(): void {
    this.saveLoading = true;
    const f = this.form.value;

    const req = {
      productId: this.data.assortmentCard.productId,
      validFrom: f.validFrom,
      status: f.status,
      retailersListIsExclusive: f.retailersListIsExclusive || false,
      retailerIds: f.retailers,
      isOrderableProduct: f.isOrderableProduct || false,
      orderableWithPriceSchemeOnly: false,
      orderableRetailerIds: null,
      priceListIds: null
    };

    if (f.isOrderableProduct) {
      req.orderableWithPriceSchemeOnly = f.orderableWithPriceSchemeOnly || false;
      req.orderableRetailerIds = f.orderableRetailers;
      req.priceListIds = f.priceLists?.map(x => x.id);
    }

    this._supplierProductAssortmentService
      .save(this.data.assortmentCard.productId, f.id, req)
      .result(this.form, () => {
        this.data.assortmentCard.loadBaseAssortments();
        this.closeDialog();
      }, (err) => {

        this._message.error({
          message: err.validationSummary || 'common.error.save_failed',
        });

        this.saveLoading = false;
      });
  }

  public deleteScheme() {
    this.dialog.confirm({
      image: 'delete-modal-image',
      template: `
      <p class="dialog-title">${this._translateService.instant('cards.products.product_assortment_management.delete.title')}</p>
      <p>
        ${this._translateService.instant('cards.products.product_assortment_management.delete.message')}
      </p>
      `
    }, () => {
      this.deleteLoading = true;
      const id = this.data.schema.id;
      this._supplierProductAssortmentService.delete(this.data.assortmentCard.productId, id).result(this.form, () => {
        this.data.assortmentCard.loadBaseAssortments();
        this.closeDialog();
      }, () => {
        this.deleteLoading = false;
      });
    });
  }

  public onNoClick(): void {
    this.dialogRef.close();
  }

  public closeDialog() {
    this.dialogRef.close();
  }
}

interface IProductAssortmentDialogData {
  formData: ISupplierProductAssortmentFormData;
  assortmentCard: SupplierProductAssortmentCard;
  retailChains: IRetailChain[];
  schema: ISupplierProductAssortment;
}
