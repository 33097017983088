import { Component } from "@angular/core";
import { LxmAppModule, UserAction } from "src/app/enum";
import { AppState } from "src/app/state/app.state";

@Component({
  selector: "lxm-settings-navigator",
  templateUrl: "./lxm-settings-navigator.card.html",
  styleUrls: ["./lxm-settings-navigator.scss"]
})
export class LxmSettingsNavigatorCard {
  private _navLinks = [
    {path: 'profile', label: 'cards.lexi_market_settings.lxm_settings_company_profile.title', requiredRight: UserAction.ManageSettings },
    {path: 'packages', label: 'cards.lexi_market_settings.lxm_settings_subscription.title', requiredRight: UserAction.ManageSettings, requiredModule: LxmAppModule.SellerPimExtensions},
    // {path: 'subscription', label: 'cards.lexi_market_settings.lxm_settings_subscription.title', requiredRight: UserAction.ManageSettings, requiredModule: LxmAppModule.SellerPimExtensions},
    {path: 'users', label: 'cards.lexi_market_settings.lxm_settings_user.title', requiredRight: UserAction.ViewUsers},
    {path: 'roles', label: 'cards.lexi_market_settings.lxm_settings_user_role.title', requiredRight: UserAction.ManageSettings},
    {path: 'mail', label: 'cards.lexi_market_settings.mail_settings.title', requiredRight: UserAction.ManageSettings},
    {path: 'product-fields/extra-parameters', label: 'cards.lexi_market_settings.product_fields_settings.title', requiredRight: UserAction.ManageSettings},
  ];

  public get navLinks() {
    let userRightsFilter = this._navLinks.filter(x => this._appState.hasRight([x.requiredRight]));
    return userRightsFilter.filter(x => x.requiredModule ? this._appState.hasModule(x.requiredModule) : true);
  }

  public constructor(private _appState: AppState) {}
}