<card [translationsPath]="translationsPath" [fullScreen]="state.isFullScreen">

  <div header-actions>

    <div class="button-group">
      <lxm-button [class.active]="isListViewActive(OffersListViewType.All)"
        label="cards.offers.filters.all" (click)="setListView(OffersListViewType.All)">
      </lxm-button>
      <lxm-button [class.active]="isListViewActive(OffersListViewType.Saved)"
        label="cards.offers.filters.saved" (click)="setListView(OffersListViewType.Saved)">
      </lxm-button>
      <lxm-button [class.active]="isListViewActive(OffersListViewType.Active)"
        label="cards.offers.filters.active" (click)="setListView(OffersListViewType.Active)">
      </lxm-button>
      <lxm-button [class.active]="isListViewActive(OffersListViewType.Archive)"
        label="cards.offers.filters.archive" (click)="setListView(OffersListViewType.Archive)">
      </lxm-button>
    </div>

      <lxm-button type="beige" *requiredRights="[UserAction.ManageSupplierOffers]" icon="plus-dark"
        label=".action.create_offer" routerLink="new">
      </lxm-button>

    <lxm-button type="beige" icon="excel" title="action.download_xls">
    </lxm-button>

  </div>

  <div body class="sticky-table">


    <div class="sticky-table-body w-full overflow-x">
      <mat-table [dataSource]="dataSource" matSort [@.disabled]="true" [zoomValue]="state.zoom">

        <ng-container matColumnDef="retailer">
          <mat-header-cell *matHeaderCellDef>
            <div mat-sort-header>
              {{(translationsPath + '.table.retailer') | translate}}
            </div>
          </mat-header-cell>
          <mat-cell *matCellDef="let row">
            <span class="flex flex-row">
              <type-indicator [type]="row.type"></type-indicator>
              {{row.retailer}}
            </span>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="period">
          <mat-header-cell *matHeaderCellDef>
            <div mat-sort-header>
              {{(translationsPath + '.table.period_date') | translate}}
            </div>
          </mat-header-cell>
          <mat-cell *matCellDef="let row">
            <span class="flex flex-row">
              {{row.from | formDate}} -
              {{row.to | formDate}}
            </span>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="name">
          <mat-header-cell *matHeaderCellDef>
            <div mat-sort-header>
              {{(translationsPath + '.table.offer_name') | translate}}
            </div>
          </mat-header-cell>
          <mat-cell *matCellDef="let row">
            <span>{{row.name}}</span>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="approved">
          <mat-header-cell *matHeaderCellDef>
            <div mat-sort-header>
              <span class="text-start">
                {{(translationsPath + '.table.offer_approved_products') | translate}}
              </span>
            </div>
          </mat-header-cell>
          <mat-cell *matCellDef="let row">
            <span>
              {{row.confirmedProductCount}} / {{row.productCount}}
            </span>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="status">
          <mat-header-cell *matHeaderCellDef>
            <div mat-sort-header>
              {{(translationsPath + '.table.offer_status') | translate}}
            </div>
          </mat-header-cell>
          <mat-cell *matCellDef="let row">
            <div class="flex f-1 justify-center">
              <status-indicator [status]="getStatusProgress(row.status)"
                [description]="row.status | translateEnum : OfferStatus">
              </status-indicator>
            </div>
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="table.columns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: table.columns;" class="table-row-hover" [class.new]="row.isNew"
          routerLink="/offers/{{row.id}}"></mat-row>
      </mat-table>
    </div>

    <no-content
      *ngIf="!hasOffers"
      image="no-offers"
      heading="cards.offers.no_offers_retailer_heading"
      text="cards.offers.no_offers_retailer_text">
    </no-content>

    <data-table-footer
      [localStorageKey]="LOCAL_STORAGE_KEY"
      [isLoading]="isLoading"
      (paginator)="hookPaginator($event)"
      [search]="search"
      (onSearch)="onSearch($event)"
      [(isFullScreen)]="state.isFullScreen" 
      (onToggleFullScreen)="state.isFullScreen = $event"
      [(zoom)]="state.zoom"
      (onLoad)="onLoad()">
    </data-table-footer>

  </div>

</card>