<div *ngIf="!displayHeadersWhenEmpty && !dataSource.data?.length">
  <no-content
    image="no-files"
    text="global.no_files_added"
    [inline]="true"
  ></no-content>
</div>

<div *ngIf="dataSource.data?.length || displayHeadersWhenEmpty">
  <mat-table [dataSource]="dataSource">
    <ng-container matColumnDef="file_name">
      <mat-header-cell *matHeaderCellDef>
        {{
          "cards.products.product_additional_info_and_instructions.table.file_name"
            | translate
        }}
      </mat-header-cell>
      <mat-cell *matCellDef="let row">
        {{ row.fileName }}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="file_created_at">
      <mat-header-cell *matHeaderCellDef>
        {{
          "cards.products.product_additional_info_and_instructions.table.created_at"
            | translate
        }}
      </mat-header-cell>
      <mat-cell *matCellDef="let row">
        {{ row.createdAt | formDate: "L HH:mm" }}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="file_language">
      <mat-header-cell *matHeaderCellDef>
        {{
          "cards.products.product_additional_info_and_instructions.table.language"
            | translate
        }}
      </mat-header-cell>
      <mat-cell *matCellDef="let row">
        <div class="flex flex-nowrap gap-3x items-center uppercase" *ngIf="row.language">
          <svg-icon [key]="languageMap[row.language]?.iso3" fontSize="12px"></svg-icon>
          <span>
            {{ languageMap[row.language]?.name }}
          </span>
        </div>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="file_created_by">
      <mat-header-cell *matHeaderCellDef>
        {{
          "cards.products.product_additional_info_and_instructions.table.created_by"
            | translate
        }}
      </mat-header-cell>
      <mat-cell *matCellDef="let row">
        <div class="flex flex-nowrap items-center gap-3x">
          <svg-icon key="user" fontSize="12px"></svg-icon>
          <span class="text-sm font-medium text-grey-100">
            {{ row.createdBy.name }}
          </span>
        </div>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="recipients">
      <mat-header-cell *matHeaderCellDef>
        {{
          "cards.products.product_additional_info_and_instructions.table.recipients"
            | translate
        }}
      </mat-header-cell>
      <mat-cell *matCellDef="let row">
        {{ row.recipients?.join(", ") }}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="actions">
      <mat-header-cell *matHeaderCellDef></mat-header-cell>
      <mat-cell *matCellDef="let row">
        <span class="flex flex-row justify-end">
          <lxm-button
            type="transparent"
            icon="download"
            title="action.download"
            (click)="downloadDeclaration(row)"
          ></lxm-button>
          <lxm-button
            type="transparent"
            icon="trash"
            title="action.remove"
            (click)="removeDeclaration(row)"
            *ngIf="!readonly"
          ></lxm-button>
          <!-- <lxm-button type="transparent" icon="edit" title="action.edit" (click)="openAddFileDialog(row)" *ngIf="!readonly"></lxm-button> -->
        </span>
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="columns"></mat-header-row>
    <mat-row
      *matRowDef="let row; columns: columns"
      [ngClass]="{ 'row-to-delete readonly': row.isDeleted }"
    ></mat-row>
  </mat-table>
</div>

<a class="no-display" #download></a>
