<card [fullScreen]="state.isFullScreen">
  <span header-title
    >{{'cards.products.products_retailer.title' | translate}}</span
  >
  <div header-actions *nonVisitor>
    <div
      class="selected-products"
      [tooltip]="productsTemplate"
      tooltipApplyClass="stripped"
      *ngIf="selection.length > 0"
    >
      <span class="selected-count"> {{(selection.length)}} </span>
    </div>

    <ng-template #productsTemplate>
      <div class="selected-products-wrapper" id="selected-products-wrapper">
        <div class="align-center mb10 flex flex-row justify-between">
          <span class="align-center flex flex-row">
            <h2>
              {{'cards.products.products_supplier.selected_products.selected_products'
              | translate}}
            </h2>
            <span class="ml5"> ({{selection.length}}) </span>
          </span>
          <lxm-button
            type="transparent"
            icon="trash"
            padding="8px 0"
            (click)="emptySelectedProducts()"
          >
          </lxm-button>
        </div>
        <table class="selected-products-table">
          <tr class="header-row">
            <th class="select-column"></th>
            <th class="image-column"></th>
            <th
              class="name-column"
              [innerHTML]="'cards.products.products_supplier.table.full_name' | translate"
            ></th>
            <th
              class="ean-column"
              [innerHTML]="'cards.products.products_supplier.table.ean' | translate"
            ></th>
          </tr>
          <tr class="product-row" *ngFor="let selection of selection">
            <td class="select-column">
              <mat-checkbox
                class="selected-checkbox"
                (click)="$event.stopPropagation()"
                (change)="$event ? toggleSelection(selection) : null"
                [checked]="isProductSelected(selection)"
              >
              </mat-checkbox>
            </td>
            <td class="image-column">
              <image-popover
                class="selected-image"
                appendTo="#selected-products-wrapper"
                type="product"
                [popperPositionFixed]="false"
                [image]="selection.imageThumbUrl"
                [thumbnail]="selection.thumbnail"
                [thumbnailBase64]="true"
                [thumbnailContentType]="selection.thumbnailContentType"
                [status]="selection.status"
                [isActiveBottom]="selection.activeViaOffer"
              >
              </image-popover>
            </td>
            <td class="name-column">
              <a class="link" href="/products/{{selection.id}}" target="_blank">
                {{selection.fullName}}
              </a>
            </td>
            <td class="ean-column">{{selection.ean}}</td>
          </tr>
        </table>
      </div>
    </ng-template>

    <ng-container *requiredRights="[UserAction.ManageSupplierOffers]">
      <lxm-button
        *ngIf="selection.length"
        label="cards.products.product_base_info.action.create_offer"
        type="green"
        icon="plus-dark"
        (click)="createOffer()"
      >
      </lxm-button>
    </ng-container>
    <lxm-button
      type="beige"
      icon="plus-dark"
      label="cards.products.products_supplier.add_product"
      (click)="newProduct()"
      *requiredRights="[UserAction.ManageProducts]"
    >
    </lxm-button>

    <dot-menu [template]="optionsTemplate">
      <ng-template #optionsTemplate>
        <ul>
          <li (click)="exportXls()">
            <lxm-button icon="excel" label="action.download_xls"></lxm-button>
          </li>
          <li [class.disabled]="!selection.length"
            *ngIf="appState.hasModule(LxmAppModule.Pdf)"
            (click)="openExportProductsDialog()"
          >
            <lxm-button
              icon="pdf"
              label="action.download_pdf"
            ></lxm-button>
          </li>
          <li
            (click)="exportXlsForSupplier()"
            [class.disabled]="selectedSuppliersCount != 1"
          >
            <lxm-button
              icon="download"
              label="action.download_supplier_products"
            ></lxm-button>
          </li>
          <li
            *ngIf="appState.supportedProductSync.indexOf(appState.contentTenantId) >= 0"
            (click)="openSyncProductsDialog()"
          >
            <lxm-button icon="undelete" label="action.sync_products">
            </lxm-button>
          </li>
        </ul>
      </ng-template>
    </dot-menu>
  </div>

  <div body class="sticky-table">
    <div class="sticky-table-body overflow-x w-full">
      <mat-table
        [dataSource]="dataSource"
        matSort
        [@.disabled]="true"
        [zoomValue]="state.zoom"
      >
        <!-- Checkbox Column -->
        <ng-container matColumnDef="select" sticky>
          <mat-header-cell *matHeaderCellDef>
            <div class="f-1 align-center flex justify-center">
              <mat-checkbox
                (change)="$event ? masterToggle() : null"
                [checked]="isAllSelected()"
                [indeterminate]="!isAllSelected()"
              >
              </mat-checkbox>
            </div>
          </mat-header-cell>
          <mat-cell
            *matCellDef="let row"
            stop-propagation
            (click)="$event ? toggleSelection(row) : null"
          >
            <div class="f-1 align-center flex justify-center">
              <mat-checkbox
                class="table-checkbox"
                (click)="$event.stopPropagation()"
                (change)="$event ? toggleSelection(row) : null"
                [checked]="isProductSelected(row)"
              >
              </mat-checkbox>
            </div>
          </mat-cell>
        </ng-container>

        <!-- Image Popover Column -->
        <ng-container matColumnDef="image" sticky>
          <mat-header-cell *matHeaderCellDef></mat-header-cell>
          <mat-cell *matCellDef="let row">
            <image-popover
              [image]="row.imageThumbUrl"
              [thumbnail]="row.thumbnail"
              [thumbnailBase64]="true"
              [thumbnailContentType]="row.thumbnailContentType"
              [statusText]="row.assortmentStatus?.value ?? undefined"
              [color]="row.assortmentStatus?.color ?? undefined"
            >
            </image-popover>
          </mat-cell>
        </ng-container>

        <!-- Full Name Column -->
        <ng-container matColumnDef="fullName" sticky>
          <mat-header-cell *matHeaderCellDef>
            <div mat-sort-header>
              {{'cards.products.products_retailer.table.full_name' | translate}}
            </div>
          </mat-header-cell>
          <mat-cell *matCellDef="let row">
            <span class="flex overflow-ellipsis"> {{row.fullName}} </span>
          </mat-cell>
        </ng-container>

        <!-- EAN Column -->
        <ng-container matColumnDef="ean">
          <mat-header-cell *matHeaderCellDef>
            <div mat-sort-header>
              {{'cards.products.products_retailer.table.ean' | translate}}
            </div>
          </mat-header-cell>
          <mat-cell *matCellDef="let row">
            <div class="triple-rows">
              <div class="cell-row">
                <svg-icon
                  class="flex"
                  key="linked"
                  fontSize="14px"
                  *ngIf="row.hasOriginProduct"
                  [title]="'cards.products.products_retailer.table.origin_product_icon' | translate"
                ></svg-icon>
              </div>
              <div class="cell-row">{{row.ean || '-'}}</div>
              <div class="cell-row alt-text">{{row.internalCode || '-'}}</div>
            </div>
          </mat-cell>
        </ng-container>

        <!-- Custom code Column -->
        <ng-container matColumnDef="custom_code">
          <mat-header-cell *matHeaderCellDef>
            <div mat-sort-header>
              {{'cards.products.products_retailer.table.codes' | translate}}
            </div>
          </mat-header-cell>
          <mat-cell *matCellDef="let row">
            <div class="triple-rows">
              <div class="cell-row"></div>
              <div class="cell-row">{{row.mainCode}}</div>
              <div class="cell-row alt-text">{{ row.secondaryCode }}</div>
            </div>
          </mat-cell>
        </ng-container>

        <!-- Brand Column -->
        <ng-container matColumnDef="brand">
          <mat-header-cell *matHeaderCellDef>
            <div mat-sort-header>
              {{'cards.products.products_retailer.table.brand' | translate}}
            </div>
          </mat-header-cell>
          <mat-cell *matCellDef="let row">
            <span>{{row.brand}}</span>
          </mat-cell>
        </ng-container>

        <!-- Gpc Segment Column -->
        <ng-container matColumnDef="gpcSegment">
          <mat-header-cell *matHeaderCellDef>
            <div mat-sort-header>
              {{'cards.products.products_retailer.table.product_tree' |
              translate}}
            </div>
          </mat-header-cell>
          <mat-cell *matCellDef="let row">
            <span> {{row.gpcSegment}} </span>
          </mat-cell>
        </ng-container>

        <!-- Segment Column -->
        <ng-container matColumnDef="category">
          <mat-header-cell *matHeaderCellDef>
            <div mat-sort-header>
              {{'cards.products.products_retailer.table.category' | translate}}
            </div>
          </mat-header-cell>
          <mat-cell *matCellDef="let row">
            <span> {{row.segment}} </span>
          </mat-cell>
        </ng-container>

        <!-- Segment Column with alternative translation -->
        <ng-container matColumnDef="segment">
          <mat-header-cell *matHeaderCellDef>
            <div mat-sort-header>
              {{'cards.products.products_retailer.table.segment' | translate}}
            </div>
          </mat-header-cell>
          <mat-cell *matCellDef="let row">
            <span> {{row.segment}} </span>
          </mat-cell>
        </ng-container>

        <!-- Supplier Column -->
        <ng-container matColumnDef="supplier">
          <mat-header-cell *matHeaderCellDef>
            <div mat-sort-header>
              {{'cards.products.products_retailer.table.supplier' | translate}}
            </div>
          </mat-header-cell>
          <mat-cell *matCellDef="let row" class="triple-rows">
            <div class="triple-rows">
              <span class="cell-row"> &nbsp; </span>
              <span class="cell-row"> {{row.supplier || '-'}} </span>
              <div class="cell-row alt-text">
                {{ getPrioritySchemeField(row, 'logisticsPartner')}}
              </div>
            </div>
          </mat-cell>
        </ng-container>

        <!-- Listed Price Column -->
        <ng-container matColumnDef="product_listed_price">
          <mat-header-cell *matHeaderCellDef>
            <div mat-sort-header>
              <span
                [innerHTML]="'cards.products.products_retailer.table.listed_price' | translate"
              ></span>
            </div>
          </mat-header-cell>
          <mat-cell *matCellDef="let row">
            <div class="triple-rows">
              <div class="cell-row"></div>
              <div class="cell-row">
                <product-scheme-display-price
                  [scheme]="{ 
                    mainPriceScheme: row.mainPriceScheme, 
                    campaignPriceScheme: row.campaignPriceScheme, 
                    procurementPriceScheme: row.procurementPriceScheme 
                  }"
                  [pricePrecision]="pricePrecision"
                  [procurementHasPriority]="procurementHasPriority"
                  displayPriceField="listPrice"
                >
                </product-scheme-display-price>
              </div>
              <div class="cell-row"></div>
            </div>
          </mat-cell>
        </ng-container>

        <!-- Listed Price Column -->
        <ng-container matColumnDef="product_retail_price">
          <mat-header-cell *matHeaderCellDef>
            <div mat-sort-header>
              <span
                [innerHTML]="'cards.products.products_retailer.table.retail_price' | translate"
              ></span>
            </div>
          </mat-header-cell>
          <mat-cell *matCellDef="let row">
            <div class="triple-rows">
              <div class="cell-row"></div>
              <div class="cell-row">
                <product-scheme-display-price
                  [scheme]="{ 
                    mainPriceScheme: row.mainPriceScheme, 
                    campaignPriceScheme: row.campaignPriceScheme, 
                    procurementPriceScheme: row.procurementPriceScheme 
                  }"
                  [pricePrecision]="2"
                  [procurementHasPriority]="procurementHasPriority"
                  displayPriceField="retailPrice"
                >
                </product-scheme-display-price>
              </div>
              <div class="cell-row">
                <product-scheme-unit-price
                  class="alt-text"
                  [scheme]="{ 
                  mainPriceScheme: row.mainPriceScheme, 
                  campaignPriceScheme: row.campaignPriceScheme, 
                  procurementPriceScheme: row.procurementPriceScheme 
                  }"
                  [pricePrecision]="pricePrecision"
                  [procurementHasPriority]="procurementHasPriority"
                  unitPriceField="retailUnitPrice"
                  [contentUnitId]="row.contentUnitId"
                >
                </product-scheme-unit-price>
              </div>
            </div>
          </mat-cell>
        </ng-container>

        <!-- Assortment groups -->
        <ng-container matColumnDef="assortment_groups">
          <mat-header-cell *matHeaderCellDef>
            <span
              [innerHTML]="'cards.products.products_retailer.table.assortment_groups' | translate"
            >
            </span>
          </mat-header-cell>
          <mat-cell *matCellDef="let row" class="overflow-cell">
            <div class="f-1 align-center flex justify-center">
              <span
                *ngIf="row.assortmentOut"
                class="direction-left-icon mr10"
                [title]="row.validTo | formDate"
              ></span>
              <span
                *ngIf="row.assortmentIn"
                class="direction-right-icon mr10"
                [title]="row.validFrom | formDate"
              ></span>
              <data-hover
                *ngIf="row.assortmentGroups?.length"
                background="grey"
              >
                <div class="f-1 flex flex-row justify-between">
                  <div class="f-1 flex flex-wrap">
                    <span
                      class="assortment-group-name"
                      *ngFor="let group of row.assortmentGroups; let i = index;"
                    >
                      {{ group?.name }}<ng-container
                        *ngIf="i !== row.assortmentGroups.length - 1"
                        >,&nbsp;</ng-container
                      >
                    </span>
                  </div>
                  <div class="bold ml10 mr10" *ngIf="row.assortmentStatus">
                    {{row.assortmentStatus.value}}
                  </div>
                </div>
              </data-hover>
            </div>
          </mat-cell>
        </ng-container>

        <!-- Priority -->
        <ng-container matColumnDef="assortment_priority">
          <mat-header-cell *matHeaderCellDef>
            <span
              class="cell-center"
              [innerHTML]="'cards.products.products_retailer.table.assortment_priority' | translate"
            >
            </span>
          </mat-header-cell>
          <mat-cell *matCellDef="let row">
            <div class="triple-rows cell-center">
              <div class="cell-row"></div>
              <div class="cell-row">{{row.assortmentStatus?.value || '-'}}</div>
              <div class="cell-row"></div>
            </div>
          </mat-cell>
        </ng-container>

        <!-- Product info fullness -->
        <ng-container matColumnDef="productInfo_fullness">
          <mat-header-cell *matHeaderCellDef>
            <span
              [innerHTML]="'cards.products.products_retailer.table.productInfo_fullness' | translate"
            >
            </span>
          </mat-header-cell>
          <mat-cell *matCellDef="let row" class="overflow-cell">
            <div class="f-1 align-center flex flex-row">
              <progress-bar
                [value]="row.productInfoFullness"
                style="width: 45px; height: 10px"
              ></progress-bar>
              <span
                class="ml8 flex"
                style="font-family: RedHatText; color: var(--dirty-purple)"
              >
                {{row.productInfoFullness ? (row.productInfoFullness + '%') :
                ''}}
              </span>
            </div>
          </mat-cell>
        </ng-container>

        <!-- Product Scheme Info -->
        <ng-container matColumnDef="product_scheme_info">
          <mat-header-cell *matHeaderCellDef> </mat-header-cell>
          <mat-cell *matCellDef="let row">
            <div
              class="triple-rows f-1 align-end flex justify-end"
              style="align-items: flex-end !important"
            >
              <div class="cell-row"></div>
              <div class="cell-row f-1 flex justify-end">
                <retailer-product-price-info-popover
                  [scheme]="{
                    mainPriceScheme: row.mainPriceScheme,
                    campaignPriceScheme: row.campaignPriceScheme,
                    procurementPriceScheme: row.procurementPriceScheme
                  }"
                  [pricePrecision]="pricePrecision"
                  [procurementHasPriority]="procurementHasPriority"
                  displayPriceField="buyInPrice"
                  [retailPriceField]="retailPriceField"
                >
                </retailer-product-price-info-popover>
              </div>
              <div class="cell-row"></div>
            </div>
          </mat-cell>
        </ng-container>

        <!-- Price For Retailer Column -->
        <ng-container matColumnDef="product_price_for_retailer">
          <mat-header-cell *matHeaderCellDef>
            <span
              [innerHTML]="'cards.products.products_retailer.table.buy_in_price' | translate"
            ></span>
          </mat-header-cell>
          <mat-cell *matCellDef="let row">
            <div class="triple-rows">
              <!-- <retailer-product-price-info-popover 
                [scheme]="{
                  mainPriceScheme: row.mainPriceScheme,
                  campaignPriceScheme: row.campaignPriceScheme,
                  procurementPriceScheme: row.procurementPriceScheme
                }"
                [pricePrecision]="pricePrecision"
                displayPriceField="buyInPrice"
                [retailPriceField]="retailPriceField">
              </retailer-product-price-info-popover> -->
              <div class="cell-row"></div>
              <div class="cell-row">
                <product-scheme-display-price
                  [scheme]="{
                    mainPriceScheme: row.mainPriceScheme,
                    campaignPriceScheme: row.campaignPriceScheme,
                    procurementPriceScheme: row.procurementPriceScheme
                  }"
                  [pricePrecision]="pricePrecision"
                  [procurementHasPriority]="procurementHasPriority"
                  displayPriceField="buyInPrice"
                >
                </product-scheme-display-price>
              </div>
              <div class="cell-row alt-text">
                <product-scheme-unit-price
                  [scheme]="{ 
                  mainPriceScheme: row.mainPriceScheme, 
                  campaignPriceScheme: row.campaignPriceScheme, 
                  procurementPriceScheme: row.procurementPriceScheme 
                  }"
                  [pricePrecision]="pricePrecision"
                  [procurementHasPriority]="procurementHasPriority"
                  unitPriceField="buyInUnitPrice"
                  [contentUnitId]="row.contentUnitId"
                >
                </product-scheme-unit-price>
              </div>
            </div>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="product_retail_price_without_vat">
          <mat-header-cell *matHeaderCellDef>
            <div mat-sort-header>
              <span
                [innerHTML]="'cards.products.products_retailer.table.retail_price_without_vat' | translate"
              ></span>
            </div>
          </mat-header-cell>
          <mat-cell *matCellDef="let row">
            <div class="triple-rows">
              <span class="cell-row"> &nbsp; </span>
              <span class="cell-row">
                <product-scheme-display-price
                  [scheme]="{
                    mainPriceScheme: row.mainPriceScheme,
                    campaignPriceScheme: row.campaignPriceScheme,
                    procurementPriceScheme: row.procurementPriceScheme
                  }"
                  [pricePrecision]="pricePrecision"
                  [procurementHasPriority]="procurementHasPriority"
                  displayPriceField="retailPriceWithoutVat"
                  retailPriceField="retailPriceWithoutVat"
                  class="cell-row"
                >
                </product-scheme-display-price>
              </span>
              <div class="cell-row alt-text">
                <product-scheme-unit-price
                  [scheme]="{
                    mainPriceScheme: row.mainPriceScheme,
                    campaignPriceScheme: row.campaignPriceScheme,
                    procurementPriceScheme: row.procurementPriceScheme
                  }"
                  [pricePrecision]="pricePrecision"
                  [procurementHasPriority]="procurementHasPriority"
                  unitPriceField="retailUnitPriceWithoutVat"
                  [contentUnitId]="row.contentUnitId"
                >
                </product-scheme-unit-price>
              </div>
            </div>
          </mat-cell>
        </ng-container>

        <!-- Net Content With Content Unit Column -->
        <ng-container matColumnDef="netContentWithUnit">
          <mat-header-cell *matHeaderCellDef>
            <div>
              {{'cards.products.products_retailer.table.net_content_with_unit' |
              translate}}
            </div>
          </mat-header-cell>
          <mat-cell *matCellDef="let row">
            <span>
              {{row.netContent}} {{row.contentUnitId |
              translateEnum:'UnitShort'}}
            </span>
          </mat-cell>
        </ng-container>

        <mat-header-row
          *matHeaderRowDef="table.columns"
          class="products-table-head"
        ></mat-header-row>
        <mat-row
          *matRowDef="let row; columns: table.columns;"
          class="table-row-hover"
          routerLink="/products/{{row.id}}"
        ></mat-row>
      </mat-table>
    </div>

    <no-content
      heading="cards.products.no_products_heading"
      text="cards.products.no_products_text"
      image="no-products"
      *ngIf="!hasProducts"
    >
    </no-content>

    <data-table-footer
      [localStorageKey]="LOCAL_STORAGE_KEY"
      [isLoading]="isLoading"
      (paginator)="hookPaginator($event)"
      [search]="search"
      (onSearch)="onSearch($event)"
      [(isFullScreen)]="state.isFullScreen"
      (onToggleFullScreen)="state.isFullScreen = $event"
      [(zoom)]="state.zoom"
      (onLoad)="onLoad()"
    >
    </data-table-footer>
  </div>
</card>

<a class="no-display" #download></a>
