import { Component, Input, Output, EventEmitter, ViewChild, ElementRef, ChangeDetectionStrategy, HostBinding } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: "search",
  templateUrl: "./search.component.html",
  styleUrls: ['./search.component.scss']
})
export class SearchComponent {

  @ViewChild('searchInput') searchInput: ElementRef;

  @Input() public formGroup: FormGroup;
  @Input() public placeholder: string;

  @HostBinding('class.open')
  @Input() public isOpen = false;
  @Input() public isLoading = false;

  @Input() public showPanel = true;
  @Input() public showBookmarks = false;
  @Input() public showSaveButton = false; // TODO

  @Input() public resetParams = null;

  @Output() public onSubmit = new EventEmitter();
  @Output() public onToggle = new EventEmitter();

  public filtersCount = 0;

  public onSearch() {
    this.onSubmit.emit();
  }

  public togglePanel(toggle = !this.isOpen) {
    this.isOpen = toggle;
    this.onToggle.emit(toggle);
  }

  public resetFields() {
    this.formGroup.reset(this.resetParams || {});
    this.onSearch();
  }

  public get isInputFocused() {
    return document.activeElement === this.searchInput?.nativeElement;
  }

  private _getFiltersCount(): void {
    const values = Object.values(this.formGroup.value);

    if (!values?.length) {
      this.filtersCount = 0;
      return;
    }

    const filterCount = values.filter(
      (x) => { 
        if (Array.isArray(x)) {
          return x.length !== 0; 
        }

        return x !== null && x !== undefined && x !== false && x !== '';
      }
    )?.length;

    this.filtersCount = filterCount;
  }

  private _filtersCountSubscription: Subscription;

  ngAfterViewInit() {
    this._getFiltersCount();

    this._filtersCountSubscription = this.formGroup.valueChanges.pipe(debounceTime(50)).subscribe(() => {
      this._getFiltersCount();
    })
  }

  ngOnDestroy() {
    if (this._filtersCountSubscription) {
      this._filtersCountSubscription.unsubscribe();
    }
  }

}
