import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { UserService } from 'src/app/_services/user.service';
import { IUserProfileCard, IUserAssistantDto } from 'src/app/models';
import { IPhoneCode } from 'src/app/components/_partials/lxm-settings/users-table/users-table.component';
import { ActivatedRoute } from '@angular/router';
import { LxmMessage } from 'src/app/_helpers';
import { finalize } from 'rxjs/operators';
import { AuthenticationService, LocaleService } from 'src/app/_services';

@Component({
  selector: "activate-account-card",
  templateUrl: './activate-account.card.html',
  styleUrls: ['./activate-account.card.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ActivateAccountCardComponent {

  public saveLoading = false;

  public phoneCodes: IPhoneCode[];
  public assistantsOptions: IUserAssistantDto[] = [];
  public profileData: FormGroup;

  public activationRequired = true;
  public hasPassword: boolean;

  public imageSource: string;

  constructor(
    route: ActivatedRoute,
    private _message: LxmMessage,
    private _userService: UserService,
    private _authService: AuthenticationService
  ) {
    const card = route.snapshot.data.userProfileCard as IUserProfileCard;
    const { formData, userInfo } = card;

    // this.assistantsOptions = this._formatAssistantsOptions(formData.assistants);

    this.phoneCodes = formData.phoneCodes;
    this.hasPassword = userInfo.hasPassword;

    this._getImageSource(userInfo.companyLogo, userInfo.companyLogoContentType);

    this.profileData = new FormGroup({
      firstName: new FormControl(userInfo.firstName),
      lastName: new FormControl(userInfo.lastName),
      userName: new FormControl(userInfo.userName),
      idCode: new FormControl(userInfo.idCode),
      email: new FormControl(userInfo.email),
      phoneCode: new FormControl(userInfo.phoneCode?.id),
      phoneNumberShort: new FormControl(userInfo.phoneNumberShort),
      roles: new FormControl(userInfo.roles),
      jobTitle: new FormControl(userInfo.jobTitle),
      // portfolioDescription: new FormControl(userInfo.portfolioDescription),
      // assistants: new FormControl(this._formatAssistantsOptions(userInfo.assistants)),

      newPassword: new FormControl(),
      newPasswordConfirm: new FormControl()
    });
  }

  public activate() {
    this.saveLoading = true;
    const f = this.profileData.value;
    const data: any = {
      firstName: f.firstName,
      lastName: f.lastName,
      userName: f.userName,
      idCode: f.idCode,
      email: f.email,
      phoneCode: f.phoneCode,
      phoneNumberShort: f.phoneNumberShort,
      jobTitle: f.jobTitle,
      // portfolioDescription: f.portfolioDescription,
      // assistantIds: f.assistants.map(x => x.id),
      newPassword: f.newPassword,
      newPasswordConfirm: f.newPasswordConfirm
    };
    this._userService.activate(data)
      .pipe(
        finalize(() => {
          this.saveLoading = false;
        })
      )
      .result(this.profileData, (res) => {
        this._message.ok({
          message: 'cards.user_settings.profile.profile_saved'
        });

        this._authService.switchTenant(this._authService.currentTenantId.value);

      }, (err) => {
        this._message.error({
          message: 'cards.user_settings.profile.profile_save_failed'
        });
      });
  }

  // private _formatAssistantsOptions(options: any[]) {
  //   if (!options) {
  //     return [];
  //   }
  //   return options.map(x => Object.assign({}, x, { label: x.fullName ? `${x.fullName} (${x.email})` : x.email }));
  // }


  private _getImageSource(logo: string, contentType: string): void  {
    if (!logo || !contentType) {
      this.imageSource = null;
    } else {
      this.imageSource = `data:${contentType};base64,${logo}`;
    }
  }
}
