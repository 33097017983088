<div class="flex flex-wrap w-full gap-2">
  <div class="notification-wrapper">
    <div class="notification-header">
      <span>{{getNotificationHeader(data)}}</span>
    </div>
    <div
      class="notification-subject"
      [innerHTML]="getNotificationSubject(data)"
    ></div>
    <div
      class="notification-preview"
      [innerHTML]="getNotificationPreview(data)"
    ></div>
  </div>
  <div class="notification-time md:justify-end">
    <time-diff date="{{data.createdAt}}"></time-diff>
  </div>
</div>
