import { Component, Inject } from "@angular/core";
import { FormGroup, FormControl } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { LxmMessage, LocalStorage } from "src/app/_helpers";
import { ActionHelper } from "src/app/_helpers/action";
import { ProductService } from "src/app/_services";
import { appSettings } from "src/app/app.settings";
import { LOCAL_STORAGE_KEY } from "src/app/config";
import { ConformityDeclarationType } from "src/app/enum";

@Component({
  selector: "conformity-declaration-dialog",
  host: { class: "dialog" },
  templateUrl: "./conformity-declaration.dialog.html"
})
export class ConformityDeclarationDialog {
  public formGroup: FormGroup;

  public languageOptions = appSettings.languages;
  public selectedProductIds = [];

  public actionLoading = {} as any;

  public ConformityDeclarationType = ConformityDeclarationType;

  public withResponse = false;
  public contactOptions = [];

  constructor(
    public dialogRef: MatDialogRef<ConformityDeclarationDialog>,
    private _productService: ProductService,
    private _msg: LxmMessage,
    private _action: ActionHelper,
    @Inject(MAT_DIALOG_DATA) public data
  ) {
    const { productIds, contactOptions, withResponse } = this.data || {};
    this.selectedProductIds = productIds;
    this.withResponse = withResponse;
    this.contactOptions = contactOptions;

    const savedLanguageId = LocalStorage.getValue(
      LOCAL_STORAGE_KEY.CONFORMITY_DECLARATION_LANGUAGE
    );

    const initialLanguage =
      this.languageOptions.find((x) => x.id === savedLanguageId) ||
      this.languageOptions[0];

    this.formGroup = new FormGroup({
      declarationType: new FormControl(
        LocalStorage.getValue(LOCAL_STORAGE_KEY.CONFORMITY_DECLARATION_TYPE) ??
        ConformityDeclarationType.Excel
      ),
      language: new FormControl(initialLanguage),
      includeProductExpiration: new FormControl(
        LocalStorage.getValue(
          LOCAL_STORAGE_KEY.CONFORMITY_DECLARATION_INCLUDE_EXPIRATION
        ) ?? false
      ),
      recipients: new FormControl()
    });

    console.log(
      LocalStorage.getValue(LOCAL_STORAGE_KEY.CONFORMITY_DECLARATION_TYPE)
    );
  }

  public formatOptions = [
    { name: "Excel", id: ConformityDeclarationType.Excel, icon: "excel" },
    { name: "PDF", id: ConformityDeclarationType.Pdf, icon: "pdf" }
  ];

  public saveLocalValue(value: any, formKey: string) {
    switch (formKey) {
      case "declarationType":
        LocalStorage.set(
          LOCAL_STORAGE_KEY.CONFORMITY_DECLARATION_TYPE,
          value.id
        );
        break;
      case "language":
        LocalStorage.set(
          LOCAL_STORAGE_KEY.CONFORMITY_DECLARATION_LANGUAGE,
          value.id
        );
        break;
      case "includeProductExpiration":
        LocalStorage.set(
          LOCAL_STORAGE_KEY.CONFORMITY_DECLARATION_INCLUDE_EXPIRATION,
          value.checked
        );
        break;
    }
  }

  public save() {
    const form = this.formGroup.value;

    const req = {
      declarationType: form.declarationType,
      language: form.language?.value,
      includeProductExpiration: form.includeProductExpiration,
      productIds: this.selectedProductIds
    };

    if (req.declarationType === ConformityDeclarationType.Pdf) {
      Object.assign(req, {
        recipients: form.recipients?.map((x) => x.email) ?? []
      });
    }

    const msg = this._action.createMessage(
      "cards.products.conformity_declaration_dialog.save_success",
      "global.generic_error"
    );

    if (this.data.withResponse) {
      // action for product card declaration create with declaration dto as result
      this._productService
        .addConformityDeclaration(this.selectedProductIds[0], req)
        .result(
          this.formGroup,
          (res) => {
            this.closeDialog(res);
          },
          (e) => {
            console.error(e);
          },
          "save",
          this.actionLoading,
          msg
        );
    } else {
      // action for products list dialog - without result
      this._productService.createConformityDeclaration(req).result(
        this.formGroup,
        (res) => {
          this.closeDialog();
        },
        (e) => {
          console.error(e);
        },
        "save",
        this.actionLoading,
        msg
      );
    }
  }

  public onNoClick(): void {
    this.dialogRef.close();
  }

  public closeDialog(res = null) {
    this.dialogRef.close(res);
  }
}
