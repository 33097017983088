import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { IProductExtraParametersSettings } from "../models/settings/product-fields-settings";

@Injectable({ providedIn: "root" })
export class SettingsService {
  constructor(private _http: HttpClient) {}

  public saveCompanyProfile(req: any) {
    return this._http.post<any>(`api/settings/companyProfile`, req);
  }

  public updatePackage(req: any) {
    return this._http.post<any>(`api/settings/updatePlan`, req);
  }

  public cancelSubscription() {
    return this._http.post<any>(`api/settings/cancelSubscription`, {});
  }

  public saveSmtpSettings(req: any) {
    return this._http.post<any>(`api/settings/smtpSettings`, req);
  }

  public testSmtpSettings(req: any) {
    return this._http.post<any>(`api/settings/testSmtpSettings`, req);
  }

  public getProductExtraParametersSettings() {
    return this._http.get<IProductExtraParametersSettings>(
      `api/settings/productFields/extraParameters`
    );
  }

  public saveProductExtraParametersSettings(req: any) {
    return this._http.post<any>(
      `api/settings/productFields/extraParameters`,
      req
    );
  }

  public getSettingsFormdata() {
    return this._http.get<any>("api/settings/formdata");
  }
}
