import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LxmAppModule, UserAction } from 'src/app/enum';
import { AppState } from 'src/app/state/app.state';
import { AuthenticationService } from 'src/app/_services';
import { PipedriveService } from 'src/app/_services/pipedrive.service';
import { TitleService } from 'src/app/_services/title.service';

@Component({
  selector: 'dashboard-layout',
  templateUrl: './dashboard.layout.html',
  host: { 'class': 'content content-responsive' },
  styleUrls: ['./dashboard.layout.scss'],
  providers: [PipedriveService]
})
export class DashboardLayout {
  title = 'navigator.links.dashboard';

  public LxmAppModule = LxmAppModule;
  public UserAction = UserAction;
  
  public dashboardData: IDashboardData;

  constructor(
    private _titleService: TitleService, 
    private route: ActivatedRoute,
    private _pipedriveService: PipedriveService,
    private _authservice: AuthenticationService,
    public appState: AppState) {
    const { primary, notifications } = route?.snapshot?.data?.dashboardData;
    const data = {
      ...primary,
      notifications
    }
    this.dashboardData = data || {};
  }

  public ngOnInit() {
    this._titleService.setTitle(this.title);

    if (this._authservice.hasModule(LxmAppModule.SellerPimExtensions)) {
      this._pipedriveService.appendLeadbooster('95c84cfa-5dc7-4f01-8591-d72bc2c73c6f');
    }

  }

  ngOnDestroy() {
    this._pipedriveService.removeLeadbooster();
  }

}

interface IDashboardData {
  welcomeCardInfo: IWelcomeCardInfo;
  offersStats: any;
}

interface IWelcomeCardInfo {
  companyName: string;
  email: string;
  fullName: string;
  phoneNumber: string;
  roles: string[];
  logo: any;
}