<div class="grid w-full grid-cols-1 xl:grid-cols-2 gap-inherit">
  <div class="flex flex-col gap-inherit">
    <welcome-card [data]="dashboardData?.welcomeCardInfo"></welcome-card>

    <ng-container *nonVisitor>
      <dashboard-offers-card
        [data]="dashboardData?.retailerOffersStats"
        *ngIf="appState.hasModule(LxmAppModule.ReceiveOffers) && appState.hasRight([UserAction.ViewReceivedOffers])"
        type="in"
        [title]="'cards.dashboard_offers.title_inbound' | translate"
        link="/offers/receiving"
      ></dashboard-offers-card>
      <dashboard-offers-card
        [data]="dashboardData?.supplierOffersStats"
        *ngIf="appState.hasModule(LxmAppModule.SendOffers) && appState.hasRight([UserAction.ViewSupplierOffers])"
        type="out"
        [title]="'cards.dashboard_offers.title_outbound' | translate"
        link="/offers/sending"
      ></dashboard-offers-card>
    </ng-container>
  </div>
  <div class="flex flex-col gap-inherit">
    <ng-container *nonVisitor>
      <dashboard-notifications-card
        [data]="dashboardData?.notifications"
      ></dashboard-notifications-card>
      <dashboard-calendar-card
        *ngIf="appState.hasRight([UserAction.ManageOfferCalendar, UserAction.ViewPublishedOffersCalendar])"
        [data]="dashboardData?.marketEntries"
      ></dashboard-calendar-card>
    </ng-container>
    <ng-container *visitor>
      <card style="flex: 0;">
        <span header-title
          >{{'cards.dashboard_visitor.title' | translate}}</span
        >
        <section body>
          <quill-view-html
            class="w-full"
            [content]="'cards.dashboard_visitor.text' | translate"
          ></quill-view-html>
        </section>
      </card>
    </ng-container>
  </div>
</div>
