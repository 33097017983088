<card-expanding>
  <span header-title>{{ 'cards.clients.expiry_rules.title' | translate}}</span>
  <div header-actions>
    <lxm-button *ngIf="canEdit"
      type="beige" 
      icon="plus-dark"
      label="cards.clients.expiry_rules.action.add_rule" 
      (click)="openDialog()">
    </lxm-button>
  </div>

  <div body [ngSwitch]="hasRules">
    <div *ngSwitchCase="true">
      <div class="rules-section">
        <span class="rules-section_header">
          {{ 'cards.clients.expiry_rules.active_rules' | translate}} ({{expiryRules?.length || 0}})
        </span>
        <div class="rules-section_body">
          <expiry-rule-row 
          *ngFor="let rule of expiryRules | slice:0:4"
          (click)="openDialog(rule)"
          [data]="rule">
        </expiry-rule-row >
          <ng-container *ngIf="showAllRules">
            <expiry-rule-row
            *ngFor="let rule of expiryRules | slice:4:1000"
            (click)="openDialog(rule)"
            [data]="rule">
          </expiry-rule-row>
          </ng-container>
          <div *ngIf="expiryRules?.length > 4" class="toggle-show-all-rules" (click)="toggleShowAllRules()">
            {{(showAllRules ? 
              'cards.clients.expiry_rules.action.show_less' : 
              'cards.clients.expiry_rules.action.show_all') | translate
            }}
          </div>
        </div>
      </div>
    </div>

    <no-content 
      *ngSwitchCase="false" 
      heading="cards.clients.expiry_rules.no_rules_heading"
      text="cards.clients.expiry_rules.no_rules_text"
      image="no-expiry-rules">
    </no-content>

  </div>

</card-expanding>