<ng-container [formGroup]="formGroup" [ngSwitch]="multiple">

  <!-- Standard select / Autocomplete  -->
  <ng-container *ngSwitchDefault>

    <ng-select #select [class.autocomplete]="url" [class.has-value]="hasValue" [class.underlabel]="underLabel"
      [formControlName]="for" [searchFn]="search" appendTo="app-root" [loading]="loading"
      [typeahead]="url ? acSubject : null" [items]="url ? options : finalizedOptions" [bindValue]="value"
      [bindLabel]="labelField" [searchable]="readonly !== true && (searchable || url)"
      [addTag]="addTag" [addTagText]="addTagText" [maxSelectedItems]="maxSelectedItems" [clearSearchOnAdd]="true"
      [hideSelected]="hideSelected" [placeholder]="placeholder | translate" [class.disabled]="disabled"
      [groupBy]="groupBy" [virtualScroll]="virtualScroll" [clearable]="clearable" [minTermLength]="minTermLength"
      [typeToSearchText]="typeToSearchTranslated" [notFoundText]="notFoundTranslated" [loadingText]="loadingTranslated"
      (search)="find($event)" (open)="onOpen($event)" (close)="onClose($event)" (change)="onChange($event)" [dropdownPosition]="dropdownPosition" 
      [closeOnSelect]="closeOnSelect">

      <ng-template ng-header-tmp>
        <ng-container *ngTemplateOutlet="headerTemplate"></ng-container>
      </ng-template>

      <ng-template ng-label-tmp let-item="item" let-clear="clear">
        <div [class]="'ng-value-wrapper default ' + getItemClass(item)">
          <span class="ng-value-label">
            <span class="flex flex-row align-center">
              <svg-icon *ngIf="labelIconField" class="mr10 {{labelIconClass}}" [key]="getItemIcon(item)" fontSize="16px"></svg-icon>
              <span class="lxm-select-label-value">{{getItemValue(item) | translate}}</span>
              <span class="pro-icon ml4" *ngIf="item.pro"></span>
            </span>
            <div *ngIf="underLabel" class="ng-value-label_under-label">
              <small>{{underLabel | translate}}</small>
            </div>
          </span>
          <small *ngIf="additionalField">
            {{getAdditionalValue(item)}}
          </small>
          <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
        </div>
      </ng-template>

      <ng-template ng-option-tmp let-item="item">
        <div [class]="'ng-value-wrapper default ' + getItemClass(item)">
          <span class="ng-value-label">
            <span class="flex flex-row align-center">
                <svg-icon *ngIf="labelIconField" class="mr10 {{labelIconClass}}" [key]="getItemIcon(item)" fontSize="16px"></svg-icon>
                <span class="inline-flex flex-row align-center">
                  {{getItemValue(item)}}
                <span class="pro-icon ml4" *ngIf="item.pro"></span>
              </span>
            </span>
          </span>
          <small *ngIf="additionalField">
            {{getAdditionalValue(item)}}
          </small>

        </div>
 
      </ng-template>
      

    </ng-select>

  </ng-container>

  <!-- Multiselect -->
  <ng-container *ngSwitchCase="true">
    <ng-select #select [class.autocomplete]="url" [class.has-value]="hasValue" [class.underlabel]="underLabel"
      [formControlName]="for" [searchFn]="search" appendTo="app-root" [loading]="loading"
      [typeahead]="url ? acSubject : null" [items]="url ? options : finalizedOptions" [bindValue]="value"
      [bindLabel]="labelField" [searchable]="readonly !== true && (searchable || url)" [multiple]="true"
      [addTag]="addTag" [addTagText]="addTagText" [maxSelectedItems]="maxSelectedItems"
      [closeOnSelect]="!multiple" [clearSearchOnAdd]="true" [hideSelected]="hideSelected"
      [placeholder]="placeholder | translate" [class.disabled]="disabled" [groupBy]="groupBy"
      [virtualScroll]="virtualScroll" [clearable]="clearable" [minTermLength]="minTermLength"
      [typeToSearchText]="typeToSearchTranslated" [notFoundText]="notFoundTranslated" [loadingText]="loadingTranslated"
      (search)="find($event)" (open)="onOpen($event)" (close)="onClose($event)" (change)="onChange($event)">

      <ng-template ng-header-tmp>
        <ng-container *ngTemplateOutlet="headerTemplate"></ng-container>
      </ng-template>

      <ng-template ng-label-tmp let-item="item" let-clear="clear">
        <div [class]="'ng-value-wrapper default ' + getItemClass(item)">
          <span class="ng-value-label">
            <span class="flex flex-row align-center">
              <svg-icon *ngIf="labelIconField" class="mr10 {{labelIconClass}}" [key]="getItemIcon(item)" fontSize="16px"></svg-icon>
              <span class="lxm-select-label-value">{{getItemValue(item) | translate}}</span>
              <span class="pro-icon ml4" *ngIf="item.pro"></span>
            </span>
            <div *ngIf="underLabel" class="ng-value-label_under-label">
              <small>{{underLabel | translate}}</small>
            </div>
            <small *ngIf="additionalField">{{getAdditionalValue(item)}}</small>
          </span>
          <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
        </div>
      </ng-template>

      <ng-template ng-option-tmp let-item="item" let-item$="item$">
        <mat-checkbox class="multi-checkbox" [checked]="item$.selected" [class.has-additional-field]="additionalField">
          <svg-icon *ngIf="labelIconField" class="mr10 {{labelIconClass}}" [key]="getItemIcon(item)" fontSize="16px"></svg-icon>
          <span class="inline-flex flex-row align-center">
            {{getItemValue(item)}}
            <span class="pro-icon ml4" *ngIf="item.pro"></span>
          </span>
          <small *ngIf="additionalField">
            <br />
            {{getAdditionalValue(item)}}
          </small>
        </mat-checkbox>
      </ng-template>

    </ng-select>

  </ng-container>

  <span class="lxm-select-default">-</span>
  <lxm-overlay *ngIf="overlay" name="select-overlay" (click)="select.close()"></lxm-overlay>

</ng-container>

<ng-content></ng-content>

<ng-template #headerTemplate>
  <div class="header-tmp-wrapper" (click)="select.close()">
    <div class="row" *ngIf="selectAllOption">
      <button class="select-select-all" (click)="onSelectAll()">
        {{'components.select.select_all' | translate}}
      </button>
      <button class="select-clear-all" (click)="onClearAll()">
        {{'components.select.clear_all' | translate}}
      </button>
    </div>
    <ng-content select="[header]"></ng-content>
  </div>
</ng-template>