import { Component, OnInit, Injector } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { ProductCardBase } from "../product-card-base";
import {
  IProductExpirationInfo,
  IExpiryTarget,
  IClvDto
} from "../product-info/product-info.model";
import { Observable } from "rxjs";
import { formUtil, keep } from "src/app/util/form-util";
import {
  UserAction,
  ProductCardSection,
  LxmAppModule,
  ExpiryTargetType
} from "src/app/enum";
import { ProductValidity } from "src/app/_services";
import { AppState } from "src/app/state/app.state";
import { LOCAL_STORAGE_KEY } from "src/app/config";
import { ProductHeaderState } from "../product-header/product-header.state";

@Component({
  selector: "product-expiry-card",
  templateUrl: "./product-expiry.card.html",
  styleUrls: ["./product-expiry.card.scss"]
})
export class ProductExpiryCard extends ProductCardBase<IProductExpirationInfo> {
  public UserAction = UserAction;
  public LxmAppModule = LxmAppModule;

  public get title() {
    return "cards.products.product_storage_info.title";
  }

  public cardSection = ProductCardSection.ExpiryInfo;

  public storageConditionsOptions: IClvDto[];
  public shelfLifeMarkingOptions: IClvDto[];
  public expiryTargets: IExpiryTarget[];
  public storageInfoOptions: IClvDto[];

  public hasBuyerPim: boolean;
  public hasSellerPim: boolean;

  public showExpirationsTitles: boolean;

  public isExpandedKey = LOCAL_STORAGE_KEY.PRODUCT_EXPIRY_CARD_OPEN;

  constructor(
    injector: Injector,
    public productValidity: ProductValidity,
    public appState: AppState,
    private _productHeaderState: ProductHeaderState
  ) {
    super(injector, "expirationInfo", "productExpiryInfoChanged");

    this.storageConditionsOptions = this._productCard.formData.storageConditions;
    this.shelfLifeMarkingOptions = this._productCard.formData.shelfLifeMarkings;
    this.expiryTargets = this._productCard.formData.expiryTargets;
    this.storageInfoOptions = this._productCard.formData.storageInfo;

    this.hasBuyerPim =
      appState.hasModule(LxmAppModule.BuyerPimExtensions) &&
      this._productCard.isOwnProduct;
    this.hasSellerPim = appState.hasModule(LxmAppModule.SellerPimExtensions);

    this.showExpirationsTitles = this.hasBuyerPim && this.hasSellerPim;
  }

  protected get successMessage(): string {
    return "cards.products.product_storage_info.save_successful";
  }

  protected _saveInternal(req: any): Observable<any> {
    return this._productService.saveExpirationInfo(this.productId, req);
  }

  protected _getNormalizedFormData(data: IProductExpirationInfo) {
    return formUtil.transformData(data, {
      paoMarkingInMonths: keep,
      shelfLifeAfterOpeningInDays: keep,
      storageConditionsId: () => data.storageConditions?.id,
      shelfLifeMarkingId: () => data.shelfLifeMarking?.id,
      storageTemperatureControlRequired: keep,
      storageMinTemp: keep,
      storageMaxTemp: keep,
      storageInfoIds: () => data.storageInfo?.map(x => x.id),
      locationExpirations: val => val || [],
      retailerExpirations: val => val || []
    });
  }

  protected _createFormGroup(data: IProductExpirationInfo): FormGroup {
    return formUtil.createFormGroup(this._getNormalizedFormData(data));
  }

  // public get isChilled() {
  //   const chilledIds = [
  //     "862d0c3f-ce13-4fa8-baac-88ea50e2ff8b",
  //     "534cb80e-a869-4e9f-b7cd-de89ff05d3df",
  //     "bd4eaf8e-f849-4803-83c8-6e875824c0e4",
  //     "6c860954-8e8e-4172-a404-59dc762b589a"
  //   ];
  //   const storageConditionId = this.form.get("storageConditionsId").value;
  //   return chilledIds.includes(storageConditionId);
  // }

  // public get isFrozen() {
  //   const frozenId = "10402d42-aacc-44a9-bbe2-ee0a10ea9816";
  //   const storageConditionId = this.form.get("storageConditionsId").value;
  //   return storageConditionId === frozenId;
  // }

  protected _afterSaved(req) {
    const { storageConditionsId } = req;
    this._productHeaderState.setData({
      storageConditionId: storageConditionsId
    });
  }

  protected _createSaveRequest() {
    const form = this.form.value;

    let retailerExpirations = this.hasSellerPim
      ? form.retailerExpirations
        ?.filter((x: any) => !x.isDeleted)
        .map((x: any) => ({
          id: x.id,
          type: x.target?.type,
          retailChainId: x.target?.retailerId,
          expiryInDays: x.expiryInDays,
          expiryForRetailerInDays: x.expiryForRetailerInDays
        }))
      : [];

    let locationExpirations = this.hasBuyerPim
      ? form.locationExpirations
        ?.filter((x: any) => !x.isDeleted)
        .map((x: any) => ({
          id: x.id,
          expiryInDays: x.expiryInDays,
          expiryForRetailerInDays: x.expiryForRetailerInDays,
          minExpiryOnDelivery: x.minExpiryOnDelivery
        }))
      : [];

    return {
      paoMarkingInMonths: form.paoMarkingInMonths,
      storageConditionsId: form.storageConditionsId,
      shelfLifeMarkingId: form.shelfLifeMarkingId,
      shelfLifeAfterOpeningInDays: form.shelfLifeAfterOpeningInDays,
      storageTemperatureControlRequired:
        form.storageTemperatureControlRequired || false,
      storageMinTemp: form.storageMinTemp,
      storageMaxTemp: form.storageMaxTemp,
      retailerExpirations: retailerExpirations,
      locationExpirations: locationExpirations,
      storageInfoIds: form.storageInfoIds
    };
  }
}
