<ng-container [formGroup]="form">
  <card-section>
    <span header-title style="font-size: 24px; font-weight: bold">
      {{'cards.lexi_market_settings.product_fields_settings.title' | translate
      }} >
      {{'cards.lexi_market_settings.product_fields_settings.extra_parameters_settings.title'
      | translate }}
    </span>
    <span header-actions>
        <lxm-button
          label="action.save"
          icon="save"
          type="beige"
          [loading]="saveLoading"
          (click)="save()"
        ></lxm-button>
    </span>

    <div body class="row">
      <info-array
        class="mt20 mb20"
        prelude="cards.lexi_market_settings.product_fields_settings.extra_parameters_settings.info_text"
      ></info-array>
      <div class="col-8 no-padding">
        <ng-container
          [formGroup]="parameterFg"
          *ngFor="let parameterFg of extraParameters.controls"
        >
          <form-field
            for="name"
            labelTop="true"
            [label]="getFieldCode(parameterFg) | translateEnum: 'ProductField'"
          >
            <language-text
              language-text
              for="name"
              newline="true"
              maxlength="200"
              [fromUiLanguages]="true"
            ></language-text>
          </form-field>

          <!-- <form-field label="cards.lexi_market_settings.product_fields_settings.extra_parameters_settings.is_disabled">
              <slide-toggle toggle for="isDisabled"></slide-toggle>
            </form-field> -->

          <form-field label="global.active">
            <slide-toggle toggle for="isVisible"></slide-toggle>
          </form-field>

          <div class="separator"></div>
        </ng-container>
      </div>
    </div>
  </card-section>
</ng-container>
