var host = window.location.hostname;

class AppSettings {
  public readonly dev = true;
  public readonly version = "1.21.2";

  public readonly apiUrl = ``;
  public readonly defaultRoute = "dashboard";

  public readonly uiLanguages = [
    { id: 1, name: "EST", value: "et", locale: "et-EE", iso3: "EST" },
    { id: 2, name: "ENG", value: "en", locale: "en-GB", iso3: "GBR" },
    { id: 32, name: "TAL", value: "en", locale: "et-EE", iso3: "GBR" },
    { id: 64, name: "DEU", value: "de", locale: "de-DE", iso3: "DEU" }
    //{ name: 'RU', value: 'ru', locale: 'ru-RU' },
  ];
  public readonly defaultLang = this.uiLanguages[0];

  public readonly languages = [
    { id: 1, name: "EST", value: "et", locale: "et-EE", iso3: "EST" },
    { id: 2, name: "ENG", value: "en", locale: "en-GB", iso3: "GBR" },
    { id: 64, name: "DEU", value: "de", locale: "de-DE", iso3: "DEU" }
    //{ name: 'RU', value: 'ru', locale: 'ru-RU' },
  ];

  public readonly DATE_FORMATS = {
    parse: {
      dateInput: "LL"
    },
    display: {
      dateInput: "DD/MM",
      monthYearLabel: "MMM",
      dateA11yLabel: "MM DD",
      monthYearA11yLabel: "MMM YYYY"
    }
  };

  // dialog settings
  public readonly DIALOG_WIDTHS = {
    AUTO: "auto",
    M: "650px",
    L: "1000px"
  };

  public readonly DIALOG_HEIGHTS = {
    AUTO: "auto",
    L: "800px"
  };

  public readonly DIALOG_SIZES = {
    M: { width: this.DIALOG_WIDTHS.M, height: this.DIALOG_HEIGHTS.AUTO },
    L: { width: this.DIALOG_WIDTHS.L, height: this.DIALOG_HEIGHTS.L },
    AUTO: { width: this.DIALOG_WIDTHS.AUTO, height: this.DIALOG_HEIGHTS.AUTO }
  };

  // location settings
  public readonly iconsPath = "./assets/img/icons/";
  public readonly i18nPath = "./assets/i18n/";
}

export const appSettings = new AppSettings();
