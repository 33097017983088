<card-expanding
  [formGroup]="form"
  [valid]="productValidity.getCardStatusValidType(cardSection)"
  [expanded]="isExpanded"
  (onExpand)="saveLocalExpandedValue($event)"
>
  <span header-title>{{ title | translate}}</span>

  <div header-actions *requiredRights="[UserAction.ManageProducts]">
    <ng-container *ngIf="isOwnProduct">
      <ng-container *ngIf="!locked">
        <lxm-button
          *ngIf="!edit"
          type="beige"
          icon="edit"
          title="action.edit"
          (click)="toggleEdit()"
        ></lxm-button>
        <lxm-button
          *ngIf="edit"
          type="grey"
          icon="back"
          label="action.cancel"
          (click)="toggleEdit()"
        >
        </lxm-button>
        <lxm-button
          *ngIf="edit"
          type="beige"
          icon="save"
          label="action.save"
          [loading]="saveLoading"
          (click)="save()"
          buttonType="submit"
        ></lxm-button>
      </ng-container>
      <ng-container *ngIf="locked">
        {{ 'global.locked_by' | translate }}: {{ lockedBy.name }} {{ lockedAt |
        amTimeAgo }}
      </ng-container>
    </ng-container>
  </div>

  <div body>
    <card-section>
      <div class="row">
        <div class="col-12 no-padding flex flex-col">
          <form-field
            [readonly]="!edit"
            label="cards.products.product_additional_info_and_instructions.label.awards"
            for="awards"
            [required]="productValidity.shouldValidate ? productValidity.isRequiredByRetailer(cardSection, productField.Awards, form.get('awards').value) : null"
            description="cards.products.product_additional_info_and_instructions.label_description.awards"
          >
            <lxm-select
              select
              class="w100p"
              [readonly]="!edit"
              [options]="awardsOptions"
              labelField="value"
              [searchable]="true"
              [multiple]="true"
              compareField="id"
              for="awards"
            >
              <lxm-button
                *requiredRights="[UserAction.ManageClvs]"
                header
                label="action.add_new"
                icon="plus-dark"
                (click)="openClassificatorValueDialog(ClvType.Award, form.get('awards'), 'awardsOptions', true)"
              >
              </lxm-button>
            </lxm-select>
            <validation-errors for="awards"></validation-errors>
          </form-field>

          <form-field
            [readonly]="!edit"
            label="cards.products.product_additional_info_and_instructions.label.food_safety_recommendation"
            description="cards.products.product_additional_info_and_instructions.label_description.food_safety_recommendation"
            for="foodSafetyRecommendationId"
            [required]="productValidity.shouldValidate ? productValidity.isRequiredByRetailer(cardSection, productField.FoodSafetyRecommendation, form.get('foodSafetyRecommendationId').value) : null"
          >
            <lxm-select
              select
              for="foodSafetyRecommendationId"
              class="w100p"
              [options]="foodSafetyRecommendationsOptions"
              [readonly]="!edit"
              [searchable]="true"
              value="id"
              labelField="translatedValue"
              [isTranslated]="true"
              [sortByLabel]="true"
            >
            </lxm-select>
            <validation-errors
              for="foodSafetyRecommendationId"
            ></validation-errors>
          </form-field>

          <form-field
            [readonly]="!edit"
            labelTop="true"
            label="cards.products.product_additional_info_and_instructions.label.description"
            for="description"
            description="cards.products.product_additional_info_and_instructions.label_description.description"
            [required]="productValidity.shouldValidate ? productValidity.isRequiredByRetailerLanguageText(productField.Description) : null"
          >
            <language-text
              language-text
              for="description"
              [newline]="true"
              [readonly]="!edit"
              [required]="productValidity.shouldValidate ? productValidity.getLanguageTextRequiredTypes(productField.Description) : null"
            ></language-text>
          </form-field>

          <form-field
            [readonly]="!edit"
            labelTop="true"
            label="cards.products.product_additional_info_and_instructions.label.instructions"
            for="instructionsForUse"
            description="cards.products.product_additional_info_and_instructions.label_description.instructions"
            [required]="productValidity.shouldValidate ? productValidity.isRequiredByRetailerLanguageText(productField.InstructionsForUse) : null"
          >
            <language-text
              language-text
              for="instructionsForUse"
              [newline]="true"
              [readonly]="!edit"
              [required]="productValidity.shouldValidate ? productValidity.getLanguageTextRequiredTypes(productField.InstructionsForUse) : null"
            ></language-text>
          </form-field>

          <form-field
            [readonly]="!edit"
            labelTop="true"
            label="cards.products.product_additional_info_and_instructions.label.warnings"
            for="warnings"
            description="cards.products.product_additional_info_and_instructions.label_description.warnings"
            [required]="productValidity.shouldValidate ? productValidity.isRequiredByRetailerLanguageText(productField.Warnings) : null"
          >
            <language-text
              language-text
              for="warnings"
              [newline]="true"
              [readonly]="!edit"
              [maxlength]="2000"
              [required]="productValidity.shouldValidate ? productValidity.getLanguageTextRequiredTypes(productField.Warnings) : null"
            ></language-text>
          </form-field>

          <form-field
            [readonly]="!edit"
            labelTop="true"
            label="cards.products.product_additional_info_and_instructions.label.technical_name"
            for="technicalName"
            description="cards.products.product_additional_info_and_instructions.label_description.technical_name"
            [required]="productValidity.shouldValidate ? productValidity.isRequiredByRetailerLanguageText(productField.TechnicalName) : null"
          >
            <language-text
              language-text
              for="technicalName"
              [newline]="true"
              [readonly]="!edit"
              [maxlength]="2000"
              [required]="productValidity.shouldValidate ? productValidity.getLanguageTextRequiredTypes(productField.TechnicalName) : null"
            ></language-text>
          </form-field>
        </div>
      </div>
    </card-section>

    <div class="separator mt20 mb20"></div>

    <div class="flex flex-col gap-2">
      <card-section *ngIf="canCreateConformityDeclaration">
        <span header-title>
          {{'cards.products.product_additional_info_and_instructions.label.conformity_declarations'
          | translate}}
        </span>
        <div header-actions>
          <lxm-button
            type="beige"
            label="action.add_new"
            icon="plus-dark"
            (click)="openConformityDeclarationDialog()"
          >
          </lxm-button>
        </div>

        <!-- (open)="openAddFileDialog($event)" -->
        <declarations-list
          body
          #declarationsList
          [data]="conformityDeclarations"
          [displayHeadersWhenEmpty]="false"
          (download)="downloadConformityDeclaration($event)"
          (remove)="removeConformityDeclaration($event)"
          [readonly]="!canEdit"
          [actionLoading]="actionLoading"
        ></declarations-list>
      </card-section>

      <card-section>
        <span header-title>
          {{'cards.products.product_additional_info_and_instructions.label.attached_links'
          | translate}}
        </span>
        <span header-actions *requiredRights="[UserAction.ManageProducts]">
          <ng-container *ngIf="isOwnProduct">
            <lxm-button
              type="beige"
              label="cards.products.product_additional_info_and_instructions.actions.add_link"
              icon="plus-dark"
              (click)="openAddLinkDialog()"
            >
            </lxm-button>
          </ng-container>
        </span>

        <links-list
          body
          #linksList
          [data]="links"
          [linkTypeOptions]="linkTypeOptions"
          (open)="openAddLinkDialog($event)"
          (remove)="removeLink($event)"
          [readonly]="!canEdit"
        >
        </links-list>
      </card-section>

      <card-section>
        <span header-title>
          {{'cards.products.product_additional_info_and_instructions.label.attached_files'
          | translate}}
        </span>
        <span header-actions *requiredRights="[UserAction.ManageProducts]">
          <ng-container *ngIf="isOwnProduct">
            <lxm-button
              type="beige"
              label="cards.products.product_additional_info_and_instructions.actions.add_file"
              icon="plus-dark"
              (click)="openAddFileDialog()"
            >
            </lxm-button>
          </ng-container>
        </span>

        <files-list
          body
          #filesList
          [data]="files"
          [displayHeadersWhenEmpty]="false"
          (open)="openAddFileDialog($event)"
          (download)="download($event)"
          (remove)="removeAttachment($event)"
          [readonly]="!canEdit"
        ></files-list>
      </card-section>
    </div>


  </div>
</card-expanding>
