import { Component, OnInit, Input, Injector } from "@angular/core";
import { FormGroup, FormControl } from "@angular/forms";
import { ProductCardBase } from "../product-card-base";
import {
  IProductCardAlcoholInfo,
  IClvDto
} from "../product-info/product-info.model";
import { Observable } from "rxjs";
import {
  UserAction,
  asArray,
  WineColor,
  ProductCardSection
} from "src/app/enum";
import { formUtil, keep } from "src/app/util/form-util";
import { ProductValidity } from "src/app/_services";
import moment from "moment";
import { LOCAL_STORAGE_KEY } from "src/app/config";
import { ProductHeaderState } from "../product-header/product-header.state";

@Component({
  selector: "product-alcohol-card",
  templateUrl: "./product-alcohol.card.html",
  styleUrls: ["./product-alcohol.card.scss"]
})
export class ProductAlcoholCard extends ProductCardBase<
  IProductCardAlcoholInfo
> {
  public UserAction = UserAction;

  public get title() {
    return "cards.products.product_alcohol.title";
  }

  public cardSection = ProductCardSection.AlcoholInfo;

  public wineFoodSuggestionsOptions: any[];
  public wineTastesOptions: any[];
  public wineColorsOptions: any[];
  public wineCharacteristicsOptions: any[];
  public wineIntensitiesOptions: any[];
  public alcoholTypesOptions: any[];
  public selectedWineGrapes: any[];
  public wineryOptions: IClvDto[];
  public wineRegionOfOriginOptions: IClvDto[];

  public isExpandedKey = LOCAL_STORAGE_KEY.PRODUCT_ALCOHOL_CARD_OPEN;

  constructor(
    injector: Injector,
    public productValidity: ProductValidity,
    private _productHeaderState: ProductHeaderState
  ) {
    super(injector, "alcoholInfo", "productAlcoholInfoChanged");

    this.wineFoodSuggestionsOptions = this._productCard.formData.wineFoodSuggestions;
    this.wineTastesOptions = this._productCard.formData.wineTastes;
    this.wineColorsOptions = this._productCard.formData.wineColors;
    this.wineCharacteristicsOptions = this._productCard.formData.wineCharacteristics;
    this.wineIntensitiesOptions = this._productCard.formData.wineIntensities;
    this.alcoholTypesOptions = this._productCard.formData.alcoholTypes;
    this.wineryOptions = this._productCard.formData.wineries;
    this.wineRegionOfOriginOptions = this._productCard.formData.wineRegionsOfOrigin;
  }

  protected _getNormalizedFormData(data: IProductCardAlcoholInfo) {
    this.selectedWineGrapes = data.wineGrapes;
    const res = formUtil.transformData(data, {
      alcoholTypeId: () => data.alcoholType?.id,
      alcoholContent: keep,
      licenseNumber: keep,
      licenseDate: () =>
        data.licenseDate ? moment.parseZone(data.licenseDate) : null,
      countryOfOrigin: keep,
      winery: keep,
      wineRegion: keep,
      wineGrapes: keep,
      wineColorId: () => data.wineColor?.id,
      wineCharacteristicsId: () => data.wineCharacteristics?.id,
      wineIntensityId: () => data.wineIntensity?.id,
      wineTasteId: () => data.wineTaste?.id,
      servingTemperature: keep,
      wineFoodSuggestions: val => val?.map(x => x.id),
      description: keep,
      wineCharacteristics: undefined,
      wineIntensity: undefined,
      wineTaste: undefined
    });

    return res;
  }

  protected _createFormGroup(data: IProductCardAlcoholInfo): FormGroup {
    return formUtil.createFormGroup(this._getNormalizedFormData(data));
  }

  protected _createSaveRequest() {
    const form = this.form.value;
    return {
      alcoholTypeId: form.alcoholTypeId,
      alcoholContent: form.alcoholContent,
      licenseNumber: form.licenseNumber,
      licenseDate: form.licenseDate,
      countryOfOriginId: form.countryOfOrigin?.id,
      wineryId: form.winery?.id,
      regionOfOriginId: form.wineRegion?.id,
      wineGrapes: form.wineGrapes?.map(x => x.id),
      wineColorId: form.wineColorId,
      wineCharacteristicsId: form.wineCharacteristicsId,
      wineIntensityId: form.wineIntensityId,
      wineTasteId: form.wineTasteId,
      servingTemperature: form.servingTemperature,
      wineFoodSuggestions: form.wineFoodSuggestions,
      description: form.description
        ? Object.assign(
            {},
            ...form.description.map(o => ({ [o.language]: o.value }))
          )
        : {}
    };
  }

  protected _afterSaved(req) {
    const { wineFoodSuggestions, alcoholContent } = req || {};
    this._productHeaderState.setData({
      foodSuggestions: this.wineFoodSuggestionsOptions.filter(x =>
        wineFoodSuggestions?.includes(x.id)
      ),
      alcoholContent: alcoholContent
    });
  }

  protected _saveInternal(req: any): Observable<any> {
    return this._productService.saveAlcoholInfo(this.productId, req);
  }

  protected get successMessage(): string {
    return "cards.products.product_alcohol.save_successful";
  }
}
