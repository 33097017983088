<card>
  <span header-title>{{ title }}</span>
  <section header-actions>
    <a class="card-header-action" [routerLink]="link"
      >{{'cards.dashboard_offers.action' | translate}}</a
    >
  </section>
  <section body>
    <div
      class="card-offers-header f-0 flex flex-row flex-wrap justify-between gap-4"
    >
      <div class="f-1 align-center flex flex-row">
        <span class="card-offers-header-text"
          >{{'cards.dashboard_offers.awaiting_confirmation' | translate}}</span
        >
        <span class="card-offers-header-value ml30"
          >{{awaitingConfirmation}}</span
        >
      </div>
      <div class="f-1 align-center flex flex-row">
        <span class="card-offers-header-text"
          >{{'cards.dashboard_offers.active_today' | translate}}</span
        >
        <span class="card-offers-header-value ml30"
          >{{data?.activeCount || 0}}</span
        >
      </div>
    </div>
    <div
      class="card-offers-content mt15 flex-col"
      [formGroup]="controls"
      [ngSwitch]="hasOffers"
    >
      <ng-container *ngSwitchCase="true">
        <div class="align-center flex flex-row flex-wrap justify-between gap-4">
          <strong style="color: #5e5357">YTD</strong>
          <lxm-select
            for="offerType"
            [options]="offerTypeOptions"
            labelField="name"
            value="id"
            [clearable]="false"
            style="width: auto; min-width: 200px"
          ></lxm-select>
        </div>

        <div id="chart-container" [ngSwitch]="type">
          <div
            id="barChartContainer"
            class="mt25 w-full"
            style="min-height: 350px"
            *ngSwitchCase="'out'"
          >
            <canvas
              baseChart
              [datasets]="barChartData"
              [labels]="barChartLabels"
              [options]="barChartOptions"
              [plugins]="barChartPlugins"
              [legend]="barChartLegend"
              [chartType]="barChartType"
            >
            </canvas>
          </div>
          <div id="pieChartContainer" class="mt25 w-full" *ngSwitchCase="'in'">
            <canvas
              baseChart
              [data]="pieChartData"
              [labels]="pieChartLabels"
              [chartType]="pieChartType"
              [options]="pieChartOptions"
              [colors]="pieChartColors"
              [legend]="pieChartLegend"
            >
            </canvas>
          </div>
          <span class="chart-loader" [class.hidden]="!showLoader"></span>
        </div>
      </ng-container>

      <no-content
        text="cards.dashboard_offers.no_entries"
        image="no-graph-offers"
        iconStyle="margin-right: -15px;"
        *ngSwitchCase="false"
      >
      </no-content>
    </div>
  </section>
</card>
