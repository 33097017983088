<div class="flex w-full items-center justify-end py-5">
  <guest-language-selector></guest-language-selector>
</div>
<div class="login-content">
  <div class="login-container">
    <!-- 
      <div class="login-message-container">

        <card-message *ngIf="errors.length > 0" [messages]="errors" [absolute]="true" type="error"></card-message>
        
      </div> -->

    <div class="login-card" [class.custom-for]="customFor ? true : false">
      <div class="login-card-left">
        <img
          class="login-card-img img--{{ customFor }}"
          [src]="imageUrl"
          draggable="false"
        />
      </div>

      <div class="login-card-right" [ngSwitch]="view">
        <ng-container *ngSwitchCase="loginView">
          <div class="login-options">
            <button
              class="login-option desktop-only cursor-pointer"
              (click)="toIdLogin()"
              [class.login-tab-active]="selectedLogin === LoginType.IdCard"
              *ngIf="!state.isMobileDevice"
            >
              <span class="login-option-text">{{
                "login.options.id_card" | translate
              }}</span>
            </button>
            <button
              class="login-option cursor-pointer"
              (click)="toMobileLogin()"
              [class.login-tab-active]="selectedLogin === LoginType.MobileId"
            >
              <span class="login-option-text">{{
                "login.options.mobile_id" | translate
              }}</span>
            </button>
            <button
              class="login-option cursor-pointer"
              (click)="toPasswordLogin()"
              [class.login-tab-active]="selectedLogin === LoginType.Password"
            >
              <span class="login-option-text">{{
                "login.options.password" | translate
              }}</span>
            </button>
            <button
              class="login-option cursor-pointer"
              *ngIf="customFor"
              (click)="toAzureAdLogin()"
              [class.login-tab-active]="selectedLogin === LoginType.AzureAd"
            >
              <span class="login-option-text">{{
                "login.options.azure_ad" | translate
              }}</span>
            </button>
          </div>

          <mat-tab-group
            class="login-tab-group no-tab-header"
            animationDuration="0"
            [(selectedIndex)]="selectedLogin"
          >
            <mat-tab class="login-tab" label="ID-card">
              <form
                class="login-tab-container"
                #idCardForm="ngForm"
                (ngSubmit)="loginIdCard()"
                novalidate
              >
                <lxm-button
                  width="280px"
                  class="max-w-full"
                  type="primary"
                  buttonType="submit"
                  label="login.log_in"
                  [loading]="loginLoading"
                ></lxm-button>
              </form>
            </mat-tab>
            <mat-tab class="login-tab" label="Mobile-ID">
              <form
                class="login-tab-container mt30"
                [formGroup]="mobileIdForm"
                (ngSubmit)="loginMobileId()"
                novalidate
              >
                <lxm-input
                  class="login-input"
                  autocomplete="tel"
                  type="tel"
                  name="phoneNumber"
                  for="phoneNumber"
                  placeholder="{{
                    'login.placeholders.phone_number' | translate
                  }}"
                >
                  <validation-errors for="phoneNumber"></validation-errors>
                </lxm-input>
                <lxm-button
                  class="mt30 max-w-full"
                  width="280px"
                  type="primary"
                  buttonType="submit"
                  label="login.log_in"
                  [loading]="loginLoading"
                ></lxm-button>
                <div
                  class="mt30"
                  style="text-align: center"
                  *ngIf="mobileIdForm.verificationCode"
                >
                  <span style="font-size: 14px; color: #5e5357">{{
                    "login.mobile_id_verification_code" | translate
                  }}</span>
                  <span style="display: block; font-size: 36px">{{
                    mobileIdForm.verificationCode
                  }}</span>
                </div>
              </form>
            </mat-tab>
            <mat-tab class="login-tab" label="Password">
              <form
                class="login-tab-container mt30"
                [formGroup]="passwordForm"
                (ngSubmit)="loginPassword()"
                novalidate
              >
                <lxm-input
                  class="login-input"
                  autocomplete="email"
                  type="email"
                  name="email"
                  for="username"
                  placeholder="{{ 'login.placeholders.e_mail' | translate }}"
                >
                  <validation-errors for="username"></validation-errors>
                </lxm-input>
                <lxm-input
                  class="login-input mt12"
                  autocomplete="password"
                  type="password"
                  for="password"
                  placeholder="{{ 'login.placeholders.password' | translate }}"
                >
                  <validation-errors for="password"></validation-errors>
                </lxm-input>
                <lxm-button
                  class="mt30 max-w-full"
                  width="280px"
                  type="primary"
                  buttonType="submit"
                  label="login.log_in"
                  [loading]="loginLoading"
                ></lxm-button>
                <a
                  class="forgot-password-link mt30"
                  (click)="toResetPassword()"
                  >{{ "login.forgot_password" | translate }}</a
                >
              </form>
            </mat-tab>
            <mat-tab class="login-tab" label="AD">
              <form
                class="login-tab-container"
                #azureAdForm="ngForm"
                (ngSubmit)="loginAzureAd()"
                novalidate
              >
                <lxm-button
                  width="280px"
                  type="primary"
                  buttonType="submit"
                  label="login.log_in"
                  [loading]="loginLoading"
                  class="max-w-full"
                ></lxm-button>
              </form>
            </mat-tab>
          </mat-tab-group>
        </ng-container>

        <ng-container *ngSwitchCase="resetView">
          <div class="reset-password-info">
            <span class="reset-password-title">{{
              "login.reset_password.title" | translate
            }}</span>
            <span class="reset-password-text mt12">
              {{ "login.reset_password.text" | translate }}
            </span>
          </div>

          <form
            [formGroup]="resetPasswordForm"
            class="login-tab-container mt30"
            (ngSubmit)="requestResetPasswordLink()"
          >
            <lxm-input
              class="login-input"
              autocomplete="email"
              type="email"
              name="email"
              for="email"
              [placeholder]="'login.placeholders.e_mail' | translate"
            >
              <validation-errors for="email"></validation-errors>
            </lxm-input>
            <lxm-button
              class="mt30 max-w-full"
              width="280px"
              type="primary"
              buttonType="submit"
              label="login.reset_password.send"
              [loading]="requestResetPasswordLinkLoading"
            >
            </lxm-button>
            <a class="forgot-password-link mt30" (click)="toLogin()">{{
              "login.back" | translate
            }}</a>
          </form>
        </ng-container>

        <ng-container *ngSwitchCase="resetSentView">
          <div class="reset-password-info items-center justify-center">
            <span class="reset-password-title">{{
              "login.forgot_password" | translate
            }}</span>
            <span
              class="reset-password-text mt12"
              [innerHTML]="
                'login.password_request_sent.text'
                  | translate: { email: resetPasswordForm.value.email }
              "
            >
            </span>
            <svg-icon
              key="sentCheckmarkCircle"
              fontSize="64px"
              class="mt30"
            ></svg-icon>
          </div>
          <a class="forgot-password-link mt30 mb30" (click)="toLogin()">{{
            "action.back" | translate
          }}</a>
        </ng-container>
      </div>
    </div>

    <div
      *ngIf="customFor"
      class="bold w-full text-center"
      style="display: flex; justify-content: center; padding: 20px 0"
    >
      <span
        [innerHTML]="
          'login.register_custom_supplier_portal'
            | translate: { customFor: customFor, registerUrl: registerUrl }
        "
      ></span>
    </div>

    <div class="login-footer mb20">
      <copyright-block></copyright-block>
    </div>
  </div>
</div>

<!-- <page-loader></page-loader> -->
