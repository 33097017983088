import { Component, OnInit, Input, OnDestroy, Output, EventEmitter, HostBinding, ChangeDetectionStrategy, Injector } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormControl, FormGroup } from '@angular/forms';
import { IProductBaseInfo, IProductCard, IProductCardFormData, IProductCode, IClvDto, ICountryTranslated, ISegment, ISegmentTreeOption, IGs1Segment } from './product-info.model';
import { ProductService } from 'src/app/_services/product.service';
import { ContentUnitType, LxmAppModule, ProductField, Unit, UserAction } from 'src/app/enum';
import { LxmMessage } from 'src/app/_helpers/messages';
import 'src/app/ext/observable-result';
import { SegmentService, TitleService } from 'src/app/_services';
import { formUtil, keep } from 'src/app/util/form-util';
import { IUser } from 'src/app/models';
import { NavigatorState } from 'src/app/state/navigator.state';
import { RouteNavigator } from 'src/app/_helpers/route.listener';
import { AppState } from 'src/app/state/app.state';
import { HubConnection } from '@microsoft/signalr';
import { Subscription } from 'rxjs';
import { ProductValidity } from 'src/app/_services';
import { ProductCardSection } from 'src/app/enum';
import { Guid } from 'src/app/util/Guid';
import { TranslatedValuePipe } from 'src/app/pipes';
import { ProductHeaderState } from '../product-header/product-header.state';
import { ProductUnitCodeType } from 'src/app/enum/product-unit-code-type';
import { LOCAL_STORAGE_KEY } from 'src/app/config';
import { ProductCardBase } from '../product-card-base';
import { Location } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'product-info-card',
  templateUrl: './product-info.card.html',
  host: { 'class': 'expanded' },
  styleUrls: ['./product-info.card.scss']
})
export class ProductInfoCard extends ProductCardBase<IProductBaseInfo> implements OnInit, OnDestroy {

  protected get successMessage(): string {
    return 'cards.products.product_base_info.save_successful';
  }

  protected _getNormalizedFormData(data: IProductBaseInfo) {
    return formUtil.transformData(data, {
      hasLotNumber: keep,
      hygieneCertificateRequired: keep,
      markingPriceRequired: keep,
      segmentOther: keep,
      orderUnitId: () => data.orderUnit?.id,
      salesUnitId: () => data.salesUnit?.id,
      contentUnitId: () => data.contentUnit?.id,
      countryOfOrigin: val => val?.iso ? val : null,
      countryOfProduction: val => val?.iso ? val : null,
      countryOfPackaging: val => val?.iso ? val : null,
      codes: val => val || []
    });
  }

  private _getEmptyData(): IProductBaseInfo {
    return {
      fullName: undefined,
      shortName: undefined,
      brand: undefined,
      subBrand: undefined,
      model: undefined,
      color: undefined,
      countryOfOrigin: undefined,
      countryOfProduction: undefined,
      countryOfPackaging: undefined,
      manufacturer: undefined,
      importer: undefined,
      isConsignmentProduct: undefined,
      customsCode: undefined,
      hasExciseCertificate: undefined,
      hasLotNumber: false,
      hygieneCertificateRequired: false,
      orderUnit: undefined,
      salesUnit: undefined,
      contentUnit: undefined,
      averageWeightInGrams: undefined,
      markingPriceRequired: false,
      netContent: undefined,
      drainedWeightInGrams: undefined,
      additionalSizeInfo: undefined,
      gs1Segment: undefined,
      segment: undefined,
      segmentOther: undefined,
      codes: undefined
    };
  }

  private createFormGroup(data: IProductBaseInfo) {
    const normalizedData = this._getNormalizedFormData(data);
    return formUtil.createFormGroup(normalizedData);
  }

  public UserAction = UserAction;
  public LxmAppModule = LxmAppModule;

  private _hubConnection: HubConnection;
  private _signalRSubscription: Subscription;
  private _editingSubscription: Subscription;

  public isOwnProduct = true;
  public isClone = false;
  public cardSection = ProductCardSection.BaseInfo;
  public productField = ProductField;

  public segmentOptions: ISegment[];
  public segmentOtherOptions: ISegmentTreeOption[];

  public showOtherSegments: boolean = false;

  private _segmentOther: ISegment = {
    id: Guid.empty,
    code: "",
    name: "",
    title: 'cards.products.product_base_info.label.segment_other'
  };

  public get title() { return 'cards.products.product_base_info.title'; }

  @Input()
  public productId: string;

  @Output()
  public relevantProductCardSectionsChanged: EventEmitter<IProductBaseInfo> = new EventEmitter();

  @Output()
  public editing: EventEmitter<any> = new EventEmitter();

  @Output()
  public productGs1SegmentChanged: EventEmitter<IGs1Segment> = new EventEmitter();

  public translationsPath = 'cards.products.product_base_info';
  public form: FormGroup;

  public isProductActive: boolean;

  public cardValid = true;

  public createLoading = false;
  public saveLoading = false;

  public locked = false;
  public lockedBy: IUser;
  public lockedAt: Date;

  public units: IClvDto[];
  public productBrands: IClvDto[];
  public productSubBrands: IClvDto[];
  public productManufacturers: IClvDto[];
  public unitKg = Unit.Kg;

  public activeAssortmentPeriod;
  public activeOffers;

  public codeTypes;

  public isExpandedKey = LOCAL_STORAGE_KEY.PRODUCT_BASE_INFO_CARD_OPEN;

  public activeOffersRenderBaseCount = 2;
  public activeOffersRenderCount = this.activeOffersRenderBaseCount;

  constructor(injector: Injector,
    private _productHeaderState: ProductHeaderState,
    private _router: Router,
    private _routeNavigator: RouteNavigator,
    public _productService: ProductService,
    private _navigator: NavigatorState,
    private _msg: LxmMessage,
    private route: ActivatedRoute,
    private _translateService: TranslateService,
    public appState: AppState,
    public productValidity: ProductValidity,
    private _segmentService: SegmentService,
    private _titleService: TitleService,
    private _translatedValuePipe: TranslatedValuePipe,
    private _location: Location
  ) {
    super(injector, 'baseInfo', 'productBaseInfoChanged');
    if (route.snapshot.paramMap.get('productId')) {
      const productCard = route.snapshot.data.productCard as IProductCard;
      this._formData = productCard.formData;
      this.isClone = productCard.isClone;

      this.segmentOptions = productCard.formData.segmentOptions || [];
      this.segmentOptions.push(this._segmentOther);

      this.isProductActive = productCard.isActive;
      this.activeAssortmentPeriod = productCard.activeAssortmentPeriod;
      this.activeOffers = productCard.activeOffers;
      this.form = this.createFormGroup(productCard ? productCard.baseInfo : {} as IProductBaseInfo);

      if (productCard.baseInfo.segment) {
        if (!this.segmentOptions.find(x => x.id == productCard.baseInfo.segment.id)) {
          this.showOtherSegments = true;
          this.form.get("segmentOther").setValue(productCard.baseInfo.segment);
          this.form.get("segment").setValue(this._segmentOther);
        }
      }

      this.isOwnProduct = productCard.isOwnProduct;

    } else {
      this._formData = route.snapshot.data.formData as IProductCardFormData;
      this.form = this.createFormGroup(this._getEmptyData());
    }
    this.units = this._formData.units;
    this.productBrands = this._formData.productBrands;
    this.productSubBrands = this._formData.productSubBrands;
    this.productManufacturers = this._formData.manufacturers;
    this.segmentOtherOptions = this._formData.segmentTreeOptions;
    this.codeTypes = this._formData.productUnitCodeTypes;

    this.form.get('gs1Segment').valueChanges.subscribe(gs1Segment => {

      if (!gs1Segment) {
        this.segmentOptions = [];
        return;
      }

      this._segmentService.getSegmentsByGpcBrickCode(gs1Segment.id)
        .subscribe(res => {
          this.segmentOptions = res;
          this.segmentOptions.push(this._segmentOther);
        });
    });

    this.form.get('segment').valueChanges.subscribe(segment => {
      this.showOtherSegments = segment?.id == Guid.empty;
      this.form.get("segmentOther").setValue(null);
    });

    _translateService.onLangChange.subscribe(() => {
      if (this.form.get("segment").value?.id == Guid.empty) {
        // force to update translation
        this.form.get("segment").setValue(this._segmentOther);
      }
    });
  }

  public get drainedWeightEnabled(): boolean {
    const { contentUnitId } = this.form.value || {};
    return contentUnitId === ContentUnitType.Kilogram || contentUnitId === ContentUnitType.Liter;
  }

  public get sizeInfoEnabled(): boolean {
    const { contentUnitId } = this.form.value || {};
    return contentUnitId === ContentUnitType.Piece ||
      contentUnitId === ContentUnitType.Meter ||
      contentUnitId === ContentUnitType.SquareMeter ||
      contentUnitId === ContentUnitType.CubicMeter;
  }

  public disableOption(i) {
    return !i.canAssignToProduct;
  }

  public segmentTreeLabel(i) {
    return `${'--'.repeat(i.level)} ${i.code} ${i.name}`.trim();
  }

  public toggleAllActiveOffers() {
    if (this.activeOffersRenderCount === this.activeOffersRenderBaseCount) {
      if (this.activeOffers?.length > 0) {
        this.activeOffersRenderCount = this.activeOffers.length;
      }
    } else {
      this.activeOffersRenderCount = this.activeOffersRenderBaseCount;
    }
  }

  public ngOnInit() {
    super.ngOnInit();

    if (!this.productId || this.isClone) {
      this.edit = true;
    }

    this.initLinkHeaders();
    this._setProductTitle();

    this._editingSubscription = this.editing.subscribe(x => {
      this._onEditChange();
    })
  }

  private _onEditChange() {
    this._setProductTitle();
  }

  public initLinkHeaders() {
    if (this.productId) {
      this._navigator.initProductReactiveLinks(this.isOwnProduct, this._getReactiveLinksHeaderText());
    }
  }

  private _setProductTitle() {
    const nameRef = this.form.value.fullName;
    const translatedName = this._translatedValuePipe.transform(nameRef);

    if (translatedName) {
      if (this.isOwnProduct) {
        this._titleService.setTitle(translatedName);
      } else {
        this._titleService.setTitle('cards.products.market_title', { productName: translatedName })
      }
    }
  }

  private _getReactiveLinksHeaderText(form = this._snapshot) {
    const defaultContentLanguage = this.appState.userSettings.displayContentLanguage;
    let name: string;
    const shortName = form.shortName?.find(x => x.language === defaultContentLanguage)?.value;
    if (!shortName) {
      const fullName = form.fullName?.find(x => x.language === defaultContentLanguage)?.value;
      name = fullName;
    } else {
      name = shortName;
    }

    return name;
  }

  public backToList() {
    this._routeNavigator.toPreviousRoute({ fallback: '/products' });
  }

  public cancelEdit() {
    if (this.isClone) {
      this._productService.deleteCloneProduct(this.productId)
        .result(this.form, () => {
          this._router.navigate(['/products']);
        });
    } else {
      this.toggleEdit();
    }
  }

  public create() {
    this.createLoading = true;
    const req = this._createSaveRequest();
    this._productService.createProduct(req)
      .result(this.form, id => {
        this._router.navigate(['/products', id], { replaceUrl: true });
      }, error => { this.createLoading = false; }
      );
  }

  public save() {
    this.saveLoading = true;
    const req = this._createSaveRequest();

    this._productService.saveProductBaseInfo(this.productId, req)
      .result(this.form, id => {
        this._msg.ok({
          message: this.successMessage
        });
        this.saveLoading = false;
        this.saveInitialData();

        const { brand, fullName, countryOfOrigin, codes } = this.form.value;

        this._productHeaderState.setData({
          brand: brand,
          fullName: fullName,
          countryOfOrigin: countryOfOrigin,
          ean13: codes?.find(x => x.typeId === ProductUnitCodeType.EAN13 && x.isPrimary),
          supplierCode: codes?.find(x => x.typeId === ProductUnitCodeType.SupplierCode && x.isPrimary),
          internalCode: codes?.find(x => x.typeId === ProductUnitCodeType.InternalCode && x.isPrimary),
          weighingGoodsCode: codes?.find(x => x.type?.id === ProductUnitCodeType.WeighingGoods && x.isPrimary),
        });

        if (this.productValidity.shouldValidate) {
          const retailerIds = this.productValidity.validatedRetailers.map(x => x.id);
          if (retailerIds.length > 0) {
            this._productService.validateRetailChainFieldRules(this.productId, retailerIds)
              .result(null, res => this.productValidity.set(res));
          }
        }

        this.edit = false;
        const gs1Segment = this.form.get('gs1Segment').value;
        this.relevantProductCardSectionsChanged.emit(gs1Segment?.relevantProductCardSections || 0);
        this.productGs1SegmentChanged.emit(gs1Segment);

        this._navigator.refreshReactiveLinks.emit();

        if (this.isClone) {
          this.isClone = false;
          this._location.replaceState(`products/${this.productId}`);
        }
      },
        (error) => {
          this._msg.error({
            message: error.validationSummary
          });
          this.saveLoading = false;
        }
      );
  }

  public revertToInitialData() {
    this.form.patchValue(this._snapshot);
  }

  public saveInitialData() {
    this._snapshot = this.form.value;

    this._snapshot.codes = this._snapshot.codes
      .filter(x => !x.isDeleted)
      .map(x => Object.assign({}, x));

    this._navigator.headerText = this._getReactiveLinksHeaderText();
  }

  protected _createSaveRequest() {
    const form = this.form.value;

    let segmentId = form.segment?.id;

    if (segmentId == Guid.empty) {
      segmentId = form.segmentOther?.id;
    }

    const req = {
      fullName: form.fullName ? Object.assign({}, ...form.fullName.map(o => ({ [o.language]: o.value }))) : {},
      shortName: form.shortName ? Object.assign({}, ...form.shortName.map(o => ({ [o.language]: o.value }))) : {},
      brandId: form.brand ? form.brand.id : null,
      subBrandId: form.subBrand ? form.subBrand.id : null,
      model: form.model,
      color: form.color,
      countryOfOriginId: form.countryOfOrigin?.id,
      countryOfProductionId: form.countryOfProduction?.id,
      countryOfPackagingId: form.countryOfPackaging?.id,
      manufacturerId: form.manufacturer?.id,
      importerId: form.importer?.id,
      isConsignmentProduct: form.isConsignmentProduct || false,
      customsCode: form.customsCode,
      hasExciseCertificate: form.hasExciseCertificate || false,
      hasLotNumber: form.hasLotNumber || false,
      hygieneCertificateRequired: form.hygieneCertificateRequired || false,
      orderUnitId: form.orderUnitId,
      salesUnitId: form.salesUnitId,
      contentUnitId: form.contentUnitId,
      averageWeightInGrams: form.averageWeightInGrams,
      markingPriceRequired: form.markingPriceRequired || false,
      drainedWeightInGrams: form.drainedWeightInGrams,
      netContent: form.netContent,
      additionalSizeInfo: form.additionalSizeInfo,
      gs1SegmentId: form.gs1Segment?.id,
      segmentId: segmentId,
      codes: form.codes.filter((x: IProductCode) => !x.isSystemCode && !x.isDeleted)
    };

    return req;
  }

  public ngOnDestroy() {
    super.ngOnDestroy();
    this._productService.emptyRetailChainFieldRules();
    this._navigator.destroyProductReactiveLinks();

    if (this._hubConnection) {
      this._hubConnection.off('productBaseInfoChanged');
      this._hubConnection.off('productSectionLocked');
      this._hubConnection.off('productSectionUnlocked');
    }

    if (this._signalRSubscription) {
      this._signalRSubscription.unsubscribe();
    }

    if (this._editingSubscription) {
      this._editingSubscription.unsubscribe();
    }
  }

}