<card class="client-card" [formGroup]="form">
  <div header-title class="client-header" [ngSwitch]="edit">
    <logo-block [src]="customLogoSrc || logoSrc" [class.client-active]="isClientActive"></logo-block>
    <span class="ml16">
      {{ clientName || ('cards.clients.client_info.new_client' | translate) }}
    </span>
  </div>
  <div header-actions [ngSwitch]="edit">

    <div class="flex align-center justify-end" *ngIf="clientId">
      <ng-container *ngSwitchCase="false">
        <owner-select 
          for="clientOwner" 
          [options]="ownerOptions" 
          [readonly]="!canChangeOwner" 
          (change)="onOwnerChange($event)">
        </owner-select>
        <lxm-button
          *ngIf="canEdit"
          class="ml15"
          type="beige"
          icon="edit"
          title="action.edit"
          (click)="toggleEdit()">
        </lxm-button>
        <ng-container *requiredRights="[UserAction.ManageSupplierOffers]">
          <lxm-button
            *ngIf="isSelfSupplier" 
            class="ml15"
            type="beige"
            icon="plus-dark"
            label="cards.clients.client_info.action.new_offer"
            (click)="newOffer()">
          </lxm-button>
        </ng-container>
      </ng-container>
      <ng-container *ngSwitchCase="true">
        <lxm-button class="ml15" type="grey" icon="back" label="action.cancel"
          (click)="clientId ? toggleEdit() : navigateBack()">
        </lxm-button>
        <lxm-button class="ml15" type="beige" icon="save" label="action.save" [loading]="saveLoading" (click)="save()"
          buttonType="submit">
        </lxm-button>
      </ng-container>
    </div>

    <div class="flex align-center justify-end" *ngIf="!clientId">
      <ng-container *ngSwitchCase="true">
        <lxm-button class="ml15" type="grey" icon="back" label="action.cancel"
          (click)="clientId ? toggleEdit() : navigateBack()">
        </lxm-button>
        <lxm-button class="ml15" type="beige" icon="save" label="action.save" [loading]="saveLoading" (click)="save()"
          buttonType="submit">
        </lxm-button>
      </ng-container>
    </div>
  </div>
  <expander class="edit-expander" body [trigger]="edit">
    <div class="edit-body">
      <div class="row">
        <div class="col-6">
          <form-field for="clientName" type="input-text" label="enums.TenantField.Name" [required]="canEdit">
            <validation-errors for="name" aliasTo="clientName"></validation-errors>
          </form-field>
          <form-field for="clientShortName" type="input-text" maxlength="5" label="enums.TenantField.ShortName" description="cards.clients.client_info.label_description.short_name" [required]="canEdit">
            <validation-errors for="shortName" aliasTo="clientShortName"></validation-errors>
          </form-field>
          <form-field label="enums.TenantField.Country" [required]="canEdit">
            <lxm-select select for="clientCountry"
              [options]="countryOptions" 
              [clearable]="false" 
              [isTranslated]="true"
              labelField="translatedName"
              [sortByLabel]="true"
              [searchable]="true"
              labelIconField="iso3"
              labelIconFallback="global"
              labelIconClass="flag-icon">
            </lxm-select>
            <validation-errors for="countryId" aliasTo="clientCountry"></validation-errors>
          </form-field>
          <form-field label="enums.TenantField.Status" [required]="canEdit">
            <lxm-select select [options]="clientStatusOptions" [clearable]="false" for="clientStatus" value="id">
            </lxm-select>
            <validation-errors for="status" aliasTo="clientStatus"></validation-errors>
          </form-field>
        </div>
        <div class="col-6">
          <form-field label="Logo">
            <image-drop-box any
                style="max-width: 250px; width: 100%;"
                (onChange)="uploadFile($event)"
                (onDelete)="deleteAttachment($event)"
                [files]="files"
                [src]="customLogoSrc || logoSrc"
                [uploadInProgress]="uploadInProgress"
                [uploadingFile]="uploadingFile">
            </image-drop-box>
          </form-field>
        </div>
      </div>
    </div>
  </expander>
</card>