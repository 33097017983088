<card-expanding
  [valid]="productValidity.getCardStatusValidType(cardSection)"
  [expanded]="isExpanded"
  (onExpand)="saveLocalExpandedValue($event)"
>
  <span header-title
    >{{'cards.products.product_images.title' | translate}}</span
  >

  <div header-actions>
    <ng-container *ngIf="isOwnProduct">
      <ng-container *requiredRights="[UserAction.ManageProducts]">
        <lxm-button
          icon="plus-dark"
          type="beige"
          label="cards.products.product_images.action.add_image"
          (click)="openDialog()"
        ></lxm-button>
      </ng-container>
    </ng-container>
  </div>

  <div body>
    <div *ngIf="!images?.length">
      <no-content
        image="no-product-images"
        text="global.no_images_added"
        [inline]="true"
      ></no-content>
    </div>

    <div
      class="row mb30"
      *ngFor="let image of images; let i = index; trackBy: imageComparer"
    >
      <div class="col-2 no-padding">
        <div class="product-image-container">
          <img
            class="product-image"
            [src]="image.thumbnailMediumUrl"
            draggable="false"
          />
        </div>
      </div>

      <div class="col-10 no-padding">
        <div class="grid w-full grid-cols-1 gap-x-4 xl:grid-cols-2">
          <div class="ml30 flex flex-col">
            <form-field [readonly]="true">
              <a label class="underline" [href]="image.url" target="_blank"
                >{{ 'cards.products.product_images.label.base_image' | translate
                }}</a
              >
              <div class="flex flex-1 flex-wrap justify-between gap-2 max-w-[200px]" text>
                <span class="min-w-100px"> {{image.width}} x {{image.height}} </span>
                <span class="alt-text"> {{image.size | filesize}} </span>
              </div>
            </form-field>

            <form-field *ngFor="let format of image.formats">
              <span label
                >{{ format.formatType | translateEnum: 'ProductImageFormatType'
                }}</span
              >
              <div class="flex flex-1 flex-wrap justify-between gap-2 max-w-[200px]" text>
                <span class="min-w-100px"> {{ format.width }} x {{ format.height }} </span>
                <span class="alt-text"> {{ format.size | filesize }} </span>
              </div>
            </form-field>
          </div>

          <div class="ml30 flex flex-col">
            <form-field [readonly]="true">
              <span label
                >{{ 'cards.products.product_images.label.image_status' |
                translate}}</span
              >
              <span text>{{ image.status | translateEnum: 'Status'}}</span>
            </form-field>

            <form-field [readonly]="true">
              <span label
                >{{'cards.products.product_images.label.master' |
                translate}}</span
              >
              <span text>{{ image.isMaster | translateEnum: 'Bool'}}</span>
            </form-field>

            <form-field>
              <span label
                >{{ 'cards.products.product_images.label.forRetailChain' |
                translate }}</span
              >
              <span text>
                {{ image.isForRetailChain | translateEnum: 'Bool' }}</span
              >
            </form-field>
          </div>
        </div>

        <ng-container *ngIf="isOwnProduct">
          <div
            class="row ml30 mt30"
            *requiredRights="[UserAction.ManageProducts]"
          >
            <lxm-button
              icon="edit"
              type="beige"
              label="cards.products.product_images.action.edit"
              (click)="openDialog(image)"
            ></lxm-button>
            <!-- <lxm-button class="ml15" icon="plus-dark" type="beige" label="cards.products.product_images.action.add_format"></lxm-button> -->
            <lxm-button
              class="ml15"
              icon="trash"
              type="grey"
              label="cards.products.product_images.action.delete"
              (click)="deleteImage(image.id, i)"
            ></lxm-button>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</card-expanding>
