import { TranslateService } from '@ngx-translate/core';
import { Unit, UnitShort } from './unit';
import { ClvType } from './clv-type';
import { FilterType } from './filter-type';
import { UserAction } from './user-action';
import { Status } from './status';
import { Bool } from './bool';
import { ProductUnitType } from './product-unit-type';
import { ProductUnitPalletType } from './product-unit-pallet-type';
import { WineColor } from './wine-color';
import { StateOfFish } from './state-of-fish';
import { ExpiryTargetType } from './expiry-target-type';
import { AllergenContentType } from './allergen-content-type';
import { AtcSegmentTargetGroup } from './atc-segment-target-group';
import { GmoContent } from './gmo-content';
// import { TenantType } from './tenant-type';
import { LxmAppModule } from './app-module';
import { CalendarOfferType, OfferType, OfferTypeBasic } from './offer-type';
import { SupplierOfferStatus } from './offer-status';
import { ReceivingOfferStatus } from './receiving-offer-status';
import { RetailerOffersViewType } from './retailer-offers-view-type';
import { SupplierProductAssortmentStatus } from './supplier-product-assortment-status';
import { ProductCardSection } from './product-card-section';
import { ProductField } from './product-field';
import { DiffValueFormatterType } from './diff-value-formatter-type';
import { ProductChangeType } from './product-change-type';
import { ProductUnitCodeField } from './product-unit-code-field';
import { ProductAllergenField } from './product-allergen-field';
import { ProductRetailerExpiryField } from './product-reatailer-expiry-field';
import { ProductImageFormatType } from './product-image-format-type';
import { ProductRetailerChangesState } from './product-retailer-sync-state';
import { ProductUnitField } from './product-unit-field';
import { ProductUnitPackageMaterialField } from './product-unit-package-material-field';
import { ProductUnitReturnablePackageDepositField } from './product-unit-returnable-package-deposit-field';
import { ProductUnitTareField } from './product-unit-tare-field';
import { OfferField } from './offer-field';
import { OfferProductField } from './offer-product-field';
import { OfferKind } from './offer-kind';
import { OfferProductStatus } from './offer-product-status';
import { OfferChangeType } from './offer-change-type';
import { CampaignReturnableItemOption } from './campaign-returnable-item-option';
import { UniqueCodeTypeOption } from './unique-code-type-option';
import { Gs1CodeCheckResponseType } from './gs1-check-response-type';
import { FieldRequiredType } from './field-required-type';
import { NotificationType } from './notification-type';
import { Guid } from '../util/Guid';
import { ClientStatus } from './client-status';
import { ClientChangeType } from './client-change-type';
import { TenantField } from './tenant-field';
import { ClientTimelineItemType } from './client-timeline-item-type';
import { ClientDiscountRuleType } from './client-discount-rule-type';
import { TenantStatus } from './tenant-status';
import { KeyCode } from './key-code';
import { ProductMovieFormatField, ProductMovieAudioField, ProductMovieActorField } from './product-movie-fields';
import { ProductBookRoleField } from './product-book-fields';
import { OfferRequestStatus } from './offer-request-status';
import { ProductMusicTrackField } from './product-music-fields';
import { UserStatus } from './user-status';
import { DayOfWeek } from './day-of-week';
import { LocationShelfDirection } from './location-shelf-direction';
import { LocationStatus } from './location-status';
import { SupplierField } from './supplier-field';
import { OfferProductCommentStatus } from './offer-product-comment-status';
import { OfferProductDecisionStatus } from './offer-product-decision-status';
import { TextFilterValueType } from './text-filter-value-type';
import { AssortmentMovement } from './assortment-movement';
import { DecisionProductStatus } from './decision-product-status';
import { CampaignTypeValueType } from './campaign-type-value-type';
import { CampaignTypeVatType } from './campaign-type-vat-type';

// EXTERNAL
import { ProcurementType } from './procurement-type';
import { ProductPriceSchemeType } from './product-price-scheme-type';
import { ProductUnitCodeType } from './product-unit-code-type';
import { ValidType } from './valid-type';
import { PurchaseOrderStatus } from './purchase-order-status';
import { SalesOrderStatus } from './sales-order-status';
import { ContentUnitType } from './content-unit-type';
import { StatusRule } from './status-rule';
import { CalendarGroupCampaignType } from './calendar-group-campaign-type';
import { ConformityDeclarationType } from './conformity-declaration-type';

export const registry: { [index: string]: any } = {
  [(<any>Unit).__typeName]: Unit,
  [(<any>UnitShort).__typeName]: UnitShort,
  [(<any>ClvType).__typeName]: ClvType,
  [(<any>FilterType).__typeName]: FilterType,
  [(<any>UserAction).__typeName]: UserAction,
  [(<any>Status).__typeName]: Status,
  [(<any>Bool).__typeName]: Bool,
  [(<any>ProductUnitType).__typeName]: ProductUnitType,
  [(<any>ProductUnitPalletType).__typeName]: ProductUnitPalletType,
  [(<any>WineColor).__typeName]: WineColor,
  [(<any>StateOfFish).__typeName]: StateOfFish,
  [(<any>ExpiryTargetType).__typeName]: ExpiryTargetType,
  [(<any>AllergenContentType).__typeName]: AllergenContentType,
  [(<any>AtcSegmentTargetGroup).__typeName]: AtcSegmentTargetGroup,
  [(<any>GmoContent).__typeName]: GmoContent,
  // [(<any>TenantType).__typeName]: TenantType,
  [(<any>LxmAppModule).__typeName]: LxmAppModule,
  [(<any>SupplierOfferStatus).__typeName]: SupplierOfferStatus,
  [(<any>ReceivingOfferStatus).__typeName]: ReceivingOfferStatus,
  [(<any>RetailerOffersViewType).__typeName]: RetailerOffersViewType,
  [(<any>SupplierProductAssortmentStatus).__typeName]:
    SupplierProductAssortmentStatus,
  [(<any>ProductCardSection).__typeName]: ProductCardSection,
  [(<any>ProductField).__typeName]: ProductField,
  [(<any>DiffValueFormatterType).__typeName]: DiffValueFormatterType,
  [(<any>ProductChangeType).__typeName]: ProductChangeType,
  [(<any>ProductUnitCodeField).__typeName]: ProductUnitCodeField,
  [(<any>ProductAllergenField).__typeName]: ProductAllergenField,
  [(<any>ProductRetailerExpiryField).__typeName]: ProductRetailerExpiryField,
  [(<any>ProductImageFormatType).__typeName]: ProductImageFormatType,
  [(<any>ProductRetailerChangesState).__typeName]: ProductRetailerChangesState,
  [(<any>ProductUnitField).__typeName]: ProductUnitField,
  [(<any>ProductUnitPackageMaterialField).__typeName]:
    ProductUnitPackageMaterialField,
  [(<any>ProductUnitReturnablePackageDepositField).__typeName]:
    ProductUnitReturnablePackageDepositField,
  [(<any>ProductUnitTareField).__typeName]: ProductUnitTareField,
  [(<any>OfferType).__typeName]: OfferType,
  [(<any>OfferTypeBasic).__typeName]: OfferTypeBasic,
  [(<any>OfferField).__typeName]: OfferField,
  [(<any>OfferProductField).__typeName]: OfferProductField,
  [(<any>OfferKind).__typeName]: OfferKind,
  [(<any>OfferProductStatus).__typeName]: OfferProductStatus,
  [(<any>OfferChangeType).__typeName]: OfferChangeType,
  [(<any>OfferProductDecisionStatus).__typeName]: OfferProductDecisionStatus,
  [(<any>OfferRequestStatus).__typeName]: OfferRequestStatus,
  [(<any>OfferProductCommentStatus).__typeName]: OfferProductCommentStatus,
  [(<any>CampaignReturnableItemOption).__typeName]:
    CampaignReturnableItemOption,
  [(<any>UniqueCodeTypeOption).__typeName]: UniqueCodeTypeOption,
  [(<any>Gs1CodeCheckResponseType).__typeName]: Gs1CodeCheckResponseType,
  [(<any>FieldRequiredType).__typeName]: FieldRequiredType,
  [(<any>ClientStatus).__typeName]: ClientStatus,
  [(<any>ClientChangeType).__typeName]: ClientChangeType,
  [(<any>TenantField).__typeName]: TenantField,
  [(<any>ClientTimelineItemType).__typeName]: ClientTimelineItemType,
  [(<any>ClientDiscountRuleType).__typeName]: ClientDiscountRuleType,
  [(<any>TenantStatus).__typeName]: TenantStatus,
  [(<any>NotificationType).__typeName]: NotificationType,
  [(<any>ProductMovieFormatField).__typeName]: ProductMovieFormatField,
  [(<any>ProductMovieAudioField).__typeName]: ProductMovieAudioField,
  [(<any>ProductMovieActorField).__typeName]: ProductMovieActorField,
  [(<any>ProductBookRoleField).__typeName]: ProductBookRoleField,
  [(<any>ProductMusicTrackField).__typeName]: ProductMusicTrackField,
  [(<any>UserStatus).__typeName]: UserStatus,
  [(<any>DayOfWeek).__typeName]: DayOfWeek,
  [(<any>LocationShelfDirection).__typeName]: LocationShelfDirection,
  [(<any>LocationStatus).__typeName]: LocationStatus,
  [(<any>SupplierField).__typeName]: SupplierField,
  [(<any>TextFilterValueType).__typeName]: TextFilterValueType,
  [(<any>AssortmentMovement).__typeName]: AssortmentMovement,
  [(<any>ProcurementType).__typeName]: ProcurementType,
  [(<any>ProductPriceSchemeType).__typeName]: ProductPriceSchemeType,
  [(<any>DecisionProductStatus).__typeName]: DecisionProductStatus,
  [(<any>PurchaseOrderStatus).__typeName]: PurchaseOrderStatus,
  [(<any>SalesOrderStatus).__typeName]: SalesOrderStatus,
  [(<any>ProductUnitCodeType).__typeName]: ProductUnitCodeType,
  [(<any>ValidType).__typeName]: ValidType,
  [(<any>ContentUnitType).__typeName]: ContentUnitType,
  [(<any>StatusRule).__typeName]: StatusRule,
  [(<any>CampaignTypeValueType).__typeName]: CampaignTypeValueType,
  [(<any>CampaignTypeVatType).__typeName]: CampaignTypeVatType,
  [(<any>CalendarGroupCampaignType).__typeName]: CalendarGroupCampaignType,
  [(<any>CalendarOfferType).__typeName]: CalendarOfferType,
  [(<any>ConformityDeclarationType).__typeName]: ConformityDeclarationType
};

function asArray<T extends {[index: number]: string }>(values: T): IEnumValue[] {
    return Object.keys(values)
      .filter(value => isNaN(Number(value)) === false)
      .map(key => asObject(values, Number(key)));
}

function asObject<T extends {[index: number]: string }>(values: T, key: number) {
  return { id: key, name: getKey(values, key), label: values[key] };
}

function asBoolArray<T extends {[index: string]: any }>(values: T): { id: string, name: string }[] {
    return Object.keys(values)
      .filter(value => typeof values[value] === 'boolean')
      .map(value => (
        { id: values[value], name: getKey(values, values[value]) }
      ));
}

function asGuidArray<T extends {[index: string]: any }>(values: T): { id: string, name: string }[] {
    return Object.keys(values)
      .filter(value => Guid.isValid(values[value]))
      .map(value => (
        { id: values[value], name: getKey(values, values[value]), label: value }
      ));
}

function translate<T extends { [index: string]: any }>(values: T, key: any, __?: TranslateService) {

  if (key === null) {
    return '';
  }

  if (typeof values === 'string') {
    values = registry[values];
  }

  if (isNaN(key) && !Guid.isValid(key)) {
    key = values[key];
  }

  return __ ? __.instant(getKey(values, key)) : values[key];
}

function getKey<T extends { [index: string]: any }>(values: T, key: any) {

  if (values && values.__altTranslationKeys && values.__altTranslationKeys[key]) {
    return values.__altTranslationKeys[key];
  }

  return `enums.${(<any>values).__typeName}.${values[key]}`;
}

function label<T extends { [index: string]: any }>(values: T, key: any) {
  return values[key];
}

export interface IEnumValue {
  id: number;
  name: string;
  label: string;
}

export {
  asArray, asBoolArray, asGuidArray, translate, getKey, asObject, label,
  Unit, UnitShort,
  ClvType,
  FilterType,
  UserAction,
  Status,
  Bool,
  ProductUnitType,
  ProductUnitPalletType,
  WineColor,
  StateOfFish,
  ExpiryTargetType,
  AllergenContentType,
  AtcSegmentTargetGroup,
  GmoContent,
  // TenantType,
  LxmAppModule,
  OfferType, OfferTypeBasic,
  SupplierOfferStatus,
  ReceivingOfferStatus,
  RetailerOffersViewType,
  SupplierProductAssortmentStatus,
  ProductCardSection,
  ProductField,
  DiffValueFormatterType,
  ProductChangeType,
  ProductUnitCodeField,
  ProductAllergenField,
  ProductRetailerExpiryField,
  ProductImageFormatType,
  ProductRetailerChangesState,
  ProductUnitField,
  ProductUnitPackageMaterialField,
  ProductUnitReturnablePackageDepositField,
  ProductUnitTareField,
  OfferField,
  OfferProductField,
  OfferKind,
  OfferProductStatus,
  OfferChangeType,
  CampaignReturnableItemOption,
  UniqueCodeTypeOption,
  Gs1CodeCheckResponseType,
  FieldRequiredType,
  ClientStatus,
  ClientChangeType,
  TenantField,
  ClientTimelineItemType,
  ClientDiscountRuleType,
  TenantStatus,
  NotificationType,
  KeyCode,
  ProductMovieFormatField,
  ProductMovieAudioField,
  ProductMovieActorField,
  ProductBookRoleField,
  OfferRequestStatus,
  ProductMusicTrackField,
  UserStatus,
  DayOfWeek,
  LocationShelfDirection,
  LocationStatus,
  OfferProductCommentStatus,
  SupplierField,
  OfferProductDecisionStatus,
  TextFilterValueType,
  ProcurementType,
  ProductPriceSchemeType,
  DecisionProductStatus,
  PurchaseOrderStatus,
  SalesOrderStatus,
  ValidType,
  ContentUnitType,
  StatusRule,
  CampaignTypeValueType,
  CampaignTypeVatType,
  ConformityDeclarationType,
};

export * from './http-method';