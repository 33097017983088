<ng-container [formGroup]="formGroup" [readonly]="!canEdit">
  <form-field
    dialog
    label="cards.settings.product_management_settings.logistics_partners_edit.label.is_default"
  >
    <slide-toggle toggle [formGroup]="formGroup" for="isDefault"></slide-toggle>
    <validation-errors for="isDefault"></validation-errors>
  </form-field>

  <form-field
    dialog
    label="cards.settings.product_management_settings.logistics_partners_edit.label.country"
    formGroupName="address"
  >
    <lxm-select
      select
      [options]="countryOptions"
      [isTranslated]="true"
      labelField="translatedName"
      [sortByLabel]="true"
      [searchable]="true"
      for="country"
      labelIconField="iso3"
      labelIconFallback="global"
      labelIconClass="flag-icon"
    >
    </lxm-select>
    <validation-errors for="country"></validation-errors>
    <validation-errors for="countryId"></validation-errors>
  </form-field>

  <form-field
    dialog
    formGroupName="address"
    label="cards.settings.product_management_settings.logistics_partners_edit.label.county"
    for="county"
    type="input-text"
  >
    <validation-errors for="county"></validation-errors>
  </form-field>

  <form-field
    dialog
    formGroupName="address"
    label="cards.settings.product_management_settings.logistics_partners_edit.label.city"
    for="city"
    type="input-text"
  >
    <validation-errors for="city"></validation-errors>
  </form-field>

  <form-field
    dialog
    formGroupName="address"
    label="cards.settings.product_management_settings.logistics_partners_edit.label.street"
    for="street"
    type="input-text"
  >
    <validation-errors for="street"></validation-errors>
  </form-field>

  <form-field
    dialog
    formGroupName="address"
    label="cards.settings.product_management_settings.logistics_partners_edit.label.number"
    for="nr"
    type="input-text"
  >
    <validation-errors for="nr"></validation-errors>
  </form-field>

  <form-field
    dialog
    formGroupName="address"
    label="cards.settings.product_management_settings.logistics_partners_edit.label.postal_code"
    for="postalCode"
    type="input-text"
  >
    <validation-errors for="postalCode"></validation-errors>
  </form-field>
</ng-container>
