<div
  class="grid grid-cols-1 gap-2 md:grid-cols-2 w-full"
>
  <div
    class="f-1 flex flex-col overflow-hidden text-ellipsis whitespace-nowrap"
  >
    <div class="calendar-offer-group">
      <span>{{data.name | translatedValue}}</span>
    </div>
    <div class="calendar-offer-name">
      <span>{{data.group?.value}}</span>
    </div>
    <div class="calendar-offer-date-container">
      <type-indicator [type]="offerType"></type-indicator>
      <span class="calendar-offer-date"
        >{{data.validFrom | formDate}} - {{data.validTo | formDate}}</span
      >
    </div>
  </div>
  <div
    class="md:items-end flex flex-col overflow-hidden text-ellipsis whitespace-nowrap"
  >
    <time-diff [date]="data.offerDueDate"></time-diff>
    <span class="calendar-offer-retailer md:text-right"> {{data.retailer.name}} </span>
  </div>
</div>
