<card-expanding [formGroup]="form" [valid]="cardValidType" [expanded]="isExpanded" (onExpand)="saveLocalExpandedValue($event)">

  <span header-title>{{ title | translate}}</span>

  <div header-actions *requiredRights="[UserAction.ManageProducts]">
    <ng-container *ngIf="isOwnProduct">
      <ng-container *ngIf="!locked">
        <lxm-button *ngIf="!edit" type="beige" icon="edit" title="action.edit" (click)="toggleEdit()"></lxm-button>
        <lxm-button *ngIf="edit" type="grey" icon="back" label="action.cancel" (click)="toggleEdit()">
        </lxm-button>
        <lxm-button *ngIf="edit" type="beige" icon="save" label="action.save" [loading]="saveLoading"
          (click)="save()" buttonType="submit"></lxm-button>
      </ng-container>
      <ng-container *ngIf="locked">
        {{ 'global.locked_by' | translate }}: {{ lockedBy.name }} {{ lockedAt | amTimeAgo }}
      </ng-container>
    </ng-container>
  </div>

  <div body>

    <div class="grid grid-cols-1 xl:grid-cols-2 w-full gap-4">
      <div class="flex flex-col">

        <form-field [readonly]="!edit" for="releaseYear"
          label="cards.products.product_book_info.label.release_year"
          description="cards.products.product_book_info.label_description.release_year"
          type="input-text"
          [required]="productValidity.shouldValidate ? productValidity.isRequiredByRetailer(cardSection, productField.BookReleaseYear, form.get('releaseYear').value) : null">
          <validation-errors for="releaseYear"></validation-errors>
        </form-field>

        <form-field [readonly]="!edit"
          label="cards.products.product_book_info.label.book_format"
          description="cards.products.product_book_info.label_description.book_format"
          for="bookFormatId"
          [required]="productValidity.shouldValidate ? productValidity.isRequiredByRetailer(cardSection, productField.BookFormat, form.get('bookFormatId').value) : null">
          <lxm-select select
            [readonly]="!edit"
            [options]="bookFormatOptions"
            for="bookFormatId"
            value="id"
            labelField="translatedValue"
            [isTranslated]="true"
            [sortByLabel]="true">
            </lxm-select>
          <validation-errors for="bookFormatId"></validation-errors>
        </form-field>

        <form-field [readonly]="!edit"
          label="cards.products.product_book_info.label.language"
          description="cards.products.product_book_info.label_description.language"
          for="language"
          [required]="productValidity.shouldValidate ? productValidity.isRequiredByRetailer(cardSection, productField.BookLanguage, form.get('language').value) : null">
            <lxm-select select for="language"
              labelField="name"
              [searchable]="true"
              url="api/clvac/languages/"
              [readonly]="!edit">
            </lxm-select>
          <validation-errors for="language"></validation-errors>
        </form-field>

        <form-field [readonly]="!edit" for="pageAmount"
            label="cards.products.product_book_info.label.page_amount"
            description="cards.products.product_book_info.label_description.page_amount"
            type="input-text"
            [required]="productValidity.shouldValidate ? productValidity.isRequiredByRetailer(cardSection, productField.BookPageAmount, form.get('pageAmount').value) : null">
            <validation-errors for="pageAmount"></validation-errors>
        </form-field>

      </div>

      <div class="flex flex-col">
        <form-field [readonly]="!edit" for="publisher"
            label="cards.products.product_book_info.label.publisher"
            description="cards.products.product_book_info.label_description.publisher"
            type="input-text"
            [required]="productValidity.shouldValidate ? productValidity.isRequiredByRetailer(cardSection, productField.BookPublisher, form.get('publisher').value) : null">
            <validation-errors for="publisher"></validation-errors>
        </form-field>

        <form-field [readonly]="!edit" for="edition"
            label="cards.products.product_book_info.label.edition"
            description="cards.products.product_book_info.label_description.edition"
            type="input-text"
            [required]="productValidity.shouldValidate ? productValidity.isRequiredByRetailer(cardSection, productField.BookEdition, form.get('edition').value) : null">
            <validation-errors for="edition"></validation-errors>
        </form-field>

      </div>
    </div>

    <section-expanding [expanded]="shouldExpandSections" class="mt0" [required]="productValidity.shouldValidate ? productValidity.isRequiredByRetailer(cardSection, productField.BookRoles, form.get('roles').value) : null">
      <span header-title>
        {{'cards.products.product_book_info.role_section.title' | translate}}
      </span>
      <div body class="w-full overflow-x">
          <book-role-list 
            for="roles" 
            [readonly]="!edit" 
            [bookRoleOptions]="bookRoleOptions">
          </book-role-list>
      </div>
    </section-expanding>

  </div>

  <div footer class="flex flex-row" *ngIf="edit">
    <lxm-button class="ml15" type="grey" icon="back" label="action.cancel" (click)="toggleEdit()"></lxm-button>
    <lxm-button class="ml15" type="beige" icon="save" label="action.save" [loading]="saveLoading" (click)="save()">
    </lxm-button>
  </div>

</card-expanding>
