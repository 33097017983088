<card-expanding>
  <span header-title>{{ 'cards.clients.contacts.title' | translate}} ({{contactsCount}})</span>
  <div header-actions>
    <ng-container *ngIf="canEdit">
      <lxm-button type="beige" icon="plus-dark"
        label="cards.clients.contacts.action.add_contact" (click)="openDialog()" *requiredRights="[UserAction.ManageClients]">
      </lxm-button>
    </ng-container>
  </div>

  <div body [ngSwitch]="hasContacts">
    <div *ngSwitchCase="true">
      <client-contact 
        *ngFor="let contact of contacts  | slice:0:4"
        (click)="openDialog(contact)"
        [contact]="contact">
      </client-contact>
      <ng-container *ngIf="showAllContacts">
        <client-contact 
          *ngFor="let contact of contacts  | slice:4:1000"
          (click)="openDialog(contact)"
          [contact]="contact">
        </client-contact>
      </ng-container>
      <div *ngIf="contacts?.length > 4" class="toggle-show-all-contacts" (click)="toggleShowAllContacts()">
        {{(showAllContacts ? 
          'cards.clients.contacts.action.show_less_contacts' : 
          'cards.clients.contacts.action.show_all_contacts') | translate
        }}
      </div>

    </div>
    <no-content 
      *ngSwitchCase="false" 
      heading="cards.clients.contacts.no_contacts_heading"
      text="cards.clients.contacts.no_contacts_text"
      image="no-contacts">
    </no-content>
  </div>

</card-expanding>