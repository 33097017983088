import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { AppState } from "src/app/state/app.state";
import { LxmAppModule, ProductCardSection, ProductField, ProductUnitField } from "src/app/enum";
import { IRetailChain } from "src/app/cards/product/product-info/product-info.model";
import { LocalStorage } from "src/app/_helpers/local-storage";
import { LOCAL_STORAGE_KEY } from "src/app/config";
import { FieldRequiredType, ValidType } from "src/app/enum";
import { Observable } from 'rxjs';
import { FormControl, FormGroup } from "@angular/forms";

@Injectable()
export class ProductsSupplierSearchDataService {

  public searchData = new FormGroup({
    searchTerm: new FormControl(),
    brands: new FormControl(),
    subBrands: new FormControl(),
    countries: new FormControl(),
    manufacturers: new FormControl(),
    importers: new FormControl(),
    gs1Segments: new FormControl(),
    segments: new FormControl(),
    // inRetailerAssortment: new FormControl(),
    assortmentStatuses: new FormControl(),
    nutritionalClaims: new FormControl(),
    productTags: new FormControl(),
    productTagsSearchOperator: new FormControl(),
    productPriceSchemeType: new FormControl(),
    client: new FormControl(),
    productValidation: new FormControl(),
    retailers: new FormControl()
  });

  public get productPriceSchemeFilterSelected(): boolean {
    return this.searchData.get('productPriceSchemeType').value ? true : false;
  }

  public get showRetailersForProductValidation(): boolean {
    return this.searchData.get('productValidation').value == 2 ? true : false;
  }

  public serializeSearchData() {
    const searchData = this.searchData.value;

    return {
      searchTerm: searchData.searchTerm,
      brandIds: searchData.brands?.map((x: any) => x.id),
      subBrandIds: searchData.subBrands?.map((x: any) => x.id),
      countryIds: searchData.countries,
      manufacturerIds: searchData.manufacturers?.map((x: any) => x.id),
      importerIds: searchData.importers?.map((x: any) => x.id),
      gs1SegmentIds: searchData.gs1Segments?.map((x: any) => x.id),
      segmentIds: searchData.segments?.map((x: any) => x.id),
      // inRetailerAssortment: new FormControl(),
      assortmentStatuses: searchData.assortmentStatuses,
      nutritionalClaims: searchData.nutritionalClaims,
      productTagsIds: searchData.productTags,
      productTagsSearchOperator: searchData.productTagsSearchOperator,
      productPriceSchemeType: searchData.productPriceSchemeType,
      clientId: this.productPriceSchemeFilterSelected ? searchData.client : null,
      productValidation: searchData.productValidation,
      retailerIds: this.showRetailersForProductValidation ? searchData.retailers : null
    };
  }

}

@Injectable()
export class ProductsRetailerSearchDataService {

  public searchData = new FormGroup({
    date: new FormControl(),
    searchTerm: new FormControl(),
    brands: new FormControl(),
    subBrands: new FormControl(),
    countries: new FormControl(),
    suppliers: new FormControl(),
    gs1Segments: new FormControl(),
    categories: new FormControl(),
    assortmentStatuses: new FormControl(),
    assortmentGroups: new FormControl(),
    locations: new FormControl(),
    productAttributes: new FormControl(),
    nutritionalClaims: new FormControl(),
    isInCampaign: new FormControl(),
    assortmentMovement: new FormControl(),
    assortmentMovementFrom: new FormControl(),
    assortmentMovementTo: new FormControl()
  });

  public serializeSearchData() {
    const searchData = this.searchData.value;
    return {
      searchTerm: searchData.searchTerm,
      brandIds: searchData.brands?.map((x: any) => x.id),
      subBrandIds: searchData.subBrands?.map((x: any) => x.id),
      countryIds: searchData.countries,
      supplierIds: searchData.suppliers?.map((x: any) => x.id),
      gs1SegmentIds: searchData.gs1Segments?.map((x: any) => x.id),
      categoryIds: searchData.categories?.map((x: any) => x.id),
      assortmentStatusIds: searchData.assortmentStatuses?.map((x: any) => x.id),
      locationIds: searchData.locations?.map((x: any) => x.id),
      assortmentGroupIds: searchData.assortmentGroups?.map((x: any) => x.id),
      productAttributeIds: searchData.productAttributes?.map((x: any) => x.id),
      nutritionalClaims: searchData.nutritionalClaims,
      isInCampaign: searchData.isInCampaign,
      assortmentMovement: searchData.assortmentMovement,
      assortmentMovementFrom: searchData.assortmentMovementFrom,
      assortmentMovementTo: searchData.assortmentMovementTo,
      date: searchData.date
    };
  }


}

@Injectable()
export class MarketSearchDataService {

  public searchData = new FormGroup({
    searchTerm: new FormControl(),
    brands: new FormControl(),
    subBrands: new FormControl(),
    countries: new FormControl(),
    suppliers: new FormControl(),
    gs1Segments: new FormControl(),
    assortmentStatuses: new FormControl(),
    productAttributes: new FormControl(),
    shouldShowEndOfLifeProducts: new FormControl(),
    nutritionalClaims: new FormControl()
  });

  
  public serializeSearchData() {
    const searchData = this.searchData.value;
    return {
      searchTerm: searchData.searchTerm,
      brandIds: searchData.brands?.map((x: any) => x.id),
      subBrandIds: searchData.subBrands?.map((x: any) => x.id),
      countryIds: searchData.countries,
      supplierIds: searchData.suppliers?.map((x: any) => x.id),
      gs1SegmentIds: searchData.gs1Segments?.map((x: any) => x.id),
      assortmentStatuses: searchData.assortmentStatuses,
      productAttributeIds: searchData.productAttributes?.map((x: any) => x.id),
      shouldShowEndOfLifeProducts: searchData.shouldShowEndOfLifeProducts,
      nutritionalClaims: searchData.nutritionalClaims
    };
  }

}

@Injectable({ providedIn: "root" })
export class ProductService {
  constructor(
    private _http: HttpClient,
    private _productValidity: ProductValidity
  ) {}

  public list(data?: any) {
    return this._http.get<IProductsSearchResponse>(`api/products`);
  }

  public getProductCurrentAssortment(productId: string, segmentId: string) {
    return this._http.get<any>(`api/products/${productId}/assortment/retailer/current?segmentId=${segmentId}`)
  }

  public createProduct(data: any) {
    return this._http.post<any>(`api/products`, data);
  }

  public cloneProduct(data: any) {
    return this._http.post<any>(`api/products/clone`, data);
  }

  public deleteProduct(productId: string) {
    return this._http.delete(`api/products/${productId}`);
  }

  public sendToDirecto(productId: string) {
    return this._http.post<any>(`api/products/${productId}/sendToDirecto`, productId);
  }

  public getImageFromApollo(productId: string) {
    return this._http.post<any>(`api/products/${productId}/getImageFromApollo`, productId);
  }

  public getProductInfoFromApollo(productId: string) {
    return this._http.get<any>(`api/products/${productId}/getProductInfoFromApollo`);
  }

  public deleteCloneProduct(productId: string) {
    return this._http.delete(`api/products/clone/${productId}`);
  }

  public saveProductBaseInfo(productId: string, data: any) {
    return this._http.post<any>(`api/products/${productId}/baseInfo`, data);
  }

  public saveExtraParameters(productId: string, data: any) {
    return this._http.post<any>(`api/products/${productId}/extraParams`, data);
  }

  public saveAdditionalInfo(productId: string, data: any) {
    return this._http.post<any>(
      `api/products/${productId}/additionalInfo`,
      data
    );
  }

  public addProductLink(productId: string, data: any) {
    return this._http.post<any>(`api/products/${productId}/links`, data);
  }

  public saveProductLink(productId: string, linkId: string, data: any) {
    return this._http.post<any>(
      `api/products/${productId}/links/${linkId}`,
      data
    );
  }

  public removeProductLink(productId: string, linkId: string) {
    return this._http.delete<any>(
      `api/products/${productId}/links/${linkId}`
    );
  }

  public addAttachment(productId: string, data: any) {
    return this._http.post<any>(`api/products/${productId}/attachments`, data);
  }

  public saveAttachment(productId: string, attachmentId: string, data: any) {
    return this._http.post<any>(
      `api/products/${productId}/attachments/${attachmentId}`,
      data
    );
  }

  public removeAttachment(productId: string, attachmentId: string) {
    return this._http.delete<any>(
      `api/products/${productId}/attachments/${attachmentId}`
    );
  }

  public downloadAttachment(productId: string, attachmentId: string) {
    document.location.href =
      environment.apiUrl +
      `api/products/${productId}/attachments/${attachmentId}/download`;
  }

  public saveAttributes(productId: string, data: any) {
    return this._http.post<any>(`api/products/${productId}/attributes`, data);
  }

  public saveExpirationInfo(productId: string, data: any) {
    return this._http.post<any>(
      `api/products/${productId}/expirationInfo`,
      data
    );
  }

  public saveMarkingAndNutritionInfo(productId: string, data: any) {
    return this._http.post<any>(
      `api/products/${productId}/markingAndNutritionInfo`,
      data
    );
  }

  public saveSeafoodInfo(productId: string, data: any) {
    return this._http.post<any>(`api/products/${productId}/seafoodInfo`, data);
  }

  public saveAlcoholInfo(productId: string, data: any) {
    return this._http.post<any>(`api/products/${productId}/alcoholInfo`, data);
  }

  public saveMovieInfo(productId: string, data: any) {
    return this._http.post<any>(`api/products/${productId}/movieInfo`, data);
  }

  public saveBookInfo(productId: string, data: any) {
    return this._http.post<any>(`api/products/${productId}/bookInfo`, data);
  }

  public saveMusicInfo(productId: string, data: any) {
    return this._http.post<any>(`api/products/${productId}/musicInfo`, data);
  }

  public saveGameInfo(productId: string, data: any) {
    return this._http.post<any>(`api/products/${productId}/gameInfo`, data);
  }

  public saveMedicationInfo(productId: string, data: any) {
    return this._http.post<any>(`api/products/${productId}/medicationInfo`, data);
  }

  public saveElectronicsInfo(productId: string, data: any) {
    return this._http.post<any>(
      `api/products/${productId}/electronicsInfo`,
      data
    );
  }

  public saveMeatInfo(productId: string, data: any) {
    return this._http.post<any>(`api/products/${productId}/meatInfo`, data);
  }

  public addImages(productId: string, data: any) {
    return this._http.post<any>(`api/products/${productId}/images`, data);
  }

  public updateImage(productId: string, imageId: string, data: any) {
    return this._http.post<any>(
      `api/products/${productId}/images/${imageId}`,
      data
    );
  }

  public deleteImage(productId: string, imageId: string) {
    return this._http.delete<any>(
      `api/products/${productId}/images/${imageId}`
    );
  }

  public lockSection(productId: string, section: string) {
    return this._http.post<any>(
      `api/products/${productId}/lockSection/${section}`,
      {}
    );
  }

  public unlockSection(productId: string, section: string) {
    return this._http.post<any>(
      `api/products/${productId}/unlockSection/${section}`,
      {}
    );
  }

  public getHistory(productId: string) {
    return this._http.get<any>(`api/products/${productId}/history`, {});
  }

  public exportXls(data: any) {
    return this._http.post<any>(`api/products/exportXls`, data);
  }

  public exportRetailerProducts(data: any) {
    return this._http.post<any>(`api/products/retailerProductsExport`, data);
  }

  public exportSupplierProducts(data: any) {
    return this._http.post<any>(`api/products/supplierProductsExport`, data);
  }

  public getSupplierProductsExport(id: string) {
    var url = `${environment.apiUrl}api/products/supplierProductsExport/${id}`;
    window.open(url, "_blank");
  }

  public downloadXls(fileId: string) {
    // very bad for bigger files, as the whole blob
    // is cached to browser memory instead of directly streaming the download to disk
    return this._http.get(`api/products/downloadXls/${fileId}`, {
      responseType: "blob"
    });
  }

  public validateRetailChainFieldRules(
    productId: string,
    retailerIds: string[]
  ) {
    if (productId && retailerIds.length > 0) {
      return this._http.post<IProductValidationResult>(
        `api/products/${productId}/validation`,
        { retailChainIds: retailerIds }
      );
    }
  }

  public getProductsValidState(productIds: string[], retailerIds: string[]) {
    return this._http.post<any>(`api/products/getValidState`, { productIds, retailerIds });
  }

  public emptyRetailChainFieldRules() {
    this._productValidity.set({});
    this._productValidity.shouldValidate = false;
  }

  public calcClientExpiry(retailerId: string, expiryInDays: number) {
    return this._http.post<any>(`api/products/calcClientExpiry`, { retailerId, expiryInDays });
  }

  public exportProductsPdf(req: IExportProductsPdfReq) {
    return this._http.post<any>(`api/products/exportPdf`, req);
  }

  public downloadProductPdf(id: string) {
    document.location.href = environment.apiUrl + `api/products/downloadPdf/${id}`;
  }

  public availableUnitsData(productId: string, clientId: string) {
    return this._http.get<{ [index: string]: any }>(`api/products/${productId}/availableUnits/${clientId}`);
  }

  public retailerCodeData(productId: string, clientId: string) {
    return this._http.get<{ [index: string]: any }>(`api/products/${productId}/retailerCode/${clientId}`);
  }

  public syncProducts(req) {
    return this._http.post(`api/products/sync`, req);
  }

  public getRetailerDetailsFormData(ean, internalCode, excludeIds) {
    return this._http.post(`api/products/retailerDetails/formData`, {
      productEan: ean,
      productInternalCode: internalCode,
      excludeIds: excludeIds
    });
  }

  public createConformityDeclaration(req) {
    return this._http.post<any>(`api/products/conformityDeclaration`, req);
  }

  public downloadConformityDeclaration(productId: string, declarationId: string) {
    document.location.href =
      environment.apiUrl +
      `api/products/${productId}/conformityDeclaration/${declarationId}/download`;
  }

  public removeConformityDeclaration(productId: string, declarationId: string) {
    return this._http.delete<any>(
      `api/products/${productId}/conformityDeclaration/${declarationId}`
    );
  }

  public addConformityDeclaration(productId: string, req) {
    return this._http.post<any>(`api/products/${productId}/conformityDeclaration`, req);
  }

}

@Injectable()
export class ProductValidity {
  constructor(
    private _appState: AppState,
  ) {}

  private _data: IProductValidationResult;

  public shouldValidate = false;
  public hasBuyerPimExtensions = this._appState.hasModule(LxmAppModule.BuyerPimExtensions);

  private _validatedRetailers: any =
    LocalStorage.getValue(
      LOCAL_STORAGE_KEY.PRODUCT_VALIDATION_RETAILERS
    ) || [];

  public get validatedRetailers() {
    return this._validatedRetailers;
  }

  public setValidatedRetailers(retailers: IRetailChain[]) {
    this._validatedRetailers = retailers;
    LocalStorage.set(
      LOCAL_STORAGE_KEY.PRODUCT_VALIDATION_RETAILERS,
      retailers
    );
  }

  public emptyValidatedRetailers() {
    this._validatedRetailers = [];
  }

  public set(data: IProductValidationResult) {
    this._data = data;
  }

  public get() {
    return this._data;
  }

  public isCardInvalid(section: ProductCardSection): boolean {
    const invalidProductUnitFieldValues = this._data?.invalidProductUnitFieldValues;
    if (section == ProductCardSection.ProductUnits && invalidProductUnitFieldValues) {
      return Object.keys(invalidProductUnitFieldValues)?.length > 0;
    }
    return this._data?.invalidProductFieldValues?.[section] ? true : false;
  }

  public isCardRecommended(section: ProductCardSection): boolean {
    return this._data?.emptyRecommendedProductFieldValues?.[section] ? true : false;
  }

  public getCardStatusValidType(section: ProductCardSection) {
    return this.isCardInvalid(section) ? ValidType.Invalid :
      (this.isCardRecommended(section) ? ValidType.Recommended : ValidType.Valid)
  }

  public isProductUnitInvalid(id) {
    return this._data?.invalidProductUnitFieldValues?.[id] ? true : false;
  }

  public get fieldValuesValid() {
    return Object.getOwnPropertyNames(this._data.invalidProductFieldValues)
    .length > 0
    ? false
    : true;
  }

  public get unitFieldsValid() {
    return Object.getOwnPropertyNames(this._data.invalidProductUnitFieldValues)
    .length > 0
    ? false
    : true;
  }

  public get isProductValid() {
    return this.fieldValuesValid && this.unitFieldsValid;
  }

  public get requiredByRetailChains() {
    return this._data?.retailChains;
  }

  public get requiredByRetailChainNames(): any {
    if (this._data?.retailChains?.length > 0) {
      return this._data.retailChains.map(x => x.name).join(", ");
    }
    return null;
  }

  public get invalidFieldKeys() {
    if (!this._data.invalidProductFieldValues) {
      return null;
    }
    return Object.keys(this._data.invalidProductFieldValues).reduce(
      (acc, a) => acc.concat(this._data.invalidProductFieldValues[a]),
      []
    );
  }

  public get invalidUnitFieldKeys() {
    if (!this._data.invalidProductUnitFieldValues) {
      return null;
    }
    return Object.keys(this._data.invalidProductUnitFieldValues).reduce(
      (acc, a) => acc.concat(this._data.invalidProductUnitFieldValues[a]),
      []
    );
  }

  public get invalidUnitIds() {
    if (!this._data.invalidProductUnitFieldValues) {
      return null;
    }
    return Object.keys(this._data.invalidProductUnitFieldValues);
  }

  public invalidUnitFieldsById(id) {
    if (!this._data.invalidProductUnitFieldValues || !this._data.invalidProductUnitFieldValues[id]) {
      return null;
    }

    return this._data.invalidProductUnitFieldValues[id]
  }

  public isRequiredByRetailerLanguageText(field: ProductField) {
    if (!this._data) {
      return FieldRequiredType.None;
    }

    const mandatory = this._data.mandatoryProductFieldLanguages?.[field];
    if (mandatory) {
      return FieldRequiredType.Required;
    }

    const recommended = this._data.recommendedProductFieldLanguages?.[field];
    if (recommended) {
      return FieldRequiredType.Recommended;
    }

    return FieldRequiredType.None;
  }

  public getLanguageTextRequiredTypes(field: ProductField) {
    if (!this._data) {
      return FieldRequiredType.None;
    }

    const mandatory = this._data.mandatoryProductFieldLanguages?.[field];
    if (mandatory) {
      const x = {};
      mandatory.forEach((m: string) => {
        Object.assign(
          x, {[m]: FieldRequiredType.Required});
      });
      return x;
    }

    const recommended = this._data.recommendedProductFieldLanguages?.[field];
    if (recommended) {
      const x = {};
      recommended.forEach((r: string) => {
        Object.assign(
          x, {[r]: FieldRequiredType.Recommended});
      });
      return x;
    }
    return FieldRequiredType.None;
  }

  public isRequiredByRetailer(
    section: ProductCardSection,
    field: ProductField,
    formValue: any
  ): any {
    if (!this._data || (Array.isArray(formValue) ? formValue?.length > 0 : formValue)) {
      return FieldRequiredType.None;
    }

    if (!formValue && formValue !== 0) {
      if (this._data.invalidProductFieldValues?.[section]?.includes(field)) {
        return FieldRequiredType.Required;
      }
      if (
        this._data.emptyRecommendedProductFieldValues?.[section]?.includes(
          field
        )
      ) {
        return FieldRequiredType.Recommended;
      }
    }

    return FieldRequiredType.None;
  }

  public isProductUnitFieldInvalid(
    unitId: string,
    field: ProductUnitField,
    formValue: any
  ): any {
    if (!this._data || (Array.isArray(formValue) ? formValue?.length > 0 : formValue)) {
      return FieldRequiredType.None;
    }

    if (!formValue && formValue !== 0) {
      if (this._data.invalidProductUnitFieldValues?.[unitId]?.includes(field)) {
        return FieldRequiredType.Required;
      }
      if (
        this._data.emptyRecommendedProductUnitFieldValues?.[unitId]?.includes(
          field
        )
      ) {
        return FieldRequiredType.Recommended;
      }
    }

    return FieldRequiredType.None;
  }

}


export interface IProductsSearchResponse {
  continuationToken: any;
  "@odata.count": number;
  "@odata.nextLink": any;
  "@search.coverage": number;
  "@search.facets": any;
  "@search.nextPageParameters": any;
  value: any[];
}

export interface IProductSearchResult {
  // ..
}

interface IExportProductsPdfReq {
  date: Date;
  language: string;
  includePrice: boolean;
  currencyId: string;
  priceListCountryId: string;
  productIds: string[];
}

export interface IProductValidationResult {
  emptyRecommendedProductFieldValues?: any;
  emptyRecommendedProductUnitFieldValues?: any;
  retailChains?: IRetailChain[];
  invalidProductFieldValues?: IInvalidProductFieldSection;
  invalidProductUnitFieldValues?: IInvalidProductUnitSection;
  mandatoryProductFieldLanguages?: any;
  recommendedProductFieldLanguages?: any;
}

interface IInvalidProductFieldSection {
  [ProductCardSection: string]: ProductField[];
}

interface IInvalidProductUnitSection {
  [productUnitId: string]: ProductUnitField[];
}
