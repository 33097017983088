import {
  Component,
  OnInit,
  Injector,
  ViewChild,
  OnDestroy
} from "@angular/core";
import { FormGroup } from "@angular/forms";
import { LxmDialog } from "src/app/_helpers/dialogs";
import { ProductCardBase } from "../product-card-base";
import {
  IProductAdditionalInfo,
  IProductLink,
  IClvDto
} from "../product-info/product-info.model";
import { Observable, Subscription } from "rxjs";
import { formUtil, keep } from "src/app/util/form-util";
import {
  SignalRService,
  AuthenticationService,
  ProductValidity
} from "src/app/_services";
import { FilesListComponent } from "src/app/components/_partials/files-list/files-list.component";
import { LinksListComponent } from "src/app/components/_partials/links-list/links-list.component";
import { IAttachment } from "src/app/models";
import {
  AddFileDialog,
  IAddFileDialogData
} from "src/app/dialogs/add-file/add-file.dialog";
import {
  AddLinkDialog,
  IAddLinkDialogData
} from "src/app/dialogs/add-link/add-link.dialog";
import { UserAction, ProductCardSection } from "src/app/enum";
import { HubConnection } from "@microsoft/signalr";
import { TranslateService } from "@ngx-translate/core";
import { LOCAL_STORAGE_KEY } from "src/app/config";
import { ProductHeaderState } from "../product-header/product-header.state";
import { IConformityDeclaration } from "src/app/models/IConformityDeclaration";
import { ConformityDeclarationDialog } from "src/app/dialogs/conformity-declaration/conformity-declaration.dialog";
import { DeclarationsListComponent } from "src/app/components/_partials/declarations-list/declarations-list.component";
import { ActionHelper } from "src/app/_helpers/action";

@Component({
  selector: "product-additional-card",
  templateUrl: "./product-additional.card.html",
  styleUrls: ["./product-additional.card.scss"]
})
export class ProductAdditionalCard
  extends ProductCardBase<IProductAdditionalInfo>
  implements OnDestroy
{
  private _hubConnection: HubConnection;
  private _signalRSubscription: Subscription;

  public UserAction = UserAction;

  @ViewChild("filesList") filesList: FilesListComponent;
  @ViewChild("linksList") linksList: LinksListComponent;
  @ViewChild("declarationsList") declarationsList: DeclarationsListComponent;

  public get title() {
    return "cards.products.product_additional_info_and_instructions.title";
  }

  public cardSection = ProductCardSection.AdditionalInfoAndAttachments;

  public canEdit: boolean;
  public selectedAwards: any[];
  public files: IAttachment[];
  public conformityDeclarations: IConformityDeclaration[];
  public links: IProductLink[];
  public linkTypeOptions: IClvDto[];
  public awardsOptions: IClvDto[];
  public foodSafetyRecommendationsOptions: IClvDto[];

  public actionLoading = {} as any;

  public isExpandedKey = LOCAL_STORAGE_KEY.PRODUCT_ADDITIONAL_CARD_OPEN;

  public canCreateConformityDeclaration = false;

  constructor(
    public dialog: LxmDialog,
    injector: Injector,
    private _dialog: LxmDialog,
    signalRService: SignalRService,
    authService: AuthenticationService,
    public productValidity: ProductValidity,
    private _translateService: TranslateService,
    private _productHeaderState: ProductHeaderState,
    private _action: ActionHelper
  ) {
    super(injector, "additionalInfo", "productAdditionalInfoChanged");

    this.files = this._productCard.attachments || [];
    this.conformityDeclarations =
      this._productCard.conformityDeclarations || [];
    this.links = this._productCard.links || [];
    this.linkTypeOptions = this._productCard.formData.linkTypes;
    this.awardsOptions = this._formData.awards;
    this.foodSafetyRecommendationsOptions =
      this._formData.foodSafetyRecommendations;

    this.canCreateConformityDeclaration =
      this._formData.canCreateConformityDeclaration;

    this._signalRSubscription = signalRService.commonHub.subscribe((x) => {
      this._hubConnection = x;
      if (!x) {
        return;
      }

      x.on("productAttachmentAdded", (data: any) => {
        this.files = data;
      });
      x.on("productAttachmentRemoved", (data: any) => {
        this.files = data;
      });
      x.on("productAttachmentChanged", (data: any) => {
        this.files = data;
      });
    });

    this.canEdit = authService.hasRight([UserAction.ManageSettings]);
  }

  public openAddLinkDialog(link: IProductLink = null) {
    const linkDialogData: IAddLinkDialogData = {
      dataTableRef: this.linksList.dataSource,
      link: link,
      linkTypeOptions: this.linkTypeOptions,
      add: (data) => {
        return this._productService.addProductLink(this.productId, data);
      },
      save: (data) => {
        return this._productService.saveProductLink(
          this.productId,
          link.id,
          data
        );
      }
    };

    this._dialog.open(AddLinkDialog, {
      data: linkDialogData
    });
  }

  public removeLink(link: IProductLink) {
    this._dialog.confirm(
      {
        image: "delete-modal-image",
        template: `
      <p class="dialog-title">${this._translateService.instant(
        "cards.products.product_additional_info_and_instructions.links.dialog_title_delete"
      )}</p>
      <p>
        ${this._translateService.instant(
          "cards.products.product_additional_info_and_instructions.links.dialog_delete_confirm"
        )}
      </p>
      `
      },
      () => {
        this._productService
          .removeProductLink(this.productId, link.id)
          .result(this.form, (res) => {
            const i = this.linksList.dataSource.data.indexOf(link);
            this.linksList.dataSource.data.splice(i, 1);
            this.linksList.dataSource._updateChangeSubscription();
          });
      }
    );
  }

  public openAddFileDialog(attachment: IAttachment = null) {
    const fileDialogData: IAddFileDialogData = {
      dataTableRef: this.filesList.dataSource,
      attachment: attachment,
      add: (data) => {
        return this._productService.addAttachment(this.productId, data);
      },
      save: (data) => {
        return this._productService.saveAttachment(
          this.productId,
          attachment.id,
          data
        );
      }
    };

    this._dialog.open(AddFileDialog, {
      data: fileDialogData
    });
  }

  public openConformityDeclarationDialog() {
    this.dialog.open(
      ConformityDeclarationDialog,
      {
        width: "700px",
        height: "auto",
        data: {
          productIds: [this.productId],
          withResponse: true,
          contactOptions: this._formData.conformityDeclarationContacts
        }
      },
      (res) => {
        if (res) {
          this.conformityDeclarations.unshift(res);
          this.conformityDeclarations = [...this.conformityDeclarations];
        }
      }
    );
  }

  public download(attachment: IAttachment) {
    this._productService.downloadAttachment(this.productId, attachment.id);
  }

  public downloadConformityDeclaration(declaration: IConformityDeclaration) {
    this._productService.downloadConformityDeclaration(
      this.productId,
      declaration.id
    );
  }

  public removeConformityDeclaration(declaration: IConformityDeclaration) {
    // todo - messages, etc

    const msg = this._action.createMessage(
      "cards.products.product_additional_info_and_instructions.attachments.remove_conformity_declaration_success",
      "global.generic_error"
    );

    this._dialog.confirm(
      {
        image: "delete-modal-image",
        template: `
      <p class="dialog-title">${this._translateService.instant(
        "cards.products.product_additional_info_and_instructions.attachments.remove_conformity_declaration_title"
      )}</p>
      <p>
        ${this._translateService.instant(
          "cards.products.product_additional_info_and_instructions.attachments.remove_conformity_declaration_message"
        )}
      </p>
      `
      },
      () => {
        this._productService
          .removeConformityDeclaration(this.productId, declaration.id)
          .result(
            this.form,
            (res) => {
              // todo - success message

              const i =
                this.declarationsList.dataSource.data.indexOf(declaration);
              this.declarationsList.dataSource.data.splice(i, 1);
              this.declarationsList.dataSource._updateChangeSubscription();
            },
            (err) => {
              console.error(err);
            },
            "removeConformityDeclaration",
            this.actionLoading,
            msg
          );
      }
    );
  }

  public removeAttachment(attachment: IAttachment) {
    this._dialog.confirm(
      {
        image: "delete-modal-image",
        template: `
      <p class="dialog-title">${this._translateService.instant(
        "cards.products.product_additional_info_and_instructions.attachments.remove_product_attachment_title"
      )}</p>
      <p>
        ${this._translateService.instant(
          "cards.products.product_additional_info_and_instructions.attachments.remove_product_attachment_message"
        )}
      </p>
      `
      },
      () => {
        this._productService
          .removeAttachment(this.productId, attachment.id)
          .result(this.form, (res) => {
            // todo - success message

            const i = this.filesList.dataSource.data.indexOf(attachment);
            this.filesList.dataSource.data.splice(i, 1);
            this.filesList.dataSource._updateChangeSubscription();
          });
      }
    );
  }

  protected _getNormalizedFormData(data: IProductAdditionalInfo) {
    return formUtil.transformData(data, {
      awards: keep,
      description: keep,
      instructionsForUse: keep,
      warnings: keep,
      technicalName: keep,
      foodSafetyRecommendationId: () => data.foodSafetyRecommendation?.id
    });
  }

  protected _createFormGroup(data: IProductAdditionalInfo): FormGroup {
    this.selectedAwards = data.awards;
    return formUtil.createFormGroup(this._getNormalizedFormData(data));
  }

  protected _createSaveRequest() {
    const form = this.form.value;
    return {
      awards: form.awards?.map((a) => a.id),
      description: form.description
        ? Object.assign(
            {},
            ...form.description.map((o) => ({ [o.language]: o.value }))
          )
        : {},
      instructionsForUse: form.instructionsForUse
        ? Object.assign(
            {},
            ...form.instructionsForUse.map((o) => ({ [o.language]: o.value }))
          )
        : {},
      warnings: form.warnings
        ? Object.assign(
            {},
            ...form.warnings.map((o) => ({ [o.language]: o.value }))
          )
        : {},
      technicalName: form.technicalName
        ? Object.assign(
            {},
            ...form.technicalName.map((o) => ({ [o.language]: o.value }))
          )
        : {},
      foodSafetyRecommendationId: form.foodSafetyRecommendationId
    };
  }

  protected get successMessage(): string {
    return "cards.products.product_additional_info_and_instructions.save_successful";
  }

  protected _saveInternal(req: any): Observable<any> {
    return this._productService.saveAdditionalInfo(this.productId, req);
  }

  protected _afterSaved(req) {
    const { awards } = req;
    this._productHeaderState.setData({
      awards: awards
    });
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    if (this._hubConnection) {
      this._hubConnection.off("productAttachmentAdded");
      this._hubConnection.off("productAttachmentRemoved");
      this._hubConnection.off("productAttachmentChanged");
    }

    if (this._signalRSubscription) {
      this._signalRSubscription.unsubscribe();
    }
  }
}
